import React from 'react'
import "../index.css";



import Container from 'react-bootstrap/Container';

import chevronleft from '../Images/chevron-left.png';


// SearchBar with a placeholder and search icon
function PriceToolBusinessStep3Expanded2(props) {
	return (
		<div>
			<Container>

				{/* BREADCRUMB */}
				<div>
					<img src={chevronleft} alt="img" />
					<button className="expanded-back-button" onClick={() => props.handleStep("Tax Preparation Business Compact")}>Business Tax Preparation Price Ranges</button>
				</div>

				{/* MAIN CONTENT */}
				<div className="box-shadow expanded-div">
					<p className="expanded-heading">S Corporation</p>

					<p className="expanded-text">Please go back one screen and select <button className="button-link" onClick={() => props.handleStep("Tax Preparation Business Compact")}>Partnership-LLC and Regular (C) Corporation:</button> our pricing for S Corporations is the same.  Nonetheless, IMPORTANT NOTICE: if your S Corporation shows an annual profit of $20,000 or more, then you as owner must go on payroll from your S-Corp.</p>
					<p className="gold-disclaimer">Our payroll tax preparation starts at $800 per year.  Most clients at Agro Accounting CPA pay toward the lower end of the range!</p>
				</div>


			</Container>
		</div>
	);
}
export default PriceToolBusinessStep3Expanded2;
