import React from 'react'
import "../index.css";

import timeline from '../Images/timeline-complete.png'; // Tell Webpack this JS file uses this image
import confirmation from '../Images/confirmation.png'; // Tell Webpack this JS file uses this image
import Disclaimer12 from "../components/Disclaimer12";

import img from '../Images/bitmoji-one-finger.png';

import LastFileManager from "../components/LastFileManager";



function Confirmation(props) {
	return (
		<div className=" center-col welcome-div">
			<br></br>
			<br></br>

			<img className="timeline" src={timeline} alt="img" />

				<div className="aligncenter">

			<p className="welcome-text1">Hello Dear Client</p>
			<p className="welcome-text2">Thank you for creating a new work order, to complete this order please upload your documents to the File Manager. Thanks! </p>

				<br></br>
				<br></br>

				<LastFileManager />

			<img className="welcome-img" src={img} alt="img" />

			</div>
		</div>
	);
}
export default Confirmation;
