import React from 'react'
import "../index.css";

// SearchBar with a placeholder and search icon
function StepsHeading(props) {
	return (
		<div>

			<p className="steps-heading">{props.text}</p>


		</div>
	);
}
export default StepsHeading;
