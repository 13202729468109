import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import PropTypes from "prop-types";
import ReactGA from 'react-ga';

import { setCurrentUser, logoutUser } from "./actions/authActions";
//import { getTaxReturns } from "./actions/taxReturnActions";

import { Provider } from "react-redux";
import store from "./store";
import { createBrowserHistory } from 'history';


//Routes
import Home from "./routes/Home";
import AboutUs from "./routes/AboutUs";
import Contact from "./routes/Contact";
import CityContact from "./routes/cityContact";

import ClientSignUp from "./routes/ClientSignUp";
import ClientSignIn from "./routes/ClientSignIn";
import AgroSignUp from "./routes/AgroSignUp";
import AgroSignIn from "./routes/AgroSignIn";
import AdminUnAuthorized from "./routes/AdminUnAuthorized";

//import ForgotPassword from "./routes/ForgotPassword";
import ForgotPasswordConfirm from "./routes/ForgotPasswordConfirm";

import SuccessfulPayment from "./routes/SuccessfulPayment";

import WorkingWithUs from "./routes/WorkingWithUs";
import BusinessOrganization from "./routes/BusinessOrganization";
import IncomeAndExpenses from "./routes/IncomeAndExpenses";
import BestPractices from "./routes/BestPractices";
import AirbnbRentalProperty from "./routes/AirbnbRentalProperty";
import International from "./routes/International";
import AdditionalTaxQuestions from "./routes/AdditionalTaxQuestions";
import SCorp from "./routes/SCorp";
import Payment from "./routes/Payment";


import MessageConfirmation from "./routes/MessageConfirmation";


import PriceTool from "./routes/PriceTool";
import SaveForLater from "./components/SaveForLater";


import CPAFreeGuidance from "./routes/CPAFreeGuidance";
import CfgSeven from "./routes/CfgSeven";

//Components
//import Navbar from "./components/layout/Navbar";
//import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./routes/Dashboard";

import Modal1 from "./routes/Modal1";

import AdminDashboard from "./routes/AdminDashboard";
import AdminClientList from "./routes/AdminClientList";
import AdminArchive from "./routes/AdminArchive";
import AdminArchiveYear from "./routes/AdminArchiveYear";

import ClientDashboard from "./routes/ClientDashboard";
import AdminTaxReturn from "./routes/AdminTaxReturn";
import ClientTaxReturn from "./routes/ClientTaxReturn";

import PriceTool2 from "./routes/PriceTool2";

import ForgetPassword1 from "./routes/ForgetPassword1";
import PasswordResetLink from "./routes/PasswordResetLink";

import Privacy from "./routes/Privacy";
import Terms from "./routes/Terms";
import Refund from "./routes/Refund";
import Services from "./routes/Services";
import Promotions from "./routes/Promotions";
import AntiDefamationPolicy from "./routes/AntiDefamationPolicy";
import AdminReviewList from "./routes/AdminReviewList";
import { GoogleOAuthProvider } from '@react-oauth/google';



import CreateBlogPost from "./routes/Blog/CreateBlogPost";
import Blog from "./routes/Blog/Blog";
import BlogArticle from "./routes/Blog/BlogArticle";

import AdminApproveUser from "./routes/AdminApproveUser";




import "./App.css";
import AdminGmailApi from "./routes/AdminGmailApi";



// Check for token to keep user logged in
//console.log(localStorage.jwtToken);

//
// try {
//   //console.log("it accepted jwtoken")
//   // Set auth token header auth
//   const token = localStorage.jwtToken;
//   setAuthToken(token);
//
//   //Decode token and get user info and exp
//   const decoded = jwt_decode(token,{ header: true });
//   // Set user and isAuthenticated
//   store.dispatch(setCurrentUser(decoded));
//   console.log(store.getState());
//
//   // Check for expired token
//   const currentTime = Date.now() / 1000; // to get in milliseconds
//   if (decoded.exp < currentTime) {
//     // Logout user
//     store.dispatch(logoutUser());
//
//     // Redirect to login
//     window.location.href = "./";
// }
//
//  }catch(error) {
//   console.log(error);
//   window.location.href = "/ClientSignIn";
//
// }


if (localStorage.jwtToken) {
	console.log("it accepted jwtoken")
	// Set auth token header auth
	const token = localStorage.jwtToken;
	setAuthToken(token);

	//Decode token and get user info and exp
	const decoded = jwt_decode(token);
	// Set user and isAuthenticated
	store.dispatch(setCurrentUser(decoded));
	console.log(store.getState());

	// Check for expired token
	const currentTime = Date.now() / 1000; // to get in milliseconds
	if (decoded.exp < currentTime) {
		// Logout user
		store.dispatch(logoutUser());

		// Redirect to login
		window.location.href = "./";
	}

} else {
	//console.log("undefined token");
	//window.location.href = "/ClientSignIn";

}


ReactGA.initialize('UA-51838923-1');
const history = createBrowserHistory();
history.listen((location) => {
	//Google Analyse
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

class App extends Component 
{ 

	componentDidMount() {
		const script = document.createElement('script');
		script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16469403712';
		script.async = true;
		document.head.appendChild(script);
	 
		window.dataLayer = window.dataLayer || [];
		function gtag() {
		  window.dataLayer.push(arguments);
		}
		gtag('js', new Date());
		gtag('config', 'AW-16469403712');
	   }


	render() {
		console.log(this.props.auth)
		console.log(store.getState())

		return (
			//<GoogleOAuthProvider clientId="447751023241-qer5dqshtjc326mm3108otot5si5s5vs.apps.googleusercontent.com">
			<Provider store={store}>
				<Router>
					<div className="App">
						<Route exact path="/" component={Home} />

						<Route exact path="/Dashboard" component={Dashboard} />
						<Route exact path="/AboutUs" component={AboutUs} />
						<Route exact path="/Contact" component={Contact} />
							<Route exact path="/CityContact" component={CityContact} />


						<Route exact path="/cpa-free-guidance" component={CPAFreeGuidance} />
						<Route exact path="/cpa-free-guidance/s-corporation" component={CfgSeven} />


						<Route exact path="/ClientSignUp" component={ClientSignUp} />
						<Route exact path="/ClientSignIn" component={ClientSignIn} />
						<Route exact path="/AgroSignIn" component={AgroSignIn} />
						<Route exact path="/AgroSignUp" component={AgroSignUp} />
						<Route exact path="/Admin/Unauthorized" component={AdminUnAuthorized} />

						<Route exact path="/Payment" component={Payment} />

						<Route exact path="/ForgotPasswordConfirm" component={ForgotPasswordConfirm} />

						<Route exact path="/PriceTool" component={PriceTool} />
						<Route exact path="/Client/PriceTool" component={PriceTool2} />

						<Route exact path="/SaveForLater" component={SaveForLater} />


						<Route exact path="/register" component={Register} />
						<Route exact path="/login" component={Login} />

						<Route exact path="/Modal1" component={Modal1} />

						<Route exact path="/Admin/Dashboard" component={AdminDashboard} />
						<Route exact path="/Client/Dashboard" component={ClientDashboard} />

						<Route exact path="/AdminClientList" component={AdminClientList} />
						<GoogleOAuthProvider clientId="447751023241-qer5dqshtjc326mm3108otot5si5s5vs.apps.googleusercontent.com">
							<Route exact path="/Admin/ReviewList" component={AdminReviewList} />
						</GoogleOAuthProvider>
						<GoogleOAuthProvider clientId="563007923513-pe5uu1nqrptds9lriqih2j1ah42kpoup.apps.googleusercontent.com">
							<Route exact path="/Admin/AdminGmailApi" component={AdminGmailApi} />
						</GoogleOAuthProvider>
						<Route exact path="/AdminTaxReturn/:id" component={AdminTaxReturn} />
						<Route exact path="/Admin/Archive" component={AdminArchive} />
						<Route exact path="/Admin/Archive/:year" component={AdminArchiveYear} />
						<Route exact path="/Admin/ApproveUser" component={AdminApproveUser} />

						<Route exact path="/ClientTaxReturn/:id" component={ClientTaxReturn} />
						<Route
							 path="/ClientTaxReturn/:id/file-manager"
							 render={(props) => <ClientTaxReturn {...props} page={"File Manager"} />}
							/>

						<Route exact path="/cpa-free-guidance/working-with-us" component={WorkingWithUs} />
						<Route exact path="/cpa-free-guidance/business-organization" component={BusinessOrganization} />
						<Route exact path="/cpa-free-guidance/income-and-expenses" component={IncomeAndExpenses} />
						<Route exact path="/cpa-free-guidance/best-practices-for-business-and-freelance-expenses" component={BestPractices} />
						<Route exact path="/cpa-free-guidance/airbnb-and-rental-property" component={AirbnbRentalProperty} />
						<Route exact path="/cpa-free-guidance/international" component={International} />
						<Route exact path="/cpa-free-guidance/additional-tax-questions" component={AdditionalTaxQuestions} />
						<Route exact path="/cpa-free-guidance/SCorp" component={SCorp} />

						<Route exact path="/SuccessfulPayment" component={SuccessfulPayment} />
						<Route exact path="/ForgotPassword" component={ForgetPassword1} />
						<Route exact path="/PasswordResetLink" component={PasswordResetLink} />

						<Route exact path="/MessageConfirmation" component={MessageConfirmation} />

						<Route exact path="/blog/create" component={CreateBlogPost} />
						<Route exact path="/blog" component={Blog} />
						<Route exact path="/blog/article/:id" component={BlogArticle} />


						<Route exact path="/Privacy" component={Privacy} />
						<Route exact path="/Terms" component={Terms} />
						<Route exact path="/Refund" component={Refund} />
						<Route exact path="/Services" component={Services} />
						<Route exact path="/Promotions" component={Promotions} />
						<Route exact path="/Anti-Defamation-Policy" component={AntiDefamationPolicy} />



						<Switch>
							<PrivateRoute exact path="/dashboard" component={Dashboard} />
						</Switch>
					</div>
				</Router>
				</Provider>
			//</GoogleOAuthProvider>
		);
	}
}

App.propTypes = {
	auth: PropTypes.object.isRequired
};

/*
const mapStateToProps = state => ({
	auth: state.auth
});*/

export default App;
