import React from 'react'
import "../index.css";

import Container from 'react-bootstrap/Container';
import chevronleft from '../Images/chevron-left.png';


// SearchBar with a placeholder and search icon
function PriceToolBusinessStep3Expanded3(props) {
	return (
		<div>
			<Container>


				{/* BREADCRUMB */}
				<div>
					<img src={chevronleft} alt="img" />
					<button className="expanded-back-button" onClick={() => props.handleStep("Tax Preparation Business Compact")}>Business Tax Preparation Price Ranges</button>
				</div>

				{/* MAIN CONTENT */}
				<div className="box-shadow expanded-div">
					<p className="expanded-heading">Not-For-Profit (Information Only) Returns</p>

					<p className="expanded-text">If gross income is less than $50,000, then <span className="bold">$250</span> </p>
					<p className="gold-disclaimer">You must file IRS Form 990-N (“postcard”) and, in most states, a Charities Report or an annual registration renewal.</p>

					<p className="expanded-text">If gross income is over $50,000, then pricing <span className="bold">starts at $800</span> </p>
					<p className="gold-disclaimer">You must file IRS Form 990-EZ or 990, a Charities Report or an annual registration renewal and, in New York only, possibly undergo a CPA Review or CPA Audit.</p>
				</div>


			</Container>
		</div>
	);
}
export default PriceToolBusinessStep3Expanded3;
