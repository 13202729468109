import axios from "axios";
import { returnErrors } from './errorActions';

import { GET_ERRORS, GET_TAXRETURN, ADD_TAXRETURN,UPDATE_TAXRETURN, TAXRETURN_LOADING } from "./types";

const getToken = () => {
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from local storage
  return token;
};


// GET ALL TAX RETURNS
export const getTaxReturns = () => dispatch => {
  dispatch(setTaxReturnLoading());

  // Get the JWT token from local storage
  const token = getToken();
  //console.log(token)

  // Check if the token exists
  if (!token) {
    console.log("token doesnt exist")
    return;
  }

  // Include the JWT token in the request headers
  const headers = {
    Authorization: token
  };

  //console.log(headers)

  axios
  .get('/api/taxReturns/getAll', { headers })
  .then(res =>
      dispatch({
        type: GET_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      //dispatch()
      console.log(err)
    );
};

// Get Tax Return by ID
export const getTaxReturnsById = id => dispatch => {
  console.log(id)

  dispatch(setTaxReturnLoading());
  axios
    .get('/api/taxReturns/getById', id)
    .then(res =>
      dispatch({
        type: GET_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );

};

// Get Tax Return by Email
export const getTaxReturnsByEmail = email => dispatch => {
  console.log(email)

  dispatch(setTaxReturnLoading());
  axios
    .get(`/api/taxReturns/${email}`)
    .then(res =>
      dispatch({
        type: GET_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );

};



// ADD TAX RETURNS
export const addTaxReturn = taxReturn => dispatch => {
  axios
    .post('/api/taxReturns/add', taxReturn)
    .then(res =>
      dispatch({
        type: ADD_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response, err.response.status))
    );
};


// ADD TAX RETURNS
export const addTaxReturn2 = taxReturn => dispatch => {
  axios
    .post('/api/taxReturns/add2', taxReturn)
    .then(res =>
      dispatch({
        type: ADD_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response, err.response.status))
    );
};





// Stripe Token Payment
export const sendStripePayment = token => {

  console.log(token);
  axios
    .post('/api/taxReturns/sendStripePayment', token)
    .then( res => console.log(res)
    )
    .catch(err =>console.log(err)
    );
};





// Email
export const email = data => dispatch => {
  //console.log("in actions")
  axios
    .post('/api/taxReturns/email', data)
    .then(res =>
      dispatch({
        payload:res
      })
    ).catch(err =>
      dispatch({
        payload:err
      })
    );
};

















export const updateSheetsLink = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateSheetsLink', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};

// UPDATE TAX RETURNS NOTES
export const updateTaxReturnNotes = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnNotes', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};


export const updateTaxReturnAcceptOffer = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnAcceptOffer', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response, err.response.status))
    );
};


// UPDATE TAX RETURNS FILES
export const updateTaxReturnFiles = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnFiles', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
    );
};

// Update Finailized Tax Return PDF
export const updateTaxReturnFilePDF = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnFilePDF', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
    );
};

// UPDATE TAX RETURNS isDocumentsUploadedByClient
export const updateTaxReturnisDocumentsUploadedByClient = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisDocumentsUploadedByClient', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};


// UPDATE TAX RETURNS amount
export const updateTaxReturnAmount = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnAmount', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};

// UPDATE TAX RETURNS isAmountPayedByClient
export const updateTaxReturnisAmountPayedByClient = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisAmountPayedByClient', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};

// UPDATE TAX RETURNS isTaxReturnDownloadedByClient
export const updateTaxReturnisTaxReturnDownloadedByClient = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisTaxReturnDownloadedByClient', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};


// UPDATE TAX RETURNS isAmountAgreedSentByTeamMember
export const updateTaxReturnisAmountAgreedSentByTeamMember = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisAmountAgreedSentByTeamMember', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};


// UPDATE TAX RETURNS updateInternStart
export const updateTaxReturnisInternStart = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisInternStart', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};


// UPDATE TAX RETURNS isSeniorStart
export const updateTaxReturnisSeniorStart = taxReturn => dispatch => {
  console.log(taxReturn);
  axios
    .put('/api/taxReturns/updateTaxReturnisSeniorStart', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};

// UPDATE TAX RETURNS isBasilStart
export const updateTaxReturnisBasilStart = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisBasilStart', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};


// UPDATE TAX RETURNS isBasilApproveEFile
export const updateTaxReturnisBasilApproveEFile = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisBasilApproveEFile', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};


// UPDATE TAX RETURNS isEFileAuthSent
export const updateTaxReturnisEFileAuthSent = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisEFileAuthSent', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};


// UPDATE TAX RETURNS isEFileAuthSigned
export const updateTaxReturnisEFileAuthSigned = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisEFileAuthSigned', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};


// UPDATE TAX RETURNS isEFileAuthAccepted
export const updateTaxReturnisEFileAuthAccepted = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisEFileAuthAccepted', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};

// UPDATE TAX RETURNS isTaxReturnUploadedByTeamMember
export const updateTaxReturnisTaxReturnUploadedByTeamMember = taxReturn => dispatch => {
  axios
    .put('/api/taxReturns/updateTaxReturnisTaxReturnUploadedByTeamMember', taxReturn)
    .then(res =>
      dispatch({
        type: UPDATE_TAXRETURN,
        payload: res.data
      })
    )
    .catch(err =>
      console.log(err)
    );
};















export const setTaxReturnLoading = () => {
  return {
    type: TAXRETURN_LOADING
  };
};
