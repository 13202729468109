import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import taxReturnReducer from "./taxReturnReducer";
import gmailReducer from "./gmailReducer";
import gmailContentReducer from "./gmailContentReducer";
import gmailSender from './gmailSender';

export default combineReducers({
	auth: authReducer,
	errors: errorReducer,
	taxReturns: taxReturnReducer,
	gmail: gmailReducer,
	gmailContent: gmailContentReducer,
	gmailSender: gmailSender
});
