import React from 'react';
//import {CardElement, injectStripe} from 'react-stripe-elements';
import PropTypes from 'prop-types'
import "../index.css";

import PublicNav from "../components/PublicNav";

//import { connect } from "react-redux";
//import { sendStripePayment } from '../actions/taxReturnActions';

import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm2 from '../components/CheckoutForm2';


class Payment extends React.Component {
	constructor(props) {
		super(props);
		this.submit = this.submit.bind(this);
		this.state = {
			amount: 0,
			name: "Default Name"
		}
	}

	componentWillReceiveProps(nextProps) {
		console.log(nextProps);
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	static propTypes = {
		sendStripePayment: PropTypes.func.isRequired
	};

	async submit(ev) {
		let { token } = await this.props.stripe.createToken({ name: this.props.name });

		let item = {
			taxReturnId: this.props.taxReturnId,
			token: token,
			amount: this.props.amount * 100
		}

		this.props.sendStripePayment(item)
			.then(res => console.log(res))
			.catch(err => console.log(err))
	}


	render() {
		return (

			<div>
				<PublicNav />
				<div className="home-secondary-header-div">
					<p className="home-secondary-header">Make a Payment</p>
					<div class="underline-thick"></div>
				</div>

				<div className="center-content">
					<input className="form-input" type="text" name="name" placeholder="Please enter your full name" onChange={this.handleChange} />
					<input className="form-input" type="number" name="amount" placeholder="Please enter an amount" onChange={this.handleChange} />
					<StripeProvider apiKey="pk_live_cje7h02NpoVjtKVikrPLroSi005MRt0qep">
						<div className="example">
							<Elements>
								<CheckoutForm2 name={this.state.name} amount={this.state.amount} />
							</Elements>


						</div>
					</StripeProvider>

				</div>

			</div>

		);

	}
}

/*
const mapStateToProps = state => ({
	taxReturns: state.taxReturns,

});
*/
export default Payment
