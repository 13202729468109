import React from 'react';
import PropTypes from 'prop-types'


import timeline from '../Images/timeline-complete.png'; // Tell Webpack this JS file uses this image
import confirmation from '../Images/confirmation.png'; // Tell Webpack this JS file uses this image

import MetaTags from 'react-meta-tags';

import PublicNav from "../components/PublicNav";
import Footer from "../components/Footer";

import "../index.css";

class MessageConfirmation extends React.Component {


	componentDidMount() {

		window.dataLayer = window.dataLayer || [];
		function gtag() {
		  window.dataLayer.push(arguments);
		}
		gtag('js', new Date());
	  gtag('event', 'conversion', {'send_to': 'AW-16469403712/lWnlCKOj2ZkZEMDQnK09'});
	   }

  render () {
    return(
      <div>

      <MetaTags>
        <title>Message Sent - Agro Accounting CPA </title>
        <meta name="description" content="Agro Accounting CPA by Basil Agrocostea CPA is the one-stop destination for freelancers, artists and creative small businesses, for accounting service & help with tax preparation." />
      </MetaTags>
      <PublicNav />


        <div className="col">
          <img className="confirmation-img" src={confirmation} alt="img" />
          <p className="confirmation-heading">Thank you so much!</p>
          <p className="confirmation-text">We’ll get back to you within 24 to 48 hours</p>
        </div>

        <div className="row-center-top-20">
          <a href="/" className="button-filled">
            Return Home
            </a>
        </div>

        <Footer />




      </div>
    );

  }
}

export default MessageConfirmation;
