import React from 'react'
import MetaTags from 'react-meta-tags';

import PublicNav from "../components/PublicNav";

import Disclaimer2 from "../components/Disclaimer2";
import Footer from "../components/Footer";


import back from '../Images/chevron-left.svg';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

class SCorp extends React.Component {
	render() {
		return (
			<div className="cpa-free-guidance-page">
				<MetaTags>
					<title>Company Income Taxes for Creative Entrepreneurs, Startups & Freelancers </title>
					<meta name="description" content="We assist your S-corporation with affordable accounting & tax preparation; for self-employed, freelance workers, creative entrepreneurs & startups.  Offering quality, affordable, expert tax advice." />
				</MetaTags>
				<PublicNav />
				<div className="center-content-disclaimer">

					<Disclaimer2 />
				</div>

				<div className="center-content-cpa">

					<a href="/cpa-free-guidance">
						<div className="breadcrumb-div">
							<img className="back-button" src={back} alt="img" />
							<p className="cpa-detail-breadcrumb">Topic Categories</p>
						</div>
					</a>

					<p className="cpa-detail-heading">S Corporation</p>


					<Accordion
						allowMultipleExpanded={true}
						preExpanded="first" >

						<AccordionItem uuid="first" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									1
          						  </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									If you have an S-Corp generating a profit -income after business expenses- of $20,000 or more during the tax return’s year then, going forward, you will have to receive a portion of your
									S-Corp profit through payroll.  “Payroll” means filing quarterly payroll tax returns and making monthly or quarterly tax payments.  If this is something you’re resistant to, then we require you start doing business under an LLC, sole proprietorship or partnership
									Please follow our PriceTool for more guidance and information on this topic.  It is our Free, Confidential, Non-Committal and Time-Saving tax preparation guide.  Also be sure to browse our Income & Expenses tips on this A.I. supported FAQ page.
            </p>
							</AccordionItemPanel>
						</AccordionItem>




					</Accordion>

					<Footer />

				</div>
			</div>



		);

	}
}

export default SCorp;
