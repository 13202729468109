import React from 'react'

// SearchBar with a placeholder and search icon
function Disclaimer12(props) {
	return (
		<div className="center-col disclaimer9-div">

			<p className="disclaimer9-text">
				We’ll get back to you within 24 hours <span className="underline-bold">once we receive</span> the requested tax documents and information from you
			</p>


		</div>
	);
}
export default Disclaimer12;
