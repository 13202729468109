import React from 'react'
import "../index.css";

import Disclaimer6 from "../components/Disclaimer6";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import chevronleft from '../Images/chevron-left.png';



// SearchBar with a placeholder and search icon
function PriceToolIndividualStep3Expanded4(props) {
	return (
		<div>
			<Container>
				<Col>

					{/* BREADCRUMB */}
					<div>
						<img src={chevronleft} alt="img" />
						<button className="expanded-back-button" onClick={() => props.handleStep("Tax Preparation Individual Compact")}>Individual Tax Preparation Price Ranges</button>
					</div>

					{/* MAIN CONTENT */}
					<div className="box-shadow expanded-div">
						<p className="expanded-heading">Individual Tax Returns with Schedule E</p>
						<p className="expanded-text">Tax return with 1 Airbnb or rental property + 1 Form W-2 = <span className="bold">$300</span></p>
					</div>

					<Disclaimer6 />

				</Col>
			</Container>
		</div>
	);
}
export default PriceToolIndividualStep3Expanded4;
