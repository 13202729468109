import React from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import axios from "axios";
import logo from '../Images/logo.png';
import folder from '../Images/folder.png';
import { getTaxReturns } from '../actions/taxReturnActions';
import HomeSecondaryHeader from "../components/HomeSecondaryHeader";
import { logoutUser } from "../actions/authActions";
import Footer from "../components/Footer";

class AdminArchiveYear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  static propTypes = {
		getTaxReturns: PropTypes.func.isRequired
	};

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
			window.location.href = "/";
		}
    
      //this.getAll()
      this.props.getTaxReturns();

  }

  onLogoutClick = () => {
    // e.preventDefault();
    this.props.logoutUser();
    window.location.href = "/";
  };

  getAll = () => {
    console.log("Here in get all");

    axios
    .get('/api/taxReturns/getArchivedTaxReturns')
      .then(res => {
        //console.log(res)

        this.setState((state, props) => {
          return {
            data: res.data
          };
        });
      })
      .catch(err =>
        console.log(err)
      );
  }


  render () {
    let { taxReturns } = this.props.taxReturns;
    const { year } = this.props.match.params;
    console.log(year)
    let list = taxReturns.filter(item => item.archiveTaxYear == year)
    console.log(list);

    // Revenue Outstanding
    let revenueOutstandingArray = list.map((object, index) => {
      let a = 0;
      if(object.isAmountAgreedByClient == true){
        a += object.amount
      }
      return a
    })

    let revenue = revenueOutstandingArray.reduce(function(a, b){
     return a + b;
    }, 0);


    let items = list.map((object,index) => {
			return <a href={"/AdminTaxReturn/" + object._id}>
			  <div className="client-taxReturn-div" key={index}>
        <p className="archive-text">Archived</p>
				<img className="admin-taxreturn-img" src={folder} alt="img" />
				<p className="admin-taxreturn-name" >{object.name}</p>
				<p className="admin-taxreturn-service" >{object.service}</p>
			</div>
		</a>
  });


    return(
      <div>
        <div className="admin-nav">
          <a className="client-nav-logo" href="/Admin/Dashboard">
            <img className="logo" src={logo} alt="img" />
          </a>

          <div className="admin-nav-detail">

            <div>
              <p className="admin-nav-name">{this.props.auth.user.name}</p>
              <p className="admin-nav-role">{this.props.auth.user.role}</p>
            </div>

            <button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>

          </div>
        </div>

        <HomeSecondaryHeader text="Tax Returns Archives" />
        <br></br>
          <br></br>

        <div className="admin-graph-div">

          <div className="admin-graph">
            <p className="admin-graph-value">{year}</p>
            <p className="admin-graph-title">Tax Year</p>
          </div>

          <div className="admin-graph">
            <p className="admin-graph-value">${revenue}</p>
            <p className="admin-graph-title">Total Revenues</p>
          </div>

          <div className="admin-graph">
            <p className="admin-graph-value">{list.length}</p>
            <p className="admin-graph-title">Client Count</p>
          </div>

        </div>

        <div>
          <div className="row-space-between">
            {items}
          </div>

          {list.length === 0 ? <p>No Tax Returns Archived for the year </p> : <p></p>}

          <Footer />
      </div>



      </div>
    )
  }
}

AdminArchiveYear.propTypes = {
	auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,

};


const mapStateToProps = state => ({
	auth: state.auth,
  taxReturns: state.taxReturns
});

export default connect(
	mapStateToProps,
	{logoutUser,getTaxReturns}
)(AdminArchiveYear);
