import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios";
import BlogList from './BlogList'
import Pagination from 'react-bootstrap/Pagination'
import { useState } from 'react';
import * as JsSearch from 'js-search';

import Footer from "../../components/Footer";
import PublicNav from "../../components/PublicNav";
import HomeSecondaryHeader from "../../components/HomeSecondaryHeader";



class Blog extends React.Component {

  constructor(props) {
  super(props);
  this.state = {
  data:[],
  input:""
  }
}

componentDidMount() {

this.getBlogs();

}

getBlogs = () => {
  axios
  .post('/api/blog/getBlogs')
  .then(res => {
        this.setState((state, props) => {
          return {
            data: res.data
          };
        });

        console.log(this.state.data);

  })
  .catch(err =>
    console.log(err)
  );
}

handleChange = e => {
  this.setState({ [e.target.name]: e.target.value });
  console.log(this.state)
}


  render () {

    var stemmer = require('porter-stemmer').stemmer;


    var search = new JsSearch.Search('title');

    //Tokenizer
    // search.tokenizer =
    // new JsSearch.StemmingTokenizer(
    //     stemmer, // Function should accept a string param and return a string
    //     new JsSearch.SimpleTokenizer());


    search.addIndex('slug');
    search.addIndex('content');
    search.addDocuments(this.state.data);



    // search.tokenizer = JsSearch.StemmingTokenizer(
    //   stemmer, // Function should accept a string param and return a string
    //     JsSearch.SimpleTokenizer(this.state.input));

    console.log(this.state.input)
    console.log(this.state.data)

    let position;
    let position2;

    //This holds the indexes of maunally searched items
    let manualResults = [];


    if(this.state.data.length > 1){

      for(let i = 0; i < this.state.data.length; i++){
        // Search
        position = this.state.data[i].content.search(this.state.input);
        // Find
        if(position > -1){
          //console.log(i);
          manualResults.push(i);
        }

        // Search 2
        position2 = this.state.data[i].title.search(this.state.input);
        // Find 2
        if(position2 > -1){
          //console.log(i)
          manualResults.push(i);
        }

      }

      console.log(manualResults)

    }


    //This holds the real array of maunally searched items
    let manualResultsArray = manualResults.map((item) => this.state.data[item])



    // Send Manual filter results first
    if(manualResultsArray.length > 0){

      console.log(manualResultsArray)
    }



    let result;
    result = search.search(this.state.input);
    console.log(result);

    // Present all articles by default
    //let blog = <BlogList data={this.state.data} />
    let blog;

    if(this.state.input.length == 0){
     blog = <BlogList data={this.state.data} />

    }else{
    //Manual Results show first
    if(manualResultsArray.length > 0){
      blog = <div>
      <BlogList data={manualResultsArray} />
      </div>

    // If no manual Results are present then show JSearch
    } else{

    // Checks if user has typed in a query
    if(this.state.input.length > 0){

      // Checks is query resulted in at least one result
      if(result.length > 0) {
        blog = <div>
        <BlogList data={result} />
        </div>
      }else{
        // If there are no matches for the query
        blog = <p>0 search results</p>
      }
    }
  }





  }




    return(
      <div className=" blue-background ">

      <PublicNav />

      <div className="blog-list-container">
        <HomeSecondaryHeader text="Blogs" />


      <br>
      </br>

      <div className='search-input-container'>
        <img src="/Images/search-icon.png" className='search-icon' alt="img" />

        <input
        className="search-input"
        name="input" type="text"
        placeholder="What can we help you with?"
        onChange={this.handleChange} />

      </div>


        {blog}

        </div>

        <Footer />

      </div>
    );
  }
}

export default Blog;
