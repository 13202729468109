import React from 'react'
import MetaTags from 'react-meta-tags';

import PublicNav from "../components/PublicNav";

import Disclaimer2 from "../components/Disclaimer2";
import Footer from "../components/Footer";


import back from '../Images/chevron-left.svg';



import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

class AirbnbRentalProperty extends React.Component {
	render() {
		return (
			<div className="cpa-free-guidance-page">
				<MetaTags>
					<title>Airbnb Host Tax Reporting Guide | Airbnb Tax Preparation & Reporting Tips</title>
					<meta name="description" content="We offer CPA tax preparation help with Airbnb & rental property income; we help you report your Airbnb & rental income; we help you organize your information for income tax preparation purposes." />
				</MetaTags>
				<PublicNav />
				<div className="center-content-disclaimer">

					<Disclaimer2 />
				</div>

				<div className="center-content-cpa">

					<a href="/cpa-free-guidance">
						<div className="breadcrumb-div">
							<img className="back-button" src={back} alt="img" />
							<p className="cpa-detail-breadcrumb">Topic Categories</p>
						</div>
					</a>

					<p className="cpa-detail-heading">AIRBNB & RENTAL PROPERTY</p>


					<Accordion
						allowMultipleExpanded={true}
						preExpanded="first" >

						<AccordionItem uuid="first" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									How do I report Airbnb income?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Airbnb income is rental income.  It is reported along with your related, rental expenses.  Please see “How do I gather and present my Airbnb or rental property information?” for more guidance on this topic.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem uuid="second" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									How do I gather and organize my Airbnb or rental property information?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									First, gather your bank account and app (Venmo, PayPal, etc.) statements for the tax return’s year.  Second, create a spreadsheet.  We recommend you use Google Sheets: it is free with your Gmail account, simple to use (you only need the basic features) and easy to share with us.
									On your spreadsheet, from your monthly account statements:
              <br></br>
									<br></br>
									1) Keep track of (only) your rental income deposits under an Income column.  <br></br>
									2) Group or cluster the various expenses that are related to your rental activity into columns that make sense to you.  Common categories include rent paid to landlord, gas and electric bills, Internet; amenities such as Hulu and Netflix; cleaning and maintenance, repairs, appliance purchases; property taxes paid.  <br></br>
									Lastly:  3) Jot down the corresponding percentage of your home or property you had rented out during the tax return’s year.  4) If you own the property, also tell us i. when you bought it and for how much, and ii. the amount you have spent since to renovate it, along with the approximate major renovation dates.  Major renovations of $2,000+ only.  Please classify expenses below this dollar threshold as repairs.

            </p>
							</AccordionItemPanel>
						</AccordionItem>




					</Accordion>

					<Footer />

				</div>
			</div>


		);

	}
}

export default AirbnbRentalProperty;
