import React from 'react'
import MetaTags from 'react-meta-tags';

//import PropTypes from 'prop-types'

//import Card from 'react-bootstrap/Card'
//import Button from 'react-bootstrap/Button'
//import Row from 'react-bootstrap/Row'


//import Container from 'react-bootstrap/Container'
//import CpaFreeGuidanceDetail from "../components/CpaFreeGuidanceDetail";
import PublicNav from "../components/PublicNav";

import Disclaimer2 from "../components/Disclaimer2";
import Footer from "../components/Footer";


import back from '../Images/chevron-left.svg';



import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

class IncomeAndExpenses extends React.Component {
	render() {
		return (
			<div className="cpa-free-guidance-page">
				<MetaTags>
					<title>Income Tax Services for Startups, Freelance Workers & the Self-Employed</title>
					<meta name="description" content="Our Certified Public Accountants offer income tax services to artists, creative entrepreneurs, independent contractors & freelancers; help with tax preparation, payroll, sales tax & more." />
				</MetaTags>
				<PublicNav />
				<div className="center-content-disclaimer">

					<Disclaimer2 />
				</div>

				<div className="center-content-cpa">

					<a href="/cpa-free-guidance">
						<div className="breadcrumb-div">
							<img className="back-button" src={back} alt="img" />
							<p className="cpa-detail-breadcrumb">Topic Categories</p>
						</div>
					</a>

					<p className="cpa-detail-heading">Income and Expenses</p>


					<Accordion
						allowMultipleExpanded={true}
						preExpanded="first" >

						<AccordionItem uuid="first" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What is my business or freelance income?  How do I calculate it?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Your business or freelance income, at a minimum, is the total of your bank account deposits and app (Venmo, PayPal, etc.) deposits for the tax return’s year.  Income is all of these deposits EXCEPT:
              <ol>
										<li>Financial support/ gifts from family and friends</li>
										<li>Personal reimbursements, i.e. IOU repayment, Venmo for the dinner bill’s share</li>
										<li>Personal refunds, i.e. for return of clothes you bought on Amazon </li>
										<li>W-2 salaried income (because this goes elsewhere on your tax return)</li>
										<li>Transfers between your bank or app accounts</li>
										<li>Prior year tax refunds (because this goes elsewhere on your tax return)</li>
									</ol>
								</p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What is the difference between income and a gift?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									A gift is unilateral, or one-way, “consideration.”  If someone gives you money and gets nothing in return from you (aside for the good feeling that comes from giving), then they have made you a gift.  For income tax purposes, gifts are not taxable.  However, if they are more than $15,000, then the grantor -the person making the gift- may have to pay an excise tax on a separate, gift tax return he/ she files.
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									How do I gather and organize my business or freelance income?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									First, please see “What is my business or freelance income?  How do I calculate it?” to gain an understanding of what you’re asking for.  Then, keep track of your results for the tax return’s year on a spreadsheet.  We recommend you use Google Sheets: it is free with your Gmail account, simple to use (you only need the basic features) and easy to share with us.
          </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									I am missing some Forms 1099.  Can you still prepare my income tax return?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									It is very important to know your Forms 1099 are actually not necessary for the correct reporting of your business or freelance income.  This is how you properly calculate your business or freelance income: “What is my business or freelance income?  How do I calculate it?”
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What are business or freelance expenses?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									SHORT ANSWER
									Money you spent to pursue or generate income.
              <br></br>
									<br></br>
									DETAILED ANSWER
									Your business or freelance expenses are what you spent money on during the tax return’s year to advance your income endeavors.  So, if you spent money on an item or service believing it would contribute to your income goals, then it is a business expense.  Next, please see “What are all of the tax deductions I can write off?” and “How do I gather and present my business or freelance expenses?”  Once you are done with these 3 interrelated tips, take just a moment to read “Best practices for business or freelance expenses.”
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What are all of the tax deductions I can write off?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									First, please see “What are business or freelance expenses?” to gain a true understanding of what you’re asking for.  Now, your tax deductions are finite: not limitless.  They are either on your bank account or app (Venmo, PayPal, etc.) statements, or on cash receipts you have saved.  If you don’t have any document to prove it, then it is not a tax deduction!
									Second, to determine your tax deductions, comb through your bank account and app statements for the tax return’s year.  Go through every month’s statement and every month’s transaction, one by one.  Do not expect to be done in 20 minutes: this task will take you a few hours to a few days, so pace yourself and plan your time accordingly.  Going forward, you want to do this every couple of months throughout the year.
									Next, please see “How do I gather and present my business or freelance expenses?”  Once you are done with these 3 interrelated tips, take just a moment to read “Best practices for business or freelance expenses.”
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									How do I gather and organize my business or freelance expenses?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									SHORT ANSWER
									Go through your bank and app (Venmo, PayPal, etc.) statements month-by-month for the tax return’s year and populate your business or freelance transactions onto a Google Sheets spreadsheet in categories (columns) that make sense to you.
            <br></br>
									<br></br>

									DETAILED ANSWER
									First, please see “What are business or freelance expenses?” and “What are all of the tax deductions I can write off?” in order to understand what you are looking for, then how you will find it.  Once you have done so:

									On a spreadsheet, group or cluster your various business or freelance expenses from your bank account and app (Venmo, PayPal, etc.) statements into categories that make sense to you, for the tax return’s year.  We recommend you use Google Sheets: it is free with your Gmail account, simple to use (you only need the basic features) and easy to share with us.  For transactions that are part-business and part-personal, jot down the business percentage you have reasonably estimated.

									Once you are done with these 3 interrelated tips, take just a moment to read “Best practices for business or freelance expenses.”

            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What if an expense is part business and part personal?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									On your spreadsheet, jot down the percentage you reasonably estimate represents your business use.  Please see “How do I gather and present my business or freelance expenses?” for more guidance on this topic.
          </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									I am unsure if some expenses are for business or for personal reasons.  What should I do?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Please gather and present your business or freelance expenses to the best of your ability.  Not everything will fall perfectly into place and that’s OK.  If in doubt, enter it onto your expenses spreadsheet.  We cannot gauge the deductibility of expense transactions you don’t make us aware of!  Be sure to browse this A.I. supported FAQ page for more guidance on Business or Freelance Expenses.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Can I write off an expense that I paid out of pocket?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Please check out our tip What are all of the tax deductions I can write off? to answer your question.
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What happens if my business or freelance expenses are more than my income?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									If your business or freelance expenses are more than your income, then you have a loss.  If you show a loss on your tax return year after year, the IRS could flag you as a hobbyist: a hobbyist cannot report tax deductions that exceed their business or freelance income.  Though there may be times - especially in the earlier years of a person or small business’ career - where expenses exceed income, we recommend against showing a loss on your tax return for more than two years of any five-year period.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What if I have multiple years of tax returns to file and of expenses to show?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Follow the same process as above, on a spreadsheet set up for each tax return’s year.  Please see “How do I gather and present my business or freelance expenses?” for more guidance on this topic.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


					</Accordion>

					<Footer />

				</div>
			</div>


		);

	}
}

export default IncomeAndExpenses;
