import React from "react";
import "../index.css";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../actions/authActions";


import img1 from '../Images/client.jpg';



class AgroSignIn extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			email: "",
			password: "",
			errors: {}

		}
	}

	componentDidMount() {
		// If logged in and user navigates to Login page, should redirect them to dashboard
		if (this.props.auth.isAuthenticated) {
			this.props.history.push("/Admin/Dashboard");
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.auth.isAuthenticated) {
			this.props.history.push("/Admin/Dashboard");
		}

		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
		}

	}


	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	onSubmit = e => {
		e.preventDefault();

		const userData = {
			email: this.state.email,
			password: this.state.password
		};

		this.props.loginUser(userData);
	};

	render() {
		console.log(this.state);
		const { errors } = this.state;

		return (
			<div>


				<div className="row">
					<div className="half">
						<img className="sign-img" src={img1} alt="img1" />

					</div>



					<div className="half">
						<div className="client-sign-div">

							<p className="public-heading">Agro Team Members Sign In</p>

							<form onSubmit={this.onSubmit}>
								<div className="margin-bottom-30">
									<label>
										Email
                  <input className="input-field-new" onChange={this.handleChange} type="email" name="email" />
									</label>
								</div>

								<div className="margin-bottom-30">
									<label>
										Password
                  <input className="input-field-new" onChange={this.handleChange} type="password" name="password" />
									</label>
									<a href="/ForgotPassword">Forgot Password?</a>
								</div>
								<p className="red-text">{errors.name}</p>
								<p className="red-text">{errors.email}</p>
								<p className="red-text">{errors.password}</p>

								<p className="red-text">{errors.passwordincorrect}</p>
								<p className="red-text">{errors.emailnotfound}</p>


								<button type="submit" className="button-filled">Sign In</button>
							</form>
						</div>
					</div>


				</div>

			</div>
		);
	}
}

AgroSignIn.propTypes = {
	loginUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{ loginUser }
)(AgroSignIn);
