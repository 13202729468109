import React from 'react'

// SearchBar with a placeholder and search icon
function Disclaimer9(props) {
	return (
		<div className="disclaimer9-div">
			<p className="disclaimer9-heading">Hey There!</p>

			<p className="disclaimer9-text">
				This is a free and time-saving tutorial that will get you tax preparation ready!
				If you follow our free information-gathering process, you will get your tax return filed in a fraction of the time, no matter which accountant you hire!
      </p>

		</div>
	);
}
export default Disclaimer9;
