import React from 'react'
//import PropTypes from 'prop-types'

//import Form from 'react-bootstrap/Form'
//import FormControl from 'react-bootstrap/FormControl'
//import FormCheck from 'react-bootstrap/FormCheck'
//import Button from 'react-bootstrap/Button'

import PublicNav from "../components/PublicNav";

import StepsHeading from "../components/StepsHeading";
import Step1Option from "../components/Step1Option";
//import PriceToolFooter from "../components/PriceToolFooter";

import "../index.css";
import Container from 'react-bootstrap/Container'

import timeline from '../Images/timeline-service.png'; // Tell Webpack this JS file uses this image




// Select a service screen with 10 grid options
class PriceToolStep1 extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			next: false
		}
	}

	componentDidMount() {
		// if(this.props.service==""){
		//
		//   this.setState({
		//     next: true
		//   });
		//
		// }
	}



	render() {

		console.log(this.state);




		return (
			<div>
				<PublicNav />

				<div className="col">

					<img className="timeline" src={timeline} alt="timeline" />
					<Container>

						<StepsHeading text="What accounting/tax service are you looking for?" />

						<div className="service-grid">
							<button className="price-tool-step-one-option-inactive" onClick={() => this.props.handleService("Select Tax Preparation")}>
								<Step1Option text="Tax Preparation" />
							</button>

							<button className="price-tool-step-one-option-inactive" onClick={() => this.props.handleService("Accounting & Business Consulting")}>
								<Step1Option text="Accounting & Business Consulting" />
							</button>

							<button className="price-tool-step-one-option-inactive" onClick={() => this.props.handleService("Sales Tax & Payroll Tax")}>
								<Step1Option text="Sales Tax & Payroll Tax" />
							</button>

							<button className="price-tool-step-one-option-inactive" onClick={() => this.props.handleService("Bookkeeping")}>
								<Step1Option text="Bookkeeping" />
							</button>

							<button className="price-tool-step-one-option-inactive" onClick={() => this.props.handleService("Company Formation & Tax ID Issuance")}>
								<Step1Option text="Company Formation & Tax ID Issuance" />
							</button>
						</div>

						<div className="service-grid">
							<button className="price-tool-step-one-option-inactive" onClick={() => this.props.handleService("Estimated Tax Calculation")}>
								<Step1Option text="Estimated Tax Calculation" />
							</button>

							<button className="price-tool-step-one-option-inactive" onClick={() => this.props.handleService("International/ Multinational Income")}>
								<Step1Option text="International/ Multinational Income" />
							</button>


							<button className="price-tool-step-one-option-inactive" onClick={() => this.props.handleService("Audit Representation")}>
								<Step1Option text="Audit Representation" />
							</button>

							<button className="price-tool-step-one-option-inactive" onClick={() => this.props.handleService("Tax Relief Options")}>
								<Step1Option text="Tax Relief Options" />
							</button>
						</div>
					</Container>

					<div className="pricetool-footer">
						<p></p>

						<button className={this.props.service === "" ? "button-grey" : "button-filled"} disabled={this.props.service === "" ? true : false} onClick={() => this.props.handleStep(this.props.service)}>
							{this.props.service === "" ? "Select a Service" : "Next"}
						</button>
					</div>


				</div>
			</div>

		);
	}
}
export default PriceToolStep1;
