import React from "react";

import Footer from "../components/Footer";
import PublicNav from "../components/PublicNav";
import Disclaimer2 from "../components/Disclaimer2";
import CPAFreeGuidanceDetailDropdown from "../components/CPAFreeGuidanceDetailDropdown";

// S CORPORATION
class CfgSeven extends React.Component {
	render() {
		return (
			<div>
				<PublicNav />

				<p>S Corp</p>
				<CPAFreeGuidanceDetailDropdown heading="" text1="" link="" text2="" />
				<Disclaimer2 />
				<Footer />
			</div>
		);
	}
}

export default CfgSeven;
