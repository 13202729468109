import React from "react";
import "../index.css";

import Container from 'react-bootstrap/Container';

class Modal1 extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			modalIndividual: true
		}
	}

	handleShow = () => {
		this.setState({
			modalIndividual: true
		});
	}

	handleHide = () => {
		this.setState({
			modalIndividual: false
		});
	}


	render() {
		return (
			<Container>

				<div className="box-shadow priceTool-div">
					<button className="button-outlined" onClick={() => this.props.handleStep(this.props.handleStepValue)}>
						Back
       				</button>
					<Container>
						<p className="steps-heading dark-blue">{this.props.heading}</p>

						<div>
							<p className="expanded-text text-center">{this.props.text}</p>
							<div className="modal1-button">
								<a className="button-filled" href="https://calendly.com/agroaccounting-east">Schedule a Call</a>
							</div>
						</div>
					</Container>

				</div>
			</Container>

		);
	}
}

export default Modal1;
