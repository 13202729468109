import React from 'react'
import "../index.css";

import Container from 'react-bootstrap/Container';

import chevronleft from '../Images/chevron-left.png';


// SearchBar with a placeholder and search icon
function PriceToolIndividualStep3Expanded1(props) {
	return (
		<div>
			<Container>


				{/* BREADCRUMB */}
				<div>
					<img src={chevronleft} alt="img" />
					<button className="expanded-back-button" onClick={() => props.handleStep("Tax Preparation Individual Compact")}>Individual Tax Preparation Price Ranges</button>
				</div>

				{/* MAIN CONTENT */}
				<div className="box-shadow expanded-div">
					<p className="expanded-heading">Individual Tax Returns with Form W-2 </p>

					<p className="expanded-text">If income is less than $50,000, then <span className="bold">$200</span></p>
					<p className="gold-disclaimer">We help modest-income tax filers!</p>

					<p className="expanded-text">If income is over $50,000, then <span className="bold">$225</span></p>

					<p className="expanded-text">Price quote for additional W-2’s = <span className="bold">$35 each</span></p>
					<p className="gold-disclaimer">Please expect a bulk W-2 discount from us!</p>

					<p className="expanded-text">Do you have additional sources of income, i.e. business, freelance / Airbnb, rental property / investment / international income?  Please go back to the prior screen and check out our pricing for the respective selection.</p>
				</div>

			</Container>




		</div>
	);
}
export default PriceToolIndividualStep3Expanded1;
