import React from 'react'

import img from '../Images/bitmoji-one-finger.png';


// SearchBar with a placeholder and search icon
function Disclaimer3(props) {
	return (
		<div className="d3-div">
			<p className="d3-text">Stuck?  Call us, we are here to help!  <a href="https://calendly.com/agroaccounting-east">Calendly</a></p>
			<img className="d3-img" src={img} alt="img" />
		</div>
	);
}
export default Disclaimer3;
