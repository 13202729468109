import React from 'react'
import PublicNav from "../components/PublicNav";
import "../index.css";
import axios from "axios";



class PasswordResetLink extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pin: "",
			password: "",
			error: "",
			confirmationMessage: ""

		}
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	componentWillReceiveProps(nextProps) {

		console.log(nextProps)

	}

	//confirmationMessage: "Password Succesfully Changed"
	//error: "This pin doesn't match our records. Password reset failed.",

	newPassword = () => {
		// Send the Reset Link Email
		axios
			.post('/api/users/resetPassword', { pin: this.state.pin, password: this.state.password })
			.then(res =>
				console.log(res),
				console.log("good pass change"),
				this.setState((state, props) => {
					return {
						error: "",
						confirmationMessage: "Password Succesfully Changed"
					};
				}))
			.catch(err =>
				console.log(err),
				console.log("bad pass change"),
			)


	}


	render() {
		let signin = <p></p>
		if (this.state.confirmationMessage === "Password Succesfully Changed") {
			signin = <a href="/ClientSignIn">Sign In</a>

		}


		return (
			<div>

				<PublicNav />
				<div className="home-secondary-header-div">
					<p className="home-secondary-header">Reset Password</p>
					<p className="normal-text-grey">Please enter your new password</p>
					<div class="underline-thick"></div>
				</div>


				<div className="center-content">
					<p>Pin</p>
					<input className="form-input" type="text" name="pin" placeholder="Enter the pin we sent to your email" onChange={this.handleChange} />
					<p>New Password</p>
					<input className="form-input" type="password" name="password" placeholder="Enter your new password" onChange={this.handleChange} />
					<button onClick={() => this.newPassword()} className="client-button-filled">Change Password</button>

					<p className="emailResponse">{this.state.confirmationMessage}</p>
					{signin}
					<p className="emailBadResponse">{this.state.error}</p>


				</div>


			</div>

		);

	}
}

export default PasswordResetLink;
