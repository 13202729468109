import React from 'react'
import "../index.css";


import Disclaimer5 from "../components/Disclaimer5";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import chevronleft from '../Images/chevron-left.png';



// SearchBar with a placeholder and search icon
function PriceToolIndividualStep3Expanded3(props) {
	return (
		<div>
			<Container>
				<Col>

					{/* BREADCRUMB */}
					<div>
						<img src={chevronleft} alt="img" />
						<button className="expanded-back-button" onClick={() => props.handleStep("Tax Preparation Individual Compact")}>Individual Tax Preparation Price Ranges</button>
					</div>

					{/* MAIN CONTENT */}
					<div className="box-shadow expanded-div">
						<p className="expanded-heading">Individual Tax Returns with Schedule B, D and/or E: page 2</p>
						<p className="expanded-text">Your price quote will depend on the size and complexity of your investment activities.  Please share your documents with us, through our safe and encrypted document portal on the next screen.  We will get back to you by the end of today with a price quote!</p>
					</div>

					<Disclaimer5 />

				</Col>
			</Container>
		</div>
	);
}
export default PriceToolIndividualStep3Expanded3;
