import React from 'react'
//import PropTypes from 'prop-types'
import "../index.css";


//import Form from 'react-bootstrap/Form'
//import FormControl from 'react-bootstrap/FormControl'
//import FormCheck from 'react-bootstrap/FormCheck'
//import Button from 'react-bootstrap/Button'

//import StepsHeading from "../components/StepsHeading";
//import Step1Option from "../components/Step1Option";
//import PriceRangeCompact from "../components/PriceRangeCompact";

import Disclaimer7 from "../components/Disclaimer7";

import Container from 'react-bootstrap/Container'
//import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'


// SearchBar with a placeholder and search icon
function PriceToolOtherServices2(props) {

	// if (props.service !== "Accounting & Business Consulting Price Quote"){
	//   return null
	// }


	return (
		<div>


			{/* MAIN CONTENT */}
			<Container>
				<p className="steps-heading">Accounting & Business Consulting Price Quote</p>

				<div className="box-shadow expanded-div">


					<p className="text-center-bold">$125 per hour</p>
					<Disclaimer7 />

				</div>
			</Container>

			<div className="pricetool-other-footer footer">
				<button className="button-outlined" onClick={() => props.handleStep("Select a Service")}>
					Back
    </button>

				<button className="button-filled" onClick={() => props.handleStep("Accounting & Business Consulting Modal1")}>
					Next
    </button>
			</div>



		</div>
	);
}
export default PriceToolOtherServices2;
