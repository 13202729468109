import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios";
import { logoutUser } from "../actions/authActions";
import { connect } from "react-redux";

import { Table } from 'reactstrap';

import logo from '../Images/logo.png';

class AdminApproveUser extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      list: [],
      update: true
    }
  }

  componentDidMount() {

    if (this.props.auth.user.role != "Basil"){
    	window.location.href = "/Admin/Dashboard";
    }

    axios.get("/api/users/getAllAdmins")
      .then(res => {
        this.setState({
          list: res.data
        })
      })
      .catch(err => console.log(err));

  }

  changeEmployeeAccess = (id) => {
    axios.post("/api/users/changeEmployeeAccess", {id:id})
      .then(res => {
        this.componentDidMount();
      })
      .catch(err => console.log(err));
  }


  render () {
    console.log(this.state)
    let { auth } = this.props;

    let items = this.state.list.map((object, index) => (
    <tbody>
    <tr key={index}>
      <th scope="row">{index}</th>
      <td>{object.name}</td>
      <td>{object.email}</td>
      <td>{object.role}</td>
      <button className={object.employeeAccess === true ? "admin-button-negative" : "admin-button-complete"} onClick={() => this.changeEmployeeAccess(object._id)} >{object.employeeAccess === false ? "Allow Access?" : "Remove Access?"}</button>
    </tr>
   </tbody>

    ));

    return(
      <div>
        <div className="admin-nav">
          <a className="client-nav-logo" href="/Admin/Dashboard">
            <img className="logo" src={logo} alt="img" />
          </a>

          <div className="admin-nav-detail">

            <div>
              <p className="admin-nav-name">{auth.user.name}</p>
              <p className="admin-nav-role">{auth.user.role}</p>
            </div>


          </div>
        </div>


       <Table>
         <thead>
           <tr>
             <th>#</th>
             <th>Name</th>
             <th>Email</th>
             <th>Role</th>
             <th>Action</th>

           </tr>
         </thead>

         {items}


       </Table>

      </div>
    );

  }
}


AdminApproveUser.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};



const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(
	mapStateToProps,
	{ logoutUser }
)(AdminApproveUser);
