import React from 'react'
import PropTypes from 'prop-types'

import "../index.css";

import Disclaimer9 from "./Disclaimer9";
import Disclaimer10 from "./Disclaimer10";

import Form from 'react-bootstrap/Form'
import FileBase64 from 'react-file-base64';

//import UploadDocumentsIcon from "../components/UploadDocumentsIcon";
import pdficon from '../Images/pdf-icon.png'; // Tell Webpack this JS file uses this image
import excelicon from '../Images/excel-icon.png'; // Tell Webpack this JS file uses this image
import docicon from '../Images/document-icon.png'; // Tell Webpack this JS file uses this image
import imageicon from '../Images/image-icon.png'; // Tell Webpack this JS file uses this image
import checkcircle from '../Images/check-circle.png'; // Tell Webpack this JS file uses this image

import timeline from '../Images/timeline-gather-documents.png'; // Tell Webpack this JS file uses this image



class UploadDocumentsText extends React.Component {
  render () {

    let showorhide1 = "flex";
    let newBulletPoint = <div></div>

    if(this.props.hideForBusiness == "none"){
      showorhide1 = "none"
    }

    if(this.props.service == "business"){

    newBulletPoint = <div className="row-all requiredDocumentsDiv">
        <div className="required-documents-checklist">
          <img className="check-circle" src={checkcircle} alt="img" />
        </div>
        <p className="requiredDocumentsText">If first year in business, upload your company formation documents, including IRS EIN letter</p>
      </div>

    }

    let theFiles;

    if (this.props.files) {
      theFiles =
        <div className="fileDiv">
          <div className="fileIcon">
            <img src={
              (this.props.files.type === "application/pdf") ? pdficon
                : (this.props.files.type === "application/vnd.ms-excel") ? excelicon
                  : (this.props.files.type === "text/csv") ? excelicon
                    : (this.props.files.type === "text/tab-separated-value") ? excelicon
                      : (this.props.files.type === "application/doc") ? docicon
                        : (this.props.files.type === "application/docx") ? docicon
                          : (this.props.files.type === "image/png") ? imageicon
                            : (this.props.files.type === "image/jpg") ? imageicon
                              : (this.props.files.type === "image/jpeg") ? imageicon
                                : imageicon} alt="img" />
          </div>
          <p className="fileName">{this.props.files.name}</p>
        </div>
      //))

    }


    return(

      <div className="screen-padding">
        <Disclaimer9 />

        <div className="row">
          {/*Left Side*/}
          <div className="col half">
            <p className="requiredDocumentsText">Supply the following documents to us</p>
            <div className="col">
              <div className="row-all requiredDocumentsDiv">
                <div className="required-documents-checklist">
                  <img className="check-circle" src={checkcircle} alt="img" />
                </div>
                <p className="requiredDocumentsText">{this.props.text1}</p>
              </div>


              <div className="row-all requiredDocumentsDiv">
                <div className="required-documents-checklist">
                  <img className="check-circle" src={checkcircle} alt="img" />
                </div>
                <p className="requiredDocumentsText">{this.props.text2} <a href="/cpa-free-guidance"> CPA's Free Guidance</a></p>
              </div>

              <div className="row-all requiredDocumentsDiv">
                <div className="required-documents-checklist">
                  <img className="check-circle" src={checkcircle} alt="img" />
                </div>
                <p className="requiredDocumentsText">{this.props.text3}  </p>
              </div>

              {this.props.stimulusText && <div style={{ display: showorhide1}} className="row requiredDocumentsDiv">
                <div className="required-documents-checklist">
                  <img className="check-circle" src={checkcircle} alt="img" />
                </div>
                <p className="requiredDocumentsText">{this.props.stimulusText}  </p>
              </div>}

              <div className="row-all requiredDocumentsDiv">
                <div className="required-documents-checklist">
                  <img className="check-circle" src={checkcircle} alt="img" />
                </div>
                <p className="requiredDocumentsText">{this.props.text4}</p>
              </div>

              {newBulletPoint}

              <div className="row-all requiredDocumentsDiv">
                <div className="required-documents-checklist">
                  <img className="check-circle" src={checkcircle} alt="img" />
                </div>
                <p className="requiredDocumentsText">{this.props.text5}</p>
              </div>
            </div>
          </div>

      




          {/*End of Row*/}
        </div>

        <Disclaimer10 />




      </div>
    );

  }
}

export default UploadDocumentsText;
