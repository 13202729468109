import React from 'react'

// SearchBar with a placeholder and search icon
function Disclaimer11(props) {
	return (
		<div className="disclaimer9-div">

			<p className="disclaimer9-text">
				Message us when all of your files are up, please!
      </p>

		</div>
	);
}
export default Disclaimer11;
