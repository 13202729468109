import React from 'react'

import img from '../Images/free-guidance-img.png';



// SearchBar with a placeholder and search icon
function FreeGuidanceCategory(props) {
	return (
		<div className="free-guidance-category-div">

			<a href={props.link}>
				<div className="free-guidance-category-button">
					<img className="free-guidance-category-img" src={img} alt="img" />
					<p className="free-guidance-category-text">{props.text}</p>
				</div>
			</a>

		</div>
	);
}
export default FreeGuidanceCategory;
