import React from 'react'
import "../index.css";

import img from '../Images/bitmoji-one-finger.png';

// SearchBar with a placeholder and search icon
function WelcomeBackClient(props) {
	return (
		<div className="welcome-div">
			<p className="welcome-text1">Welcome Dear Client</p>
			<p className="welcome-text2">Check out the new platform we have built to better work on your tax returns.  Please create a new order to get started!</p>

			<a className="welcome-button" href="/Client/PriceTool">New Order</a>
			<img className="welcome-img" src={img} alt="img" />
		</div>
	);
}
export default WelcomeBackClient;
