import React from 'react'
import PropTypes from 'prop-types'

class ClientFile extends React.Component {
  render () {
    console.log(this.props.files)
    let links;
    links = this.props.files.map((object, index) => (
    	<div>
    		<a className="dashboard-file-link" href={object.link}>{object.name}</a> <br></br><br></br>
    		<button onClick={() => this.props.deleteFile(object.link) }>Delete</button>
    	</div>
    ))
    return(
      <div>
        {links}
      </div>
    );

  }
}

export default ClientFile;
