import {
	GAPI_LOAD_REQUEST, GAPI_LOAD_SUCCESS,
	AUTHORIZED, UNAUTHORIZED,
	GET_MESSAGE_LIST_REQUEST, GET_MESSAGE_LIST_SUCCESS,
	FETCH_MESSAGE_LIST_REQUEST, FETCH_MESSAGE_LIST_SUCCESS,
	GET_MESSAGE_REQUEST, GET_MESSAGE_SUCCESS,
	SEND_MESSAGE_REQUEST, SEND_MESSAGE_SUCCESS
} from './types';

import { getHeader, getAttachments, getHtmlBody, markMessageAsRead } from './messageMethods';
import { saveAs } from 'file-saver';
import quotedPrintable from 'quoted-printable'
import utf8 from 'utf8'

const gapiLoadRequest = () => ({ type: GAPI_LOAD_REQUEST });
const gapiLoadSuccess = () => ({ type: GAPI_LOAD_SUCCESS });
const authorized = () => ({ type: AUTHORIZED });
const unauthorized = () => ({ type: UNAUTHORIZED });

const updateSigninStatus = (isSignedIn) => dispatch => {
	if (isSignedIn) {
		dispatch(authorized());
	} else {
		dispatch(unauthorized());
	}
};

export const authorize = () => dispatch => {
	window.gapi.auth2.getAuthInstance().signIn();
};

export const unauthorize = () => dispatch => {
	window.gapi.auth2.getAuthInstance().signOut();
};

export const loadGapi = () => dispatch => {
	dispatch(gapiLoadRequest());
	let gapi = window.gapi;
	gapi.load('client:auth2', () => {
		gapi.client.init({
			discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
			clientId: '612007410839-5p14nah2du1u97t1ujmmr05g7a3rp4qq.apps.googleusercontent.com',
			scope: 'https://www.googleapis.com/auth/gmail.readonly' +
				' https://www.googleapis.com/auth/gmail.send' +
				' https://www.googleapis.com/auth/gmail.modify'
		}).then(() => {
			dispatch(gapiLoadSuccess());
			gapi.auth2.getAuthInstance().isSignedIn.listen((status) => dispatch(updateSigninStatus(status)));
			dispatch(updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get()));
		});
	});
};


//Message List Actions

const receiveMessageList = (messageListName, pageToken, onRequest, onSuccess) => dispatch => {
	let gapi = window.gapi;
	dispatch(onRequest());
	gapi.client.gmail.users.messages.list({
		userId: 'me',
		labelIds: messageListName,
		maxResults: 20,
		pageToken: pageToken
	}).execute(response => {
		let promise = new Promise((resolve, reject) => {
			let messages = [];
			let counter = 0;
			if (response.messages !== undefined) {
				counter = response.messages.length;
				response.messages.forEach(message => {
					gapi.client.gmail.users.messages.get({
						userId: 'me',
						id: message.id
					}).then(({ result }) => {
						messages.push({
							id: result.id,
							snippet: result.snippet,
							isUnread: result.labelIds.includes('UNREAD'),
							payload: {
								headers: result.payload.headers
							}
						});
						if (--counter === 0) {
							resolve(messages);
						}
					}).catch(e => {
						reject(e);
					})
				});
			}
		});

		promise.then(messages => {
			messages.sort((a, b) => new Date(getHeader(b, 'Date')) - new Date(getHeader(a, 'Date')));
			dispatch(onSuccess({
				messages,
				nextPageToken: response.nextPageToken
			}));
		})
	})
};

const getMessageListRequest = () => ({ type: GET_MESSAGE_LIST_REQUEST });
const getMessageListSuccess = (messages) => ({ type: GET_MESSAGE_LIST_SUCCESS, payload: messages });

export const getMessageList = (messageListName) => dispatch => {
	dispatch(receiveMessageList(
		messageListName,
		'',
		getMessageListRequest,
		getMessageListSuccess
	))
};

const fetchMessageListRequest = () => ({ type: FETCH_MESSAGE_LIST_REQUEST });
const fetchMessageListSuccess = (messages) => ({ type: FETCH_MESSAGE_LIST_SUCCESS, payload: messages });

export const fetchMessageList = (messageListName) => (dispatch, getState) => {
	dispatch(receiveMessageList(
		messageListName,
		getState().gmail.nextPageToken,
		fetchMessageListRequest,
		fetchMessageListSuccess
	));
};


/* Message Actions */
const getMessageRequest = () => ({ type: GET_MESSAGE_REQUEST });
const getMessageSuccess = (message) => ({ type: GET_MESSAGE_SUCCESS, payload: message });

export const getMessage = (id) => dispatch => {
	dispatch(getMessageRequest());
	window.gapi.client.gmail.users.messages.get({
		userId: 'me',
		id
	}).then(({ result }) => {
		dispatch(getMessageSuccess({
			id: result.id,
			payload: {
				headers: result.payload.headers,
				htmlBody: getHtmlBody(result),
				attachments: getAttachments(result)
			}
		}));
		markMessageAsRead(result.id);
	})
};


export const downloadAttachment = (messageId, attachment) => dispatch => {
	window.gapi.client.gmail.users.messages.attachments.get({
		userId: 'me',
		messageId,
		id: attachment.body.attachmentId
	}).execute(({ result }) => {
		let byteString = atob(result.data.replace(/-/g, '+').replace(/_/g, '/'));

		let ab = new ArrayBuffer(byteString.length);
		let ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		let blob = new Blob([ia], { type: attachment.mimeType });

		saveAs(blob, attachment.filename);
	})
};


/* Send Messages */
const encode = (s) => btoa(unescape(encodeURIComponent(s))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
const encodeHeader = (s) => `=?utf-8?Q?${quotedPrintable.encode(utf8.encode(s))}?=`;

export const reply = (messageToReply, replyMessage, attachments) => dispatch => {
	const to = getHeader(messageToReply, 'From');
	const subject = encodeHeader('Re: ' + getHeader(messageToReply, 'Subject'));
	const messageId = getHeader(messageToReply, 'Message-Id');

	const headers = {
		'To': to,
		'In-Reply-To': to,
		'References': to,
		'Message-Id': messageId,
		'Subject': subject
	};

	dispatch(send(headers, replyMessage, attachments));
};


export const sendMessage = (to, subject, message, attachments) => dispatch => {
	const headers = {
		'To': to,
		'Subject': encodeHeader(subject)
	};

	dispatch(send(headers, message, attachments));
};

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });

const send = (headers, message, attachments) => dispatch => {
	dispatch(sendMessageRequest());

	let email = [
		'Content-Type: multipart/mixed; boundary="my_boundary"',
		'MIME-Version: 1.0'
	];

	for (let h in headers) {
		email.push(`${h}: ${headers[h]}`)
	}

	email.push(
		'',
		'--my_boundary'
	);

	if (!/^\s*$/.test(message)) {
		email.push(
			'Content-Type: text/plain; charset="UTF-8"',
			'MIME-Version: 1.0',
			'Content-Transfer-Encoding: 7bit',
			'',
			`${message}`,
			'',
			'--my_boundary'
		)
	}

	if (attachments.length > 0) {
		for (let i = 0; i < attachments.length; i++) {
			email.push(
				`Content-Type: ${attachments[i].type}`,
				'MIME-Version: 1.0',
				'Content-Transfer-Encoding: base64',
				`Content-Disposition: attachment; filename="${attachments[i].name}"`,
				'',
				btoa(attachments[i].blob),
				'',
				'--my_boundary'
			)
		}
	}

	email = encode(email.join('\r\n') + '--');

	window.gapi.client.gmail.users.messages.send({
		userId: 'me',
		resource: {
			raw: email
		}
	}).then(response => {
		dispatch(sendMessageSuccess())
	})
};