import React from 'react'

import "../index.css";

// The Big headings
function HomeSecondaryHeader(props) {
	return (
		<div className="home-secondary-header-div">
			<p className="home-secondary-header">{props.text}</p>
			<div class="underline-thick"></div>
		</div>
	);
}
export default HomeSecondaryHeader;
