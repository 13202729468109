import { GET_TAXRETURN, ADD_TAXRETURN,UPDATE_TAXRETURN, TAXRETURN_LOADING } from "../actions/types";

const initialState = {

  taxReturns: [],
  loading: false

};


export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TAXRETURN:
      return {
        ...state,
        taxReturns: action.payload,
        loading: false
      };
     case ADD_TAXRETURN:
     return {
       ...state,
       taxReturns: [action.payload, ...state.taxReturns]
     };
     case UPDATE_TAXRETURN:
     return {
       ...state,
       taxReturns: [action.payload, ...state.taxReturns]
     };
     case TAXRETURN_LOADING:
     return {
     ...state,
     loading: true
   };


    default:
      return state;
  }
}
