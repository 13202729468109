	import React from 'react'
import PropTypes from 'prop-types'
import "../index.css";
import axios from "axios";

import { connect } from "react-redux";
import { getTaxReturnsByEmail, updateTaxReturnAcceptOffer, updateTaxReturnFiles, updateSheetsLink } from '../actions/taxReturnActions';
import { logoutUser } from "../actions/authActions";
import Form from 'react-bootstrap/Form'

//import Tabs from 'react-bootstrap/Tabs'
//import Tab from 'react-bootstrap/Tab'
//import FileManager from "../components/FileManager";

//import Checkout from '../components/CheckoutForm.js';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from '../components/CheckoutForm';
import ClientFiles from './ClientFiles';
import ClientTaxReturnComponent from './ClientTaxReturnComponent'

//import profileimg from '../Images/profileimg.png';
import logo from '../Images/logo.png';

import FileBase64 from '../components/react-file-base64';

import imgBottom from '../Images/bitmoji-one-finger.png';

import paymentOne from '../Images/payment-1.png';
import paymentTwo from '../Images/payment-2.png';
import paymentThree from '../Images/payment-3.png';
import paymentFour from '../Images/payment-4.png';
import paymentFive from '../Images/payment-5.png';

import UploadDocumentsText from "../components/UploadDocumentsText";
import Disclaimer11 from "../components/Disclaimer11";




//import pdficon from '../Images/pdf-icon.png'; // Tell Webpack this JS file uses this image
//import excelicon from '../Images/excel-icon.png'; // Tell Webpack this JS file uses this image
//import docicon from '../Images/document-icon.png'; // Tell Webpack this JS file uses this image
//import imageicon from '../Images/image-icon.png'; // Tell Webpack this JS file uses this image
//import checkcircle from '../Images/check-circle.png'; // Tell Webpack this JS file uses this image




class ClientTaxReturn extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			id: null,
			taxItem: this.props.taxReturns,
			files: [],
			fileStatus:"",
			base64: [],
			step: "Activity",
			rerender: "something",
			errors: null,
			emailSubject: "",
			emailBody: "",
			name: this.props.auth.user.name,
			email: this.props.auth.user.email,
			documentsLink: "",
			errorDocumentLink: "",
			newNotes: "",
			errorNewNotes: "",
			spouseName:"",
			errorSpouseName:"",
			errorName:"",
			fileDeleteStatus:"",
			update: " ",
			successNameChange:""
		}
	}



	static propTypes = {
		getTaxReturnsByEmail: PropTypes.func.isRequired,
		getTaxReturns: PropTypes.func.isRequired,
		updateTaxReturnAcceptOffer: PropTypes.func.isRequired,
		updateTaxReturnFiles: PropTypes.func.isRequired,
		updateSheetsLink: PropTypes.func.isRequired

	}; if(nextProps) {
		this.forceUpdate();

		this.setState((state, props) => {
			return {
				id: nextProps.id
			};
		});
	}




	componentDidMount() {

		if (!this.props.auth.isAuthenticated) {
			window.location.href = "/";
		}



		this.props.getTaxReturnsByEmail(this.props.auth.user.email)



		if(this.props.page == "File Manager"){
		this.setState((state, props) => {
			return {
				step: "File Manager"
			};
		});
	}


	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	onLogoutClick = () => {
		//e.preventDefault();
		this.props.logoutUser();
		window.location.href = "/";

	};


	handleFiles = (items) => {
		console.log(items);


		this.setState((state, props) => {
			return {
				files: items,
				fileStatus: "Uploading..."
			};
		});

		this.updateTaxReturnFiles()
	}


	updateTaxReturnFiles = () => {
		const { id } = this.props.match.params;

		//this.props.updateTaxReturnFiles({files:this.state.files, id:id})

		axios
			.put('/api/taxReturns/updateTaxReturnFiles', { files: this.state.files, id: id })
			.then(res => {
				this.props.getTaxReturnsByEmail(this.props.auth.user.email)
				this.setState((state, props) => {
					return {
						fileStatus: "Upload Complete",
						fileDeleteStatus:""
					};
				});
			}
			)
			.catch(err => {
				//this.props.getTaxReturnsByEmail(this.props.auth.user.email),
				console.log("An Error has occurred")

				this.setState((state, props) => {
					return {
						fileStatus: "File could not be uploaded"
					};
				});
					}
			);



	}

	// Delete Note uploaded by user
	deleteNote = (input) => {
		console.log(input);
		axios
			.post('/api/taxReturns/deleteNote', { note: input.note, id: input.id })
			.then(res => {
				console.log(res)

				this.setState((state, props) => {
					return {
						noteStatus:"",
						noteDeleteStatus:"Note Deleted"
					};
				});

				this.props.getTaxReturnsByEmail(this.props.auth.user.email)

			}
			)
			.catch(err => {
				console.log("An Error has occurred")

				this.setState((state, props) => {
					return {
						fileStatus:"File Could not be uploaded contact basil@agroaccounting.com"
					};
				});
					}
			);
	}


	// Delete Files uploaded by user
	deleteFile = (input) => {
		console.log(input);
		axios
			.post('/api/taxReturns/deleteTaxReturnFile', { file: input.link, id: input.id })
			.then(res => {
				console.log(res)

				this.setState((state, props) => {
					return {
						fileStatus:"",
						fileDeleteStatus:"File Deleted"
					};
				});

				this.props.getTaxReturnsByEmail(this.props.auth.user.email)

			}
			)
			.catch(err => {
				console.log("An Error has occurred")

				this.setState((state, props) => {
					return {
						fileStatus:"File Could not be uploaded contact basil@agroaccounting.com"
					};
				});
					}
			);
	}

	updateFullName = () => {

		if(this.state.name == "") {
			this.setState({
				errorName: "Please enter at least one character",
				name: ""
			})
		} else {

			const { id } = this.props.match.params;
			//this.props.updateSheetsLink({link:this.state.documentsLink, id:id})

			axios
				.put('/api/taxReturns/updateName', { name: this.state.name, id: id })
				.then(res =>
					this.props.getTaxReturnsByEmail(this.props.auth.user.email),

					this.setState({
						name: "",
						errorName: "",
						successNameChange:"Name Change Successful"

					})

				)
				.catch(err =>
					console.log(err)
				);

		}

	}



	updateSpouseName = () => {

		if(this.state.spouseName == "") {
			this.setState({
				errorSpouseName: "Please enter at least one character",
				spouseName: ""
			})
		} else {

			const { id } = this.props.match.params;
			//this.props.updateSheetsLink({link:this.state.documentsLink, id:id})

			axios
				.put('/api/taxReturns/updateSpouseName', { spouseName: this.state.spouseName, id: id })
				.then(res =>
					this.props.getTaxReturnsByEmail(this.props.auth.user.email),

					this.setState({
						spouseName: "",
						errorSpouseName: ""

					})

				)
				.catch(err =>
					console.log(err)
				);

		}

	}



updateNotes = () => {

	if(this.state.newNotes == "") {
		this.setState({
			errorNewNotes: "Please enter at least one character",
			newNotes: ""
		})
	} else {

		const { id } = this.props.match.params;
		//this.props.updateSheetsLink({link:this.state.documentsLink, id:id})

		axios
			.put('/api/taxReturns/updateNotes', { notes: this.state.newNotes, id: id })
			.then(res =>
				this.props.getTaxReturnsByEmail(this.props.auth.user.email),

				this.setState({
					newNotes: "",
					errorNewNotes: ""

				})

				//console.log("Sheets link added to DB")
			)
			.catch(err =>
				console.log(err)
			);

	}

}



	updateSheetsLink = () => {

		var pattern = /^((http|https):\/\/)/;

		if (!pattern.test(this.state.documentsLink)) {
			this.setState({
				errorDocumentLink: "Please enter a valid url starting with http or https"
			})
		} else {

			const { id } = this.props.match.params;
			//this.props.updateSheetsLink({link:this.state.documentsLink, id:id})

			axios
				.put('/api/taxReturns/updateSheetsLink', { link: this.state.documentsLink, id: id })
				.then(res =>
					this.props.getTaxReturnsByEmail(this.props.auth.user.email),

					this.setState({
						documentsLink: "",
						errorDocumentLink: ""

					})

					//console.log("Sheets link added to DB")
				)
				.catch(err =>
					console.log(err)
				);

		}

	}



	handleStep = (value) => {
		this.setState((state, props) => {
			return {
				step: value
			};
		});
	}



	sendMessage = () => {
		//console.log("in send a message");

		let email = {
			name: this.state.name,
			email: this.state.email,
			message: this.state.emailBody,
			from: "clientinfo@agroaccounting.com"
		}

		axios
			.post('/api/taxReturns/email', email)
			.then(res => {
				console.log(res)
				//this.props.history.push("/EmailSuccesful")

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailBadResponse: "",
					emailResponse: "Email Sent!"
				});




			}
			)
			.catch(err => {
				console.log(err)

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailResponse: "",
					emailBadResponse: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
				});
			}

			);

		//this.props.email(email);


	}









	handleView = (value) => {
		console.log(value);
	}


	declineOffer = () => {
	}

	acceptOffer = (value) => {
		//this.props.updateTaxReturnAcceptOffer(value);
		//const { id } = this.props.match.params;

		axios
			.put('/api/taxReturns/updateTaxReturnAcceptOffer', value)
			.then(res =>
				this.props.getTaxReturnsByEmail(this.props.auth.user.email)
			)
			.catch(err =>
				this.props.getTaxReturnsByEmail(this.props.auth.user.email)
			);


	}

	downloadTaxReturn = () => {
	}

	normalDate = (date) => {
		return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
	}

	componentWillReceiveProps(nextProps) {

		console.log(nextProps);

		this.forceUpdate();

		if (nextProps) {
			this.setState({
				rerender: "",
				errors: nextProps

			});
		}

	}

	decodeAndRemoveHyphens = (encodedString) => {
		// Decode URL-encoded characters
		const decodedString = decodeURIComponent(encodedString);
		// Remove hyphens from the decoded string
		const cleanedString = decodedString.replace(/[-+]/g, ' ');
		
	
		return cleanedString;
	}



	render() {

		const { id } = this.props.match.params;
		let { taxReturns } = this.props.taxReturns;
		console.log(this.props.taxReturns);
		console.log(id);
		console.log(this.state);

		let archiveStatus;

		let links;
		let documentsLink;
		let adminLinks;
		let notes;

		let filesArray = [];
		let filesObject =[];

		let taxReturnLinks1 = [];
		let taxReturnLinks2 = [];
		let taxReturnLinks3 = []


		let links1;
		let links2;






		let theOne = taxReturns.map((object, index) => {

			if(object.archive == true){
				archiveStatus = <p className="archive-status">Archived</p>
			}else{
				archiveStatus = <p className=""></p>
			}



			if (object._id === id) {
				console.log("in here")


				if (object.files !== undefined) {

					//Check for files of type arrays
					filesArray = object.files.filter(item => Array.isArray(item))
					console.log(filesArray)

					links1 = filesArray.map((object, index) => (
						<div className="row">
							<a href={object[1]}>{object[0]}</a> <br></br><br></br>
						<button className="delete-button-client" onClick={() => this.deleteFile({link:object.link, id:id}) }>Delete</button>
						</div>
					))

					//Check for files of type Objects

					filesObject = object.files.filter(item => Array.isArray(item) != true)
					console.log(filesObject.length)

					links2 = filesObject.map((object, index) => (
						<div className="row">
							<a href={object.link}>{object.name}</a> <br></br><br></br>
						<button className="delete-button-client" onClick={() => this.deleteFile({link:object.link, id:id}) }>Delete</button>
						</div>
					))

				}




				if (object.taxReturnFile !== undefined){
					console.log(object.taxReturnFile)

					//Since the save as array was made in the beginning, this is taking the assumption. That only the first 2 values [0],[1] are the array strucute and the rest are objects
					if(typeof(object.taxReturnFile[0]) == "string"){
						console.log("taxReturnFile are in an array - old")

						taxReturnLinks1 = 	<div className="row">
								<a href={object.taxReturnFile[1]}>{this.decodeAndRemoveHyphens(object.taxReturnFile[0])}</a>
							</div>

					}

					//Check for objects
					 if (typeof(object.taxReturnFile[2]) == "object") {
						console.log("taxReturnFile are in an object - new")

						let list = object.taxReturnFile.filter(item => typeof item == 'object')
						console.log(list.length);

							taxReturnLinks2 = list.map((object, index) => (
								<div className="row">
									<a href={object.link}>{this.decodeAndRemoveHyphens(object.name)}</a>
								</div>
							))
				}

				if(typeof(object.taxReturnFile[0]) == "object"){

					taxReturnLinks3 = object.taxReturnFile.map((object, index) => (
						<div className="row">
							<a href={object.link}>{this.decodeAndRemoveHyphens(object.name)}</a>
						</div>
					))

				}

				}








				if (object.documentsLink !== undefined) {
					documentsLink = object.documentsLink.map((object, index) => (
						<div>
							<a className="sheets-label2" href={object}>{object}</a> <br></br><br></br>
						</div>
					))
				}

				if (object.adminDocumentsLink !== undefined) {
					adminLinks = object.adminDocumentsLink.map((object, index) => (
						<div>
							<a target="_blank" className="sheets-label2" href={object}>{object}</a> <br></br><br></br>
						</div>
					))
				}



				if (object.notes !== undefined) {
					notes = object.notes.map((object, index) => (
						<div className="row">
							<p className="normal-text">{object}</p> <br></br>
								<button className="delete-button-client" onClick={() => this.deleteNote({note:object, id:id}) }>Delete</button>

						</div>
					))
				}

				// Documents Checklist Body
				let documentChecklistBody;
				console.log(object.service);

				{/*Documents Checklist Individual*/}
				if(object.service == "Tax Preparation Individual"){
					documentChecklistBody = <UploadDocumentsText
						text1="All of the year-end tax forms you have received"
						text2="Your business, freelance and/or rental income and expenses, gathered and presented per our "
						text3="Your health insurance information (months covered and total amount paid) or Form 1095, if any"
						text4="The last tax returns you filed, Federal and State"
						text5="Your driver’s license or State ID card, front and back"
						handleStep={this.handleStep}
						handleChange={this.handleChange}
						handleFiles={this.handleFiles}
						files={this.state.files}
					/>
				}




				{/*Documents Checklist Business*/}
				if(object.service == "Tax Preparation Business"){
					documentChecklistBody = <UploadDocumentsText
						text1="Give us access to your bookkeeping software at basil@agroaccounting.com"
						text2="Don't use bookkeeping software?  Your business, freelance and/or rental income and expenses, gathered and presented per our "
						text3="All of the year-end tax forms received for your company"
						stimulusText="Amount of March 2021 Stimulus check, if any "
						hideForBusiness="none"
						service="business"
						text4="The last tax returns filed for your company, Federal and State"
						text5="The signing partner or director's license or State ID"
						handleStep={this.handleStep}
						handleChange={this.handleChange}
						handleFiles={this.handleFiles}
						next="What Happens Next Business"
						back="Modal 2 Business"
						files={this.state.files}
					/>
				}



				if (this.state.step === "Activity") {

					return (
						<div>
							{/*Top Part*/}
							<div className="client-tax-dashboard-section1">
								<div className="client-nav">
									<a className="client-nav-logo" href="/Client/Dashboard">
										<img className="logo2" src={logo} alt="img" />
									</a>
									<div className="client-nav-detail">
										<a href="/Client/PriceTool" className="margin-right client-button-filled" >New Order</a>
										<div className="col">
											<p className="admin-nav-name">{this.props.auth.user.name}</p>
											<p className="admin-nav-role">{this.props.auth.user.role}</p>
											<a href="/ForgotPassword">Reset Password?</a>

										</div>
										<button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>
									</div>


								</div>



								<div className="client-tax-dashboard-row-1">
									{/*Name etc*/}
									<div className="">

										<div className="col">
											<p className="client-tax-dashboard-name">{object.name}</p>
											<p className="client-tax-dashboard-type">Client</p>
											{archiveStatus}

										</div>
									</div>

									{/*QuickView*/}
									<div className="quickview-client">
										<div className="quickview-1">
											<p className="quickview-text">Service: <span className="bold2">{object.service}</span></p>
											{(object.spouseFullName != null) ? <p className="quickview-text">Spouse Full Name: <span className="bold2">{object.spouseFullName}</span></p> : <p className="quickview-text">Spouse Full Name: <span className="bold2">N/A</span></p>}

										</div>

									</div>

								</div>

								{/*Nav*/}
								<div className="client-tax-dashboard-nav-div-1">
									<a href="/Client/Dashboard" className="client-tax-dashboard-nav-link">Dashboard</a>
									<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Activity")}>Activity</button>
									<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Communications")}>Communications</button>
									<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("File Manager")}>File Manager</button>
									<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Document Checklist")}>Document Checklist</button>

								</div>

							</div>




							<div>

								{/*1*/}
								<div className="client-activity-div-0">
									<div>
										<p className="activity-heading">Document Upload</p>
										<p className="activity-sub-heading">Total Files: {object.files.length}</p>
									</div>
									<button className="activity-button-outlined" onClick={() => this.handleStep("File Manager")}>File Manager</button>
								</div>

								{/*2*/}
								{object.isAmountAgreedSentByTeamMember === true ?
									<div className="client-activity-div-invoice-agreement">
										<div className="">
											<p className="activity-heading">Invoice Agreement</p>
											<p className="activity-sub-heading">Total Cost: <span className="bold2">${object.amount}</span></p>
										</div>

										{object.isAmountAgreedByClient === false ?
											<div className="client-activity-div3">
												<button className="activity-double-button-1-agreement" onClick={() => this.handleStep("Communications")}>Decline? Email us</button>
												<button className="activity-double-button-2-agreement" onClick={() => this.acceptOffer({ id })}>Accept</button>
											</div>
											: <p className="normal-text">You have accepted the price quote</p>}
									</div>
									: <p></p>}


								{/*3*/}
								{object.isEFileAuthSent === true ?
									<div className="client-activity-div">
										<div>
											<p className="activity-heading">Sign E-File Authorization Forms</p>
											<p className="activity-sub-heading">Sent via: Email</p>
										</div>

										<div className="client-activity-disclaimer">
											<p className="client-activity-disclaimer-text">Please check your email account.  Our email's subject line is "From Basil, please open."  Check your spam if you don't see it!</p>
										</div>
									</div>
									: <p></p>}


								{/*4*/}
								{object.isAmountAgreedByClient === true && object.isEFileAuthAccepted === true ?
									<div className="client-activity-stripe-div">
										<div>
											<p className="activity-heading">Payment Portal</p>
											<a href="https://www.paypal.me/BasilA" className="bold3">Pay us through Paypal</a>
											<br></br>
											<br></br>
											<img className="payment-img" src={paymentFour} alt="img" />
										</div>


										{
											<div>
												{/*
												<StripeProvider apiKey="pk_live_cje7h02NpoVjtKVikrPLroSi005MRt0qep">
													<div className="example">
														<Elements>
															<CheckoutForm name={this.props.auth.user.name} taxReturnId={id} amount={object.amount} isAmountPayedByClient={object.isAmountPayedByClient}  />
														</Elements>
														<p>{this.state.rerender}</p>

													</div>
												</StripeProvider>
												*/}
												<p className="activity-sub-heading">Electronic Payments Accepted: Credit/Debit</p>
												<form className="nav-link" name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="e77649c1-0718-4348-9456-50037b843ea4" /> <input type = "submit" value = "Make a Payment" /> </form>
												<br></br>
												<div className="Row">
														<img className="payment-img" src={paymentOne} alt="img" />
														<img className="payment-img" src={paymentTwo} alt="img" />
														<img className="payment-img" src={paymentThree} alt="img" />
														<img className="payment-img" src={paymentFive} alt="img" />
													</div>
											</div>


										}
									</div>
									: <p></p>}


								{/*5*/}
								{object.isTaxReturnUploadedByTeamMember === true ?
									<div className="client-activity-div">
										<div>
											<p className="activity-heading">Download Filed Tax Return</p>
												<p className="activity-sub-heading">Find your tax return under the Finalized Tax Return(s) section in File Manager</p>
										</div>
										{/*<a className="activity-button-outlined" href={object.taxReturnFile[1]}>Download</a>*/}
										<button className="activity-button-outlined" onClick={() => this.handleStep("File Manager")}>File Manager</button>

									</div>
									: <p></p>}

								{object.isAmountPayedByClient === true ?
									<div className="col-center">
										<p className="client-activity-footer-text">Thank you so very much for hiring my CPA firm this year.  If you think you had a good experience with us, be so kind as to share it on our <a href="https://www.yelp.com/biz/agro-accounting-cpa-brooklyn-4" className="client-activity-footer-link">Yelp</a> and <a href="https://www.google.com/search?authuser=5&source=hp&ei=xTslXvfNBaezggfagoXYBw&q=basil+cpa&oq=basil+cpa&gs_l=psy-ab.3..0i22i10i30j0i22i30l7j0i22i10i30j0i22i30.2159.3998..4148...0.0..0.306.1917.0j3j3j2......0....1..gws-wiz.......0j0i131j0i10.ASPnFeZQbFU&ved=0ahUKEwi3xJCaupHnAhWnmeAKHVpBAXsQ4dUDCAg&uact=5#lrd=0x89c258fc3009319d:0xa543b06ed05d6e6c,3,,," className="client-activity-footer-link">Google</a>

										</p>
										<img className="welcome-img" src={imgBottom} alt="img" />

									</div>
									: <p></p>}
							</div>
						</div>
					)
				}

				if (this.state.step === "Communications") {
					return (
						<div className="client-tax-dashboard-section1 padding-bottom-lg">
							<div className="client-nav">

								<a className="client-nav-logo" href="/">
									<img className="logo2" src={logo} alt="img" />
								</a>

								<div className="client-nav-detail">
									<a href="/Client/PriceTool" className="margin-right client-button-filled" >New Order</a>

									<div className="col">
										<p className="admin-nav-name">{this.props.auth.user.name}</p>
										<p className="admin-nav-role">{this.props.auth.user.role}</p>
									</div>
									<button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>
								</div>


							</div>


							<div className="client-tax-dashboard-row-1">
								{/*Name etc*/}
								<div className="col-center">


									<div className="col">
										<p className="client-tax-dashboard-name">{object.name}</p>
										<p className="client-tax-dashboard-type">Client</p>
										{archiveStatus}
									</div>
								</div>

								{/*QuickView*/}
								<div className="quickview-client">
									<div className="quickview-1">
										<p className="quickview-text">Service: <span className="bold2">{object.service}</span></p>
										{(object.spouseFullName != null) ? <p className="quickview-text">Spouse Full Name: <span className="bold2">{object.spouseFullName}</span></p> : <p className="quickview-text">Spouse Full Name: <span className="bold2">N/A</span></p>}
									</div>
								</div>

							</div>

							{/*Nav*/}
							<div className="client-tax-dashboard-nav-div-1">
								<a href="/Client/Dashboard" className="client-tax-dashboard-nav-link">Dashboard</a>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Activity")}>Activity</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Communications")}>Communications</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("File Manager")}>File Manager</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Document Checklist")}>Document Checklist</button>

							</div>

							<div className="center-content-comms">
								<p className="admin-workflow-heading">Communications</p>

								<p className="normal-text">From: {object.email}</p>
								<p className="normal-text">To: clientinfo@agroaccounting.com</p>

								<div className="">
									<textarea onChange={this.handleChange} placeholder="Type Your Message..." name="emailBody"></textarea>
									<button className="contact-button" onClick={() => this.sendMessage()}>Submit</button>
									<p className="emailResponse">{this.state.emailResponse}</p>
									<p className="emailBadResponse">{this.state.emailBadResponse}</p>
								</div>
							</div>


						</div>
					)
				}

				if (this.state.step === "File Manager") {
					return (

						<div className="client-tax-dashboard-section1 padding-bottom-lg">

							<div className="client-nav">

								<a className="client-nav-logo" href="/">
									<img className="logo2" src={logo} alt="img" />
								</a>

								<div className="client-nav-detail">
									<a href="/Client/PriceTool" className="margin-right client-button-filled" >New Order</a>

									<div className="col">
										<p className="admin-nav-name">{this.props.auth.user.name}</p>
										<p className="admin-nav-role">{this.props.auth.user.role}</p>
									</div>
									<button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>
								</div>


							</div>


							<div className="client-tax-dashboard-row-1">
								{/*Name etc*/}
								<div className="">


									<div className="col">
										<p className="client-tax-dashboard-name">{object.name}</p>
										<p className="client-tax-dashboard-type">Client</p>
										{archiveStatus}

									</div>
								</div>

								{/*QuickView*/}
								<div className="quickview-client">
									<div className="quickview-1">
										<p className="quickview-text">Service: <span className="bold2">{object.service}</span></p>
										{(object.spouseFullName != null) ? <p className="quickview-text">Spouse Full Name: <span className="bold2">{object.spouseFullName}</span></p> : <p className="quickview-text">Spouse Full Name: <span className="bold2">N/A</span></p>}
									</div>


								</div>

							</div>

							{/*Nav*/}
							<div className="client-tax-dashboard-nav-div-1">
								<a href="/Client/Dashboard" className="client-tax-dashboard-nav-link">Dashboard</a>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Activity")}>Activity</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Communications")}>Communications</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("File Manager")}>File Manager</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Document Checklist")}>Document Checklist</button>

							</div>

							<div className="center-content-comms">
								<Disclaimer11 />

								<p className="admin-workflow-heading">File Manager</p>
								<p className="blue-text"></p>

								<div className="admin-file-manager">

									<div className="admin-file-manager-label-div">
										<p className="admin-file-manager-label">Documents</p>
										<p className="admin-file-manager-label">Upload files one at a time</p>
										<p className="admin-file-manager-label">Files Accepted: PDF, JPEG & PNG, Microsoft Office</p>
										<br></br>
										<p className="normal-text-grey2">Larger files can take up to 30 seconds to upload</p>
										<br></br>
										<p className="highlight-yellow width-tight normal-text" style={{padding:"8px", borderRadius:"7px"}}>Use Google Drive or Dropbox folder sharing if you have more than 3 documents</p>
										<br></br>
										<p className="highlight-yellow width-tight normal-text" style={{padding:"8px", borderRadius:"7px"}}>If you are having trouble uploading your files, consider adding all of your documents to a Google Drive or Dropbox folder, then share the link to it with us on the URL line.</p>
										<br></br>
										<p className={ (this.state.fileStatus) === "File Could not be uploaded contact basil@agroaccounting.com" ? "emailBadResponse" : "Complete" ? "emailResponse" : "Pending" }>{this.state.fileStatus}</p>
										<p className="emailBadResponse">{this.state.fileDeleteStatus}</p>
									<FileBase64
											multiple={false}
											onDone={this.handleFiles} />

										<div className="admin-files-div">

											{links1}
											{links2}

										</div>

										<br></br>
										<div className="admin-file-manager-label-div">
											<p className="admin-file-manager-label">Finalized Tax Return(s)</p>

											<br></br>
											{taxReturnLinks1}
											{taxReturnLinks2}
											{taxReturnLinks3}


										</div>
										<br></br>
										<br></br>

										<div>
											<p className="admin-file-manager-label">Links from Agro Accounting</p>
											{adminLinks}
										</div>


									</div>



									<div>
										<p className="admin-file-manager-label">Google Docs or Dropbox <u>FOLDER</u> link(s)</p>
										<p className="admin-file-manager-label">(Must start with http or https, copy and paste your URL from the browser)</p>
										<br></br>
										<p className="highlight-yellow width-tight normal-text" style={{padding:"8px", borderRadius:"7px"}}>Use Google Drive or Dropbox folder sharing if you have more than 3 documents</p>
										<br></br>
										<span className="normal-text-grey2" style={{padding:"5px", borderRadius:"7px", backgroundColor: "#9D3848", color:"#fff", fontSize: "14px"}}>Remove all file sharing and editing restrictions please.</span>
										{documentsLink}

										<div className="row">
											<input className="input-field-new" placeholder="https://docs.google.com/" value={this.state.documentsLink} onChange={this.handleChange} type="text" name="documentsLink" />
											<button className="client-tax-dashboard-nav-link" onClick={() => this.updateSheetsLink()}>Add Link</button>
										</div>
										<p className="emailBadResponse">{this.state.errorDocumentLink}</p>



										<div className="margin-bottom-30">
											<p className="admin-file-manager-label">Notes</p>
											{notes}
										</div>

										<div>
											<label>
												<div className="spacebetween">
													<p className="admin-file-manager-label">Type us your notes here: NOT A MESSAGING TOOL</p>
													<p className="sideText">Optional</p>
												</div>
												<textarea type="text" value={this.state.newNotes} name="newNotes" onChange={this.handleChange} />
											</label>
											<button className="client-tax-dashboard-nav-link" onClick={() => this.updateNotes()}>Add Notes</button>
											<p className="emailBadResponse">{this.state.errorNewNotes}</p>
										</div>




											<br></br>
											<br></br>

												<div>
													<label>
														<div className="spacebetween">
															<p className="admin-file-manager-label"> Full Name</p>
															<p className="sideText">Optional</p>
														</div>
														<input type="text" value={this.state.name} name="name" onChange={this.handleChange} placeholder={(object.name == null) ? "John Doe" : object.spouseFullName} />
													</label>
													<button className="client-tax-dashboard-nav-link" onClick={() => this.updateFullName()}>Update Full Name</button>
													<p className="emailResponse">{this.state.successNameChange}</p>
													<p className="emailBadResponse">{this.state.errorSpouseName}</p>
												</div>
												<br></br>
												<br></br>

										<div>
											<label>
												<div className="spacebetween">
													<p className="admin-file-manager-label">Spouse Name</p>
													<p className="sideText">Optional</p>
												</div>
												<input type="text" value={this.state.spouseName} name="spouseName" onChange={this.handleChange} placeholder={(object.spouseFullName == null) ? "Jane Doe" : object.spouseFullName} />
											</label>
											<button className="client-tax-dashboard-nav-link" onClick={() => this.updateSpouseName()}>Update Spouse Name</button>
											<p className="emailBadResponse">{this.state.errorSpouseName}</p>
										</div>


									</div>

								</div>


							</div>




						</div>



					)
				}

				if (this.state.step === "Document Checklist") {
					return (

						<div className="client-tax-dashboard-section1 padding-bottom-lg">

							<div className="client-nav">

								<a className="client-nav-logo" href="/">
									<img className="logo2" src={logo} alt="img" />
								</a>

								<div className="client-nav-detail">
									<a href="/Client/PriceTool" className="margin-right client-button-filled" >New Order</a>

									<div className="col">
										<p className="admin-nav-name">{this.props.auth.user.name}</p>
										<p className="admin-nav-role">{this.props.auth.user.role}</p>
									</div>
									<button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>
								</div>


							</div>


							<div className="client-tax-dashboard-row-1">
								{/*Name etc*/}
								<div className="">


									<div className="col">
										<p className="client-tax-dashboard-name">{object.name}</p>
										<p className="client-tax-dashboard-type">Client</p>
										{archiveStatus}

									</div>
								</div>

								{/*QuickView*/}
								<div className="quickview-client">
									<div className="quickview-1">
										<p className="quickview-text">Service: <span className="bold2">{object.service}</span></p>
										{(object.spouseFullName != null) ? <p className="quickview-text">Spouse Full Name: <span className="bold2">{object.spouseFullName}</span></p> : <p className="quickview-text">Spouse Full Name: <span className="bold2">N/A</span></p>}
									</div>


								</div>

							</div>

							{/*Nav*/}
							<div className="client-tax-dashboard-nav-div-1">
								<a href="/Client/Dashboard" className="client-tax-dashboard-nav-link">Dashboard</a>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Activity")}>Activity</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Communications")}>Communications</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("File Manager")}>File Manager</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Document Checklist")}>Document Checklist</button>

							</div>

							{/*Documents Checklist*/}
							<div>
							{documentChecklistBody}
							</div>

						</div>


					)
				}



			} else {
				console.log("notttttt");
			}
			return null;
		});



		console.log(this.state);
		return (

			<div className="client-tax-dashboard-section1">



				<div className="background-color-white">
					{theOne}

				</div>








				{/* <FileManager documentsLink={this.props.documentsLink} handleFiles={this.handleFiles} /> */}






			</div>


		);

	}






}



ClientTaxReturn.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	taxReturns: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	taxReturns: state.taxReturns,

});
export default connect(
	mapStateToProps,
	{ logoutUser, getTaxReturnsByEmail, updateTaxReturnAcceptOffer, updateTaxReturnFiles, updateSheetsLink }
)(ClientTaxReturn);
