import React from 'react'
import PropTypes from 'prop-types'

import { connect } from "react-redux";
import { getTaxReturnsById, updateTaxReturnAmount, updateTaxReturnisSeniorStart, updateTaxReturnisInternStart, updateTaxReturnisBasilStart, updateTaxReturnisBasilApproveEFile, updateTaxReturnisEFileAuthSent, updateTaxReturnisEFileAuthSigned, updateTaxReturnisEFileAuthAccepted, updateTaxReturnisTaxReturnUploadedByTeamMember, updateTaxReturnFiles, updateTaxReturnFilePDF } from '../actions/taxReturnActions';
import { getTaxReturns } from '../actions/taxReturnActions';
//import store from "../store";
//import { Redirect } from "react-router";

import { logoutUser } from "../actions/authActions";


import axios from "axios";

//import AdminWorkflowOne from "../components/AdminWorkflow/One";
//import AdminWorkflowTwo from "../components/AdminWorkflow/Two";
//import AdminWorkflowThree from "../components/AdminWorkflow/Three";
//import AdminWorkflowFour from "../components/AdminWorkflow/Four";
//import AdminWorkflowFive from "../components/AdminWorkflow/Five";
//import AdminWorkflowSix from "../components/AdminWorkflow/Six";
//import AdminWorkflowSeven from "../components/AdminWorkflow/Seven";
//import AdminWorkflowEight from "../components/AdminWorkflow/Eight";
//import AdminWorkflowNine from "../components/AdminWorkflow/Nine";
//import AdminWorkflowTen from "../components/AdminWorkflow/Ten";
//import AdminWorkflowEleven from "../components/AdminWorkflow/Eleven";
//import AdminWorkflowTwelve from "../components/AdminWorkflow/Twelve";
//import AdminWorkflowThirteen from "../components/AdminWorkflow/Thirteen";
import GMail from '../components/GMailView';

//import DashboardInfo from "./DashboardInfo";


//import Tabs from 'react-bootstrap/Tabs'
//import Tab from 'react-bootstrap/Tab'
//import FileManager from "../components/FileManager";
import logo from '../Images/logo.png';


//import profileimg from '../Images/profileimg.png';

import FileBase64 from 'react-file-base64';
import Loader from '../components/Loader';
//import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';


//import pdficon from '../Images/pdf-icon.png'; // Tell Webpack this JS file uses this image
//import excelicon from '../Images/excel-icon.png'; // Tell Webpack this JS file uses this image
//import docicon from '../Images/document-icon.png'; // Tell Webpack this JS file uses this image
//import imageicon from '../Images/image-icon.png'; // Tell Webpack this JS file uses this image
//import checkcircle from '../Images/check-circle.png'; // Tell Webpack this JS file uses this image


class AdminTaxReturn extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			step: "Activity",
			taxReturn: {},
			emailSubject: "",
			emailBody: "",
			emailResponse: "",
			emailBadResponse: "",
			name: this.props.auth.user.name,
			email: this.props.auth.user.email,
			amount: "",
			amountValue: "",
			amountButton: "admin-button",
			amountResponse: "",
			taxReturnFile: "",
			hi: "",
			files: [],
			errors: {},
			rerender: false,
			fileDeleteStatus:"",
			documentsLink:"",
			errorDocumentLink:"",
			successDocumentLink:"",
			archiveTaxYear:2020,
			userList: [],
			loading: false
		}
	}

	componentWillReceiveProps(nextProps) {

		if (nextProps) {
			//		const { id } = this.props.match.params;

			this.handleStep("Activity");


			// this.props.getTaxReturns();
			// let theOne;
			// let { taxReturns } = this.props.taxReturns;
			//
			// theOne = taxReturns.find(element => element._id == id);
			// console.log(theOne);
		}



		// if (nextProps.errors) {
		//   this.setState({
		//     errors: nextProps.errors
		//   });
		// }

	}

	static propTypes = {
		getTaxReturns: PropTypes.func.isRequired,
		updateTaxReturnisSeniorStart: PropTypes.func.isRequired,
		updateTaxReturnisInternStart: PropTypes.func.isRequired,
		updateTaxReturnisBasilStart: PropTypes.func.isRequired,
		updateTaxReturnisBasilApproveEFile: PropTypes.func.isRequired,
		updateTaxReturnisEFileAuthSent: PropTypes.func.isRequired,
		updateTaxReturnisEFileAuthSigned: PropTypes.func.isRequired,
		updateTaxReturnisEFileAuthAccepted: PropTypes.func.isRequired,
		updateTaxReturnisTaxReturnUploadedByTeamMember: PropTypes.func.isRequired,
		updateTaxReturnFiles: PropTypes.func.isRequired,
		updateTaxReturnFilePDF: PropTypes.func.isRequired,
		logoutUser: PropTypes.func.isRequired
	};


	async componentDidMount () {
		this.setState({
			loading: true,
			})
		if (!this.props.auth.isAuthenticated) {
			window.location.href = "/";
		}

		if (this.props.auth.user.role === "Client") {
			window.location.href = "/Client/Dashboard";
		}

		await axios.get("/api/users/getAllCPA")
		.then(res => {
			this.setState({
			userList: res.data
			})
		})
		.catch(err => console.log(err));


		const { id } = this.props.match.params;
		//console.log(id);

		await this.props.getTaxReturns();
		let theOne;
		let { taxReturns } = this.props.taxReturns;

		theOne = taxReturns.find(element => element._id === id);
		console.log(theOne);
		this.setState({
			loading: false,
		})
	}


	updateSheetsLink = () => {

		var pattern = /^((http|https):\/\/)/;

		if (!pattern.test(this.state.documentsLink)) {
			this.setState({
				errorDocumentLink: "Please enter a valid url starting with http or https"
			})
		} else {

			const { id } = this.props.match.params;
			//this.props.updateSheetsLink({link:this.state.documentsLink, id:id})

			axios
				.put('/api/taxReturns/updateAdminSheetsLink', { link: this.state.documentsLink, id: id })
				.then(res => {
					this.props.getTaxReturns()

					this.setState({
						documentsLink: "",
						errorDocumentLink: "",
						successDocumentLink: ""


					})

					//console.log("Sheets link added to DB")
				}
				)
				.catch(err =>
					console.log(err)
				);

		}

	}




	archiveFile = (values) => {
		console.log(values);
		const { id } = this.props.match.params;
		axios
			.put('/api/taxReturns/archiveTaxReturn', {year: this.state.archiveTaxYear, id:id})
			.then(res => {
				console.log(res)
				this.props.getTaxReturns();
			})
			.catch(err => {
				console.log(err)

				this.setState({
					archiveResponse: ""
				})
			})
	}

	removeFromArchive = (values) => {
		console.log(values);
		const { id } = this.props.match.params;
		axios
			.put('/api/taxReturns/removeFromArchive', {id:id})
			.then(res => {
				console.log(res)
				this.props.getTaxReturns();
			})
			.catch(err => {
				console.log(err)

				this.setState({
					archiveResponse: ""
				})
			})
	}


	refresh = () => {
		const { id } = this.props.match.params;
		console.log(id);

		this.props.getTaxReturns();
		let theOne;
		let { taxReturns } = this.props.taxReturns;

		theOne = taxReturns.find(element => element._id === id);
		console.log(theOne);

		this.setState({ state: this.state })
	}




	sendMessage = (value) => {
		//console.log("in send a message");

		let email = {
			name: this.state.name,
			email: value,
			message: this.state.emailBody,
			from: "clientinfo@agroaccounting.com"

		}

		axios
			.post('/api/taxReturns/emailToClient', email)
			.then(res => {
				console.log(res)
				//this.props.history.push("/EmailSuccesful")

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailBadResponse: "",
					emailResponse: "Email Sent!"
				});
			}
			)
			.catch(err => {
				console.log(err)

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailResponse: "",
					emailBadResponse: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
				});
			}

			);

		//this.props.email(email);


	}


	openLink = (link) => {
		window.location.href = link;
	}




	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}


	handleStep = (value) => {
		this.setState((state, props) => {
			return {
				step: value
			};
		});
	}

	handleFiles = (items) => {
		console.log("reached handlefiles in client")

		console.log(items);

		// this.setState((state, props) => {
		// 	return {
		// 		files: items
		// 	};
		// });

		//this.updateTaxReturnFiles()

		const { id } = this.props.match.params;
		//this.props.updateTaxReturnFiles({files:this.state.files, id:id});

		axios
		.put('/api/taxReturns/updateTaxReturnFiles', { files: items, id: id })
		.then(res =>
			this.props.getTaxReturns()
		)
		.catch(err =>
			this.props.getTaxReturns(),
			console.log("An Error has occurred")
		);


	}



	// Deletes Regular Files uploaded by user
	deleteFile = (input) => {
		console.log(input);
		axios
			.post('/api/taxReturns/deleteTaxReturnFile', { file: input.link, id: input.id })
			.then(res => {
				console.log(res)
				this.props.getTaxReturns()
			}
			)
			.catch(err => {
				console.log("An Error has occurred")

				this.setState((state, props) => {
					return {
						fileDeleteStatus: "File delete"
					};
				});
					}
			);
	}

	// Deletes Finalized Tax Returns
	deleteTaxReturnFile = (input) => {
		console.log(input);
		axios
			.post('/api/taxReturns/deleteAdminTaxReturnFile', { file: input.link, id: input.id })
			.then(res => {
				console.log(res)
				this.props.getTaxReturns()
			}
			)
			.catch(err => {
				console.log("An Error has occurred")

				this.setState((state, props) => {
					return {
						fileDeleteStatus: "File delete"
					};
				});
					}
			);

	}



	handleFilesTaxReturn = (item) => {

		console.log(item);
		const { id } = this.props.match.params;


		this.setState((state, props) => {
			return {
				taxReturnFile: item,
			};
		});


		//this.props.updateTaxReturnFilePDF({ taxReturnFile: this.state.taxReturnFile, id: id });

		axios
			.put('/api/taxReturns/updateTaxReturnFilePDF', { taxReturnFile: this.state.taxReturnFile, id: id })
			.then(res =>
				this.props.getTaxReturns()

			)
			.catch(err =>
				this.props.getTaxReturns()
			);

	}

	forceUpdateHandler = () => {
		this.forceUpdate();
	};

	onLogoutClick = () => {
		// e.preventDefault();
		this.props.logoutUser();
		window.location.href = "/";
	};

	updateAmount = () => {
		const { id } = this.props.match.params;

		//this.props.updateTaxReturnAmount({amount:this.state.amount, id:id});

		axios
			.put('/api/taxReturns/updateTaxReturnAmount', { amount: this.state.amount, id: id })
			.then(res =>
				this.props.getTaxReturns(),

				//this.refresh()

				this.setState((state, props) => {
					return {
						step: "Activity",
						amount: "",
						amountButton: "admin-button",
						amountResponse: "Price Quote Sent!"
					};
				})

				//this.forceUpdate()

				//this.setState({ state: this.state })


			)
			.catch(err =>
				this.props.getTaxReturns()

			);


	}

	updateInternStart = (value) => {
		//this.props.updateTaxReturnisInternStart(value);
		const { id } = this.props.match.params;

		axios
			.put('/api/taxReturns/updateTaxReturnisInternStart', value)
			.then(res =>
				this.props.getTaxReturns(),
				window.location.href = `/AdminTaxReturn/${id}`
			)
			.catch(err =>
				this.props.getTaxReturns()
			);


	}

	updateSeniorStart = (value) => {
		//this.props.updateTaxReturnisSeniorStart(value);

		const { id } = this.props.match.params;

		axios
			.put('/api/taxReturns/updateTaxReturnisSeniorStart', value)
			.then(res =>
				this.props.getTaxReturns(),
				window.location.href = `/AdminTaxReturn/${id}`
			)
			.catch(err =>
				this.props.getTaxReturns()
			);

	}

	updateBasilStart = (value) => {
		//this.props.updateTaxReturnisBasilStart(value);

		const { id } = this.props.match.params;

		axios
			.put('/api/taxReturns/updateTaxReturnisBasilStart', value)
			.then(res =>
				this.props.getTaxReturns(),
				window.location.href = `/AdminTaxReturn/${id}`
			)
			.catch(err =>
				this.props.getTaxReturns()
			);


	}


	updateBasilApproveEFile = (value) => {
		//this.props.updateTaxReturnisBasilApproveEFile(value);
		const { id } = this.props.match.params;

		axios
			.put('/api/taxReturns/updateTaxReturnisBasilApproveEFile', value)
			.then(res =>
				this.props.getTaxReturns(),
				window.location.href = `/AdminTaxReturn/${id}`
			)
			.catch(err =>
				this.props.getTaxReturns()
			);


	}

	updateEFileAuthSent = (value) => {
		//this.props.updateTaxReturnisEFileAuthSent(value);
		const { id } = this.props.match.params;

		axios
			.put('/api/taxReturns/updateTaxReturnisEFileAuthSent', value)
			.then(res =>
				this.props.getTaxReturns(),
				window.location.href = `/AdminTaxReturn/${id}`
			)
			.catch(err =>
				this.props.getTaxReturns()
			);


	}

	updateEFileAuthSigned = (value) => {
		//  this.props.updateTaxReturnisEFileAuthSigned(value);
		const { id } = this.props.match.params;

		axios
			.put('/api/taxReturns/updateTaxReturnisEFileAuthSigned', value)
			.then(res =>
				this.props.getTaxReturns(),
				window.location.href = `/AdminTaxReturn/${id}`
			)
			.catch(err =>
				this.props.getTaxReturns()
			);



	}

	updateEFileAuthAccepted = (value) => {
		//this.props.updateTaxReturnisEFileAuthAccepted(value);
		const { id } = this.props.match.params;

		axios
			.put('/api/taxReturns/updateTaxReturnisEFileAuthAccepted', value)
			.then(res =>
				this.props.getTaxReturns(),
				window.location.href = `/AdminTaxReturn/${id}`
			)
			.catch(err =>
				this.props.getTaxReturns()
			);



	}

	updateTaxReturnUploaded = (value) => {
		//this.props.updateTaxReturnisTaxReturnUploadedByTeamMember(value);
		const { id } = this.props.match.params;

		axios
			.put('/api/taxReturns/updateTaxReturnisTaxReturnUploadedByTeamMember', { id: id })
			.then(res =>
				this.props.getTaxReturns()
			)
			.catch(err =>
				this.props.getTaxReturns(),
				console.log("An Error has occurred")
			);
	}

	updateTaxReturnFiles = () => {



	}


	if(nextProps) {
		this.forceUpdate();

		this.setState((state, props) => {
			return {
				rerender: " "
			};
		});
	}


	updateIsAmountPayedByClient = (value) => {
		//this.props.updateTaxReturnisEFileAuthAccepted(value);
		const { id } = this.props.match.params;
		this.setState({
			loading: true,
			})
		axios
			.put('/api/taxReturns/updateTaxReturnIsAmountPayedByClient', value)
			.then(res => {
				this.props.getTaxReturns();
				window.location.href = `/AdminTaxReturn/${id}`
			}
			)
			.catch(err =>{
				console.log("Success", err);
				return;
				this.props.getTaxReturns()
	});
	}

	updateLocation = (value) => {
		axios
			.put('/api/taxReturns/updateTaxReturnLocation', value)
			.then(res =>
				this.props.getTaxReturns()
			)
			.catch(err =>
				this.props.getTaxReturns()
			);
	}

	updateLocatioAvailable = (value) => {
		axios
			.put('/api/taxReturns/updateTaxReturnLocationAvailable', value)
			.then(res =>
				this.props.getTaxReturns()
			)
			.catch(err =>
				this.props.getTaxReturns()
			);
	}

	handleSelectChange = (event,id) => {
		const selectedOption = event.target.value;
		this.updateLocation({id:id, location:selectedOption})
	};

	updateCPAUser = (value) => {
		axios
			.put('/api/taxReturns/updateCPAUser', value)
			.then(res =>
				this.props.getTaxReturns()
			)
			.catch(err =>
				this.props.getTaxReturns()
			);
	}

	handleCPAChange = (event,id) => {
		const selectedOption = event.target.value;
		this.updateCPAUser({id, userId: selectedOption})
	};


	decodeAndRemoveHyphens = (encodedString) => {
		// Decode URL-encoded characters
		const decodedString = decodeURIComponent(encodedString);
		// Remove hyphens from the decoded string
		const cleanedString = decodedString.replace(/[-+]/g, ' ');
		
	
		return cleanedString;
	}





	render() {
		const { id } = this.props.match.params;
		//     let link = `/AdminTaxReturn/${id}`;
		//
		//     if (this.state.rerender === true) {
		//       return <Redirect to={link} />
		// }



		//		const { errors } = this.state;
		console.log(this.state);
		//console.log(this.props.auth.user.id);
		let { auth } = this.props;
		//console.log(auth);

		let { taxReturns } = this.props.taxReturns;

		const cities = [
			{ name: 'Brooklyn', url: 'https://g.page/r/CWxuXdBusEOlEBM/review' },
			{ name: 'Philadelphia', url: 'https://g.page/r/CR4EvEh_RvZAEBM/review' },
			{ name: 'San Francisco', url: 'https://g.page/r/CS00l55x_54DEBM/review' },
			{ name: 'Portland', url: 'https://g.page/r/CfzDfpakWiHREBM/review' },
			{ name: 'San Diego', url: 'https://g.page/r/CWN_B3z_MnAlEBM/review' },
			{ name: 'Austin', url: 'https://g.page/r/CW04OeOy73X3EBM/review' },
			{ name: 'Los Angeles', url: 'https://g.page/r/Cc5iRPVWcPllEBM/review' },
			{ name: 'Chicago', url: 'https://g.page/r/Cd7Srw6k9bZYEBM/review' },
			{ name: 'Miami', url: 'https://g.page/r/CQGHMvFmHucEEBM/review' },
			{ name: 'Denver', url: 'https://g.page/r/CUcl50gzFFZIEBM/review'}
		  ];
		  
		  // Sort the cities alphabetically by name
		  cities.sort((a, b) => a.name.localeCompare(b.name));



		//console.log(taxReturns);

		//		let activityScreen;
		//		let images = [];
		let links;
		let links1;
		let links2;

		let taxReturnLinks = [];
		let taxReturnLinks1 = [];
		let taxReturnLinks2 = [];
		let taxReturnLinksArray = [];
		let taxReturnLinksString = [];
		let taxReturnLinksObject = [];

		let filesArray = [];
		let filesObject =[];

		//		let taxReturnLink;
		let documentsLink;
		let adminDocumentsLink;
		let archiveStatus;

		let theOne = taxReturns.map((object, index) => {

		if(object.archive == true){
			archiveStatus = <div className="row">
			<p className="archive-status">Archived</p>
			<button className="archive-file-link" onClick={() => this.removeFromArchive({id:object._id})}>Remove From Archive</button>
			</div>
		}else if (this.props.auth.user.role === "Basil") {
			archiveStatus = <div>
					 <label>
						 <div className="spacebetween">
							 <p className="admin-file-manager-label">Archive Tax Return</p>
						 </div>
						 <input placeholder="Tax Year" type="number" name="archiveTaxYear" onChange={this.handleChange} className="" value={this.state.archiveTaxYear}/>
					 </label>
					 <button className="archive-file-link" onClick={() => this.archiveFile({id:object._id})}>Archive This Tax Return</button>
					 <p className="emailResponse"></p>
					 <p className="emailBadResponse"></p>
				 </div>
		} else{
			archiveStatus = <div>
								<p className="emailResponse"></p>
							</div>
		}



			if (object._id === id) {
				if (object.files !== undefined) {

					//Check for files of type arrays
					filesArray = object.files.filter(item => Array.isArray(item))
					console.log(filesArray)

					links1 = filesArray.map((object, index) => (
						<div>
							<a href={object[1]}>{object[0]}</a> <br></br><br></br>
						{/*<button  onClick={() => this.deleteFile({link:object.link, id:id}) }>Delete</button>*/}
						</div>
					))

					//Check for files of type Objects

					filesObject = object.files.filter(item => Array.isArray(item) != true)
					console.log(filesObject.length)

					links2 = filesObject.map((object, index) => (
						<div>
							<a href={object.link}>{object.name}</a> <br></br><br></br>
						{/*<button  onClick={() => this.deleteFile({link:object.link, id:id}) }>Delete</button>*/}
						</div>
					))




				}

				if (object.taxReturnFile !== undefined){

					//Since the save as array was made in the beginning, this is taking the assumption. That only the first 2 values [0],[1] are the array strucute and the rest are objects
					if(typeof(object.taxReturnFile[0]) == "string"){
						console.log("taxReturnFile are in an array - old")
						console.log("object",object.taxReturnFile);
						taxReturnLinks1 = 	<div className="row">
								<a href={object.taxReturnFile[1]}>{this.decodeAndRemoveHyphens(object.taxReturnFile[0])}</a>
								<button className="delete-button" onClick={() => this.deleteTaxReturnFile({link:object.taxReturnFile[1], id:id}) }>Delete</button>
							</div>

					}

					//Check for objects
					 if (typeof(object.taxReturnFile[0]) == "object" || typeof(object.taxReturnFile[1]) == "object" || typeof(object.taxReturnFile[2]) == "object") {
						console.log("taxReturnFile are in an object - new")

						let list = object.taxReturnFile.filter(item => typeof item == 'object')
						console.log(list.length);

							taxReturnLinks2 = list.map((object, index) => (
								<div className="row">
									<a href={object.link} download>{this.decodeAndRemoveHyphens(object.name)}</a>
									<button className="delete-button" onClick={() => this.deleteTaxReturnFile({link:object.link, id:id}) }>Delete</button>
								</div>
							))
				}

				}

				if (object.documentsLink !== undefined) {
					documentsLink = object.documentsLink.map((object, index) => (
						<div>
							<a className="sheets-label2" href={object}>{object}</a> <br></br><br></br>
						</div>
					))
				}

				if (object.adminDocumentsLink.length > 0) {
					adminDocumentsLink = object.adminDocumentsLink.map((object, index) => (
						<div>
							<a className="sheets-label2" href={object}>{object}</a> <br></br><br></br>
						</div>
					))
				}




				if (this.state.step === "Activity") {
					return (
						<div className="padding-bottom-lg" key={index}>

							<div className="admin-nav">
								<a className="client-nav-logo" href="/Admin/Dashboard">
									<img className="logo" src={logo} alt="img" />
								</a>

								<div className="admin-nav-detail">

									<div>
										<p className="admin-nav-name">{auth.user.name}</p>
										<p className="admin-nav-role">{auth.user.role}</p>
									</div>

									<button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>

								</div>
							</div>


							<div className="client-tax-dashboard-row">
								{/*Name etc*/}
								<div className="">

									<div className="col">
										<p className="client-tax-dashboard-name">{object.name}</p>
										<p className="client-tax-dashboard-type">Client</p>
											{/*Archive*/}
											{archiveStatus}
									</div>
								</div>

								{/*QuickView*/}
								<div className="quickview">
									<div className="quickview-1">
										<p className="quickview-text">Service: <span className="bold2">{object.service}</span></p>
										{(object.spouseFullName != null) ? <p className="quickview-text">Spouse Full Name: <span className="bold2">{object.spouseFullName}</span></p> : <p className="quickview-text">Spouse Full Name: <span className="bold2">N/A</span></p>}
									</div>
								</div>

							</div>

							{/*Nav*/}
							<div className="admin-tax-dashboard-nav-div">
								<a href="/Admin/Dashboard" className="client-tax-dashboard-nav-link">Dashboard</a>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Activity")}>Activity</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Communications")}>Communications</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("File Manager")}>File Manager</button>
							</div>


							<div className="admin-workflow-section">
								<p className="admin-workflow-heading">Workflow</p>
								<p className="blue-text"></p>

								{/*1*/}
								{(this.props.auth.user.role === "Basil") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">Client</p>


										<p className="admin-workflow-action">Documents Received</p>
										</div>

										<div>
											<p className={object.files.length >= 1 ? "admin-workflow-status-complete" : "admin-workflow-status-pending"}>{object.files.length >= 1 ? "Complete" : "Pending"}</p>
										</div>
									</div>
									:
									<p classname="none"></p>
								}




								{/*2*/}
								{(this.props.auth.user.role === "Basil") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">Admin</p>



										<div className="admin-workflow-input">
											<p className="margin-bottom-30 admin-workflow-action">Send Price Quote</p>
											<input placeholder={object.amount} onChange={this.handleChange} value={this.state.amount} type="number" name="amount" />
										</div>
										<p className="emailResponse">{this.state.amountResponse}</p>
									</div>


										<div>
											<button className={this.state.amountButton} onClick={() => this.updateAmount({ id })} >Update Price Quote</button>
										</div>
									</div>
									:
									<p classname="none"></p>
								}


								{/*3*/}
								{(this.props.auth.user.role === "Basil") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">Client</p>


										<p className="admin-workflow-action">Client Accepts Terms</p>
										</div>

										<div>
											<p className={object.isAmountAgreedByClient === false ? "admin-workflow-status-pending" : "admin-workflow-status-complete"}>{object.isAmountAgreedByClient === false ? "Pending" : "Complete"}</p>
										</div>
									</div>
									:
									<p classname="none"></p>
								}

								{/*3.1*/}
								{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Basil" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "CPA/EA") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">Client Location</p>


											<p className="margin-bottom-30 admin-workflow-action">
												<input type="checkbox" name="singleCheckbox" style={{ opacity: 1, margin:"0px 5px", position:"relative",pointerEvents: "all" }} checked={object.isLocationAvailable} onChange={(event) => this.updateLocatioAvailable({ id ,isLocationAvailable:event.target.checked})}/>
													Location Available
											
											</p>
											
										</div>

										{object.isLocationAvailable&&<div>
										<select id="addressSelect" name="address" value={object.clientSelectedLocation?object.clientSelectedLocation:""} onChange={(event) => this.handleSelectChange(event,id)} style={{ display: "block",  marginLeft: "10px", height:"35px", fontSize:"15px"}}>
										<option value="" disabled>
											Select a city
										</option>
												{cities.map(city => (
        <option key={city.name} value={city.url}>{city.name}</option>
      ))}
											</select>
										</div>}
									</div>
									:
									<p classname="none"></p>
								}



								{/*4*/}
								{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Basil" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "CPA/EA") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">Staff Associate</p>



										<p className="margin-bottom-30 admin-workflow-action">Staff Associate Starts</p>
										</div>

										<div>
											<button className={object.isInternStart === false ? "admin-button-pending" : "admin-button-complete"} onClick={() => this.updateInternStart({ id })} >{object.isInternStart === false ? "Pending" : "Complete"}</button>
										</div>
									</div>
									:
									<p classname="none"></p>
								}



								{/*5*/}
								{(this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "Basil" || this.props.auth.user.role === "CPA/EA") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">Senior Associate</p>



										<p className="margin-bottom-30 admin-workflow-action">Senior Associate Starts</p>
									</div>

										<div>
											<button className={object.isSeniorStart === false ? "admin-button-pending" : "admin-button-complete"} onClick={() => this.updateSeniorStart({ id })} >{object.isSeniorStart === false ? "Pending" : "Complete"}</button>
										</div>
									</div>
									:
									<p classname="none"></p>
								}

								{/*6.1*/}
								{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Basil" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "CPA/EA") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">CPA/EA</p>


											<p className="margin-bottom-30 admin-workflow-action">
												
													Select CPA/EA Name
											
											</p>
											
										</div>

										<div>
										<select id="cpaName" name="name" defaultValue={object.cpaUser?._id?object.cpaUser?._id:"1" } onChange={(event) => this.handleCPAChange(event,id)} style={{ display: "block",  marginLeft: "10px", height:"35px", fontSize:"15px"}}>
												{!object.cpaUser?._id && <option value="1" disabled>Select a User</option>}
												{this.state.userList.map((user) => { return <option value={user._id}>{user.name}</option> })}		
											</select>
										</div>
									</div>
									:
									<p classname="none"></p>
								}
								{object.cpaUser && <>
								{/*6.2*/}
								{(this.props.auth.user.role === "Basil" || this.props.auth.user.role === "CPA/EA") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">CPA/EA</p>

										<p className="margin-bottom-30 admin-workflow-action">CPA/EA Starts</p>
										</div>



										<div>
											<button className={object.isBasilStart === false ? "admin-button-pending" : "admin-button-complete"} onClick={() => this.updateBasilStart({ id, cpaName: this.props.auth.user.name })} >{object.isBasilStart === false ? "Pending" : "Complete"}</button>
										</div>
									</div>
									:
									<p classname="none"></p>
								}



								{/*7*/}

								{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "Basil" || this.props.auth.user.role === "CPA/EA") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">CPA/EA</p>

										<p className="margin-bottom-30 admin-workflow-action">CPA/EA Approves E-File</p>
										</div>



										<div>
											<button className={object.isBasilApproveEFile === false ? "admin-button-pending" : "admin-button-complete"} onClick={() => this.updateBasilApproveEFile({ id })} >{object.isBasilApproveEFile === false ? "Pending" : "Complete"}</button>
										</div>
									</div>
									:
									<p classname="none"></p>
								}




								{/*9*/}
								{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Basil" || this.props.auth.user.role === "CPA/EA") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">Staff Associate</p>

											<p className="margin-bottom-30 admin-workflow-action">E-File Auth Prepared</p>
										</div>


										<div>
											<button className={object.isEFileAuthSent === false ? "admin-button-pending" : "admin-button-complete"} onClick={() => this.updateEFileAuthSent({ id })} >{object.isEFileAuthSent === false ? "Pending" : "Complete"}</button>
										</div>
									</div>
									:
									<p classname="none"></p>
								}



								{/*10*/}
								{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "Basil" || this.props.auth.user.role === "CPA/EA") ?
									<div className="admin-workflow-div-0">
										<div className="row-1">
											<p className="admin-workflow-role">Senior Associate</p>
										<div className="row-2">
											<p className="margin-bottom-30 admin-workflow-action">E-File Auth Signed</p>
										</div>
										</div>


										<div>
											<button className={object.isEFileAuthSigned === false ? "admin-button-pending" : "admin-button-complete"} onClick={() => this.updateEFileAuthSigned({ id })} >{object.isEFileAuthSigned === false ? "Pending" : "Complete"}</button>
										</div>
									</div>
									:
									<p classname="none"></p>
								}



								{/*10*/}
								{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Basil" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "CPA/EA") ?
									<div className="admin-workflow-div-0">

										<div className="row-1">
											<p className="admin-workflow-role">CPA/EA</p>

										<div className="row-2">
											<p className="margin-bottom-30 admin-workflow-action">E-File Accepted</p>
										</div>
										</div>


										<div>
											<button className={object.isEFileAuthAccepted === false ? "admin-button-pending" : "admin-button-complete"} onClick={() => this.updateEFileAuthAccepted({ id })} >{object.isEFileAuthAccepted === false ? "Pending" : "Complete"}</button>
										</div>
									</div>
									:
									<p classname="none"></p>
								}


								{/*11*/}
								{(this.props.auth.user.role === "Basil" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "Staff Associate") ?
									<div className="admin-workflow-div-0">

										<div className="row-1">
											<p className="admin-workflow-role">Client</p>
										<p className="admin-workflow-action">Client Pays Invoice</p>
										</div>


										<div>
										<button className={object.isAmountPayedByClient === false ? "admin-button-pending" : "admin-button-complete"} onClick={() => this.updateIsAmountPayedByClient({ id })} >{object.isAmountPayedByClient === false ? "Pending" : "Complete"}</button>
										</div>
									</div>
									:
									<p classname="none"></p>
								}


								{/*12*/}
								{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Basil") ?
									<div className="admin-workflow-div-0">

										<div className="row-1">
											<p className="admin-workflow-role">Staff Associate</p>
										<p className="margin-bottom-30 admin-workflow-action">Tax Return Uploaded</p>
										</div>


										<div>
											<p className={object.isTaxReturnUploadedByTeamMember === false ? "admin-workflow-status-pending" : "admin-workflow-status-complete"} onClick={() => this.updateTaxReturnUploaded({ id })} >{object.isTaxReturnUploadedByTeamMember === false ? "Pending" : "Complete"}</p>
										</div>
									</div>
									:
									<p classname="none"></p>
								}
								</>}

								{/*13*/}
								{/* {(this.props.auth.user.role === "Basil") ?
									<div className="admin-workflow-div-0">

										<div className="row-1">
											<p className="admin-workflow-role">Client</p>
										<p className="admin-workflow-action">Tax Return Downloaded</p>
										</div>


										<div>
											<p className={object.isTaxReturnDownloadedByClient === false ? "admin-workflow-status-pending" : "admin-workflow-status-complete"}>{object.isTaxReturnDownloadedByClient === false ? "Pending" : "Complete"}</p>
										</div>
									</div>
									:
									<p classname="none"></p>
								} */}
							</div>
						</div>
					)
				} // End of IF ACTIVITY


				if (this.state.step === "Communications") {
					return (
						<div className="padding-bottom-lg">

							<div className="admin-nav">
								<a className="client-nav-logo" href="/Admin/Dashboard">
									<img className="logo" src={logo} alt="img" />
								</a>

								<div className="admin-nav-detail">

									<div>
										<p className="admin-nav-name">{auth.user.name}</p>
										<p className="admin-nav-role">{auth.user.role}</p>
									</div>

									<button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>

								</div>
							</div>


							<div className="client-tax-dashboard-row">
								{/*Name etc*/}
								<div className="">

									<div className="col">
										<p className="client-tax-dashboard-name">{object.name}</p>
										<p className="client-tax-dashboard-type">Client</p>
											{/*Archive*/}
											{archiveStatus}
									</div>
								</div>

								{/*QuickView*/}
								<div className="quickview">
									<div className="quickview-1">
										<p className="quickview-text">Service: <span className="bold2">{object.service}</span></p>
										{(object.spouseFullName != null) ? <p className="quickview-text">Spouse Full Name: <span className="bold2">{object.spouseFullName}</span></p> : <p className="quickview-text">Spouse Full Name: <span className="bold2">N/A</span></p>}
									</div>
								</div>

							</div>

							{/*Nav*/}
							<div className="admin-tax-dashboard-nav-div">
								<a href="/Admin/Dashboard" className="client-tax-dashboard-nav-link">Dashboard</a>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Activity")}>Activity</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Communications")}>Communications</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("File Manager")}>File Manager</button>
							</div>






							<div className="center-content-comms" style={{ paddingBottom: '20px' }}>
								<p className="admin-workflow-heading">Communications</p>

								<p className="normal-text">To: {object.email}</p>

								<div className="">
									<textarea onChange={this.handleChange} placeholder="Type Your Message..." name="emailBody"></textarea>
									<button className="contact-button" onClick={() => this.sendMessage(object.email)}>Submit</button>
									<p className="emailResponse">{this.state.emailResponse}</p>
									<p className="emailBadResponse">{this.state.emailBadResponse}</p>
								</div>
							</div>
							{<GMail fromEmail={object.email} />}


						</div>
					)
				}// End of OF Communications

				if (this.state.step === "File Manager") {

					return (

						<div className="padding-bottom-lg">

							<div className="admin-nav">
								<a className="client-nav-logo" href="/Admin/Dashboard">
									<img className="logo" src={logo} alt="img" />
								</a>

								<div className="admin-nav-detail">

									<div>
										<p className="admin-nav-name">{auth.user.name}</p>
										<p className="admin-nav-role">{auth.user.role}</p>
									</div>

									<button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>

								</div>
							</div>


							<div className="client-tax-dashboard-row">
								{/*Name etc*/}
								<div className="">

									<div className="col">
										<p className="client-tax-dashboard-name">{object.name}</p>
										<p className="client-tax-dashboard-type">Client</p>
											{/*Archive*/}
											{archiveStatus}
									</div>
								</div>

								{/*QuickView*/}
								<div className="quickview">
									<div className="quickview-1">
										<p className="quickview-text">Service: <span className="bold2">{object.service}</span></p>
										{(object.spouseFullName != null) ? <p className="quickview-text">Spouse Full Name: <span className="bold2">{object.spouseFullName}</span></p> : <p className="quickview-text">Spouse Full Name: <span className="bold2">N/A</span></p>}
									</div>
								</div>

							</div>

							{/*Nav*/}
							<div className="admin-tax-dashboard-nav-div">
								<a href="/Admin/Dashboard" className="client-tax-dashboard-nav-link">Dashboard</a>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Activity")}>Activity</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("Communications")}>Communications</button>
								<button className="client-tax-dashboard-nav-link" onClick={() => this.handleStep("File Manager")}>File Manager</button>
							</div>

							<div className="center-content-comms">
								<p className="admin-workflow-heading">File Manager</p>
								<div className="admin-file-manager">

									<div className="admin-file-manager-label-div">
										<p className="admin-file-manager-label">Documents</p>

										{/*
										<FileBase64
											multiple={false}
											onDone={this.handleFiles} />
										*/}

										<div className="admin-files-div">




												{links1}
												{links2}





										</div>
										<br></br>
										<div className="admin-file-manager-label-div">
											<p className="admin-file-manager-label">Upload Finalized Tax Return</p>
											<FileBase64
												multiple={false}
												onDone={this.handleFilesTaxReturn} />
											<br></br>

										{/* <a href={object.taxReturnFile.link}>{object.taxReturnFile.name}</a> */}
											{taxReturnLinks}
											{taxReturnLinks1}
											{taxReturnLinks2}

										</div>

										{/*Upload Links*/}
										<div className="page-half">
											<br></br>
											<br></br>
											<p className="admin-file-manager-label">Google Docs or Dropbox link(s)</p>
											{/*<p className="admin-file-manager-label">(Must start with http or https, copy and paste your URL from the browser)</p>*/}
											<br></br>
											{adminDocumentsLink}

											<div className="row">
												<input className="input-field-new" placeholder="https://docs.google.com/" value={this.state.documentsLink} onChange={this.handleChange} type="text" name="documentsLink" />
												<button className="client-tax-dashboard-nav-link" onClick={() => this.updateSheetsLink()}>Add Link</button>
											</div>
											<p className="emailBadResponse">{this.state.errorDocumentLink}</p>
											<p className="emailResponse">{this.state.successDocumentLink}</p>

										</div>

									</div>



									<div className="admin-filemanager-left">
										<p className="admin-file-manager-label">Google Docs or Dropbox link(s)</p>
										{/*<a href={object.documentsLink} target="_blank" className="sheets-label2">{object.documentsLink}</a>*/}
										{documentsLink}
										<div>
											<p className="admin-file-manager-label">Notes</p>
											<p>{object.notes}</p>
										</div>
									</div>

								</div>





							</div>




						</div>



					)
				}// End of IF File Manager





			}
			return null;
		});



		return (

			<div className="background-color">
				{/*<FileManager documentsLink={this.props.documentsLink} handleFiles={this.handleFiles} />*/}
				<div className="background-color-white">
					{console.log(theOne)}
					{this.state.loading || !theOne ? <Loader /> :theOne}
				</div>
				{this.state.hi}
			</div>
		);
	}
}

AdminTaxReturn.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};


const mapStateToProps = state => ({
	taxReturns: state.taxReturns,
	auth: state.auth

});

export default connect(
	mapStateToProps,
	{
		getTaxReturnsById, getTaxReturns, updateTaxReturnAmount, updateTaxReturnisSeniorStart, updateTaxReturnisInternStart, updateTaxReturnisBasilStart, updateTaxReturnisBasilApproveEFile, updateTaxReturnisEFileAuthSent,
		updateTaxReturnisEFileAuthSigned,
		updateTaxReturnisEFileAuthAccepted,
		updateTaxReturnisTaxReturnUploadedByTeamMember,
		updateTaxReturnFiles, updateTaxReturnFilePDF, logoutUser
	}
)(AdminTaxReturn);
