import React from 'react'
import PropTypes from 'prop-types'

import MetaTags from 'react-meta-tags';
import PublicNav from "../components/PublicNav";
import Footer from "../components/Footer";

class Promotions extends React.Component {
  render () {
    return(

      <div>
      <MetaTags>
        <title>Promotions & Client Discounts - Agro Accounting CPA </title>
        <meta name="description" content="Agro Accounting CPA by Basil Agrocostea CPA is the one-stop destination for freelancers, artists and creative small businesses, for accounting service & help with tax preparation." />
      </MetaTags>
      <PublicNav />

      <div className="home-secondary-header-div">
        <p className="home-secondary-header">Promotions & Client Discounts</p>
        <div class="underline-thick"></div>
      </div>

      <div className="center-content">
      <b className="terms-heading"> PROMOTIONS AND CLIENT DISCOUNTS </b>
      <br></br>
      <p className="terms-text">
      We offer discounts for multiple client referrals.  Our policy is as follows:<br></br>
      ■ If you refer us to 3 individuals in a similar tax position as you, your tax preparation is Free;<br></br>
      ■ If you are a business and refer us to 4 businesses in a similar tax position as you, your annual accounting & tax service is Free;<br></br>
      ■ Anything in between, let's talk about a discount!  And if you have already referred us to someone, kindly remind us so.
      </p>

      <br></br>
      <br></br>
      <br></br>
      <br></br>



      </div>
      <Footer />
      </div>



    );

  }
}

export default Promotions;
