import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import axios from "axios";
import MetaTags from 'react-meta-tags';
import ScriptTag from 'react-script-tag';
import {Helmet} from "react-helmet";


import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

//import { HashLink } from 'react-router-hash-link';
import { HashLink, NavHashLink } from "react-router-hash-link";



//import { NavHashLink as NavLink } from 'react-router-hash-link';
//import { HashLink as Link } from 'react-router-hash-link';

import Footer from "../components/Footer";
import HomeSecondaryHeader from "../components/HomeSecondaryHeader";

import ContactSection from "../components/ContactSection";
import ContactSection1 from "../components/ContactSection1";
import ContactSection2 from "../components/ContactSection2";
import ContactSection3 from "../components/ContactSection3";
import ContactSection4 from "../components/ContactSection4";

import ContactSectionDefault from "../components/ContactSectionDefault";

import CityContact from "./cityContact";

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import bbb from '../Images/bbb2.png';
import one from '../Images/home-image-one.png';
import services from '../Images/services.png';
import logo from '../Images/logo.png';
import mapNY from '../Images/mapNY.png';
import mapPhilly from '../Images/mapPhilly.png';
import mapAustin from '../Images/mapAustin.png';

import mapLA from '../Images/mapLA.png';
import mapSanFran from '../Images/mapSanFran.png';
import mapSanDiego from '../Images/mapSanDiego.png';
import white from '../Images/white.png';
import mapMI from '../Images/mapMI.png';
import mapDV from '../Images/mapDV.png';

import back from '../Images/chevron-left.svg';


import featureOne from '../Images/feature-one.png';
import featureTwo from '../Images/feature-two.png';
import featureThree from '../Images/feature-three.png';

import paymentOne from '../Images/payment-1.png';
import paymentTwo from '../Images/payment-2.png';
import paymentThree from '../Images/payment-3.png';
import paymentFour from '../Images/payment-4.png';
import paymentFive from '../Images/payment-5.png';

import seal1 from '../Images/SealCompliassureConfirm3.png'
import seal2 from '../Images/authorizeseal.png'

import faqHome from '../Images/faq-home.png';




import { connect } from "react-redux";
import { email } from "../actions/taxReturnActions";



class Home extends React.Component {
	constructor(props) {

		super(props);
		this.state = {
			contactName: "",
			contactEmail: "",
			contactMessage: "",
			emailResponse: "",
			emailBadResponse: "",
			contactSection: "",

			userLat: 0,
			userLong: 0,
			locationInactive: false
		}
	}

	componentDidMount() {
		if ("geolocation" in navigator) {
			console.log("Available");
		} else {
			console.log("Not Available");
		}

		// Get the location of the user
		this.getLocation();

		// Get the closest city from the user
		//this.getClosestCity();
	}


	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	sendMessage = () => {
		//console.log("in send a message");

		let email = {
			name: this.state.contactName,
			email: this.state.contactEmail,
			message: this.state.contactMessage
		}

		axios
			.post('/api/taxReturns/email', email)
			.then(res => {
				console.log(res)
				//this.props.history.push("/EmailSuccesful")

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailBadResponse: "",
					emailResponse: "Email Sent!"
				});
			}
			)
			.catch(err => {
				console.log(err)

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailResponse: "",
					emailBadResponse: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
				});
			}

			);
		//this.props.email(email);
	}

	componentWillReceiveProps(nextProps) {

		if (nextProps) {
			this.setState({
				emailResponse: nextProps
			});
		}

	//	this.getCurrentLocation();
	//	console.log(this.state);

	}

	changeContactSection = (location) => {
		this.setState({
			contactSection: location
		});
	}


	getLocation = () => {

	navigator.geolocation.getCurrentPosition(

	position => {

		this.setState({
			userLat: position.coords.latitude,
			userLong: position.coords.longitude
		});

	//	console.log(position.coords.latitude);
	//	console.log(position.coords.longitude);
	},
	error => {
	 console.error("Error Code = " + error.code + " - " + error.message);

	 this.setState({
			locationInactive: true
		});

	});

	}

	getCurrentLocation = () => {

		navigator.geolocation.getCurrentPosition(

		position => {

			this.setState({
				userLat: position.coords.latitude,
				userLong: position.coords.longitude
			});
		},

		error => {
		 console.error("Error Code = " + error.code + " - " + error.message);
		});

	}


	render() {

		console.log(this.state);

		let Contact;
		//console.log(this.state);

		let message = <div className="contact-div-0 contact-message-height reallycoolcss">
							 <div className="center-content-cpa">
							 <Accordion preExpanded={["0"]} allowMultipleExpanded={false}>
							 <AccordionItem uuid={"0"} className="accordian">
								 <AccordionItemHeading className="accordian-heading">
									 <AccordionItemButton className="accordian-button">
								 </AccordionItemButton>
								 </AccordionItemHeading>
								 <AccordionItemPanel className="accordian-text">
									 <div className="contact-div-half">
										 <p className="accordian-heading-contact">Working… please give us just another 2 seconds max :)</p>
									 </div>
									 <br></br>
									 <br></br>
								 </AccordionItemPanel>
							 </AccordionItem>
							 </Accordion>
					</div>
					</div>


		if(this.state.userLat != 0){

			message = <p></p>

		}


		{/*		{City Contact} */}
		Contact =	<div>
				{/*		{Contact} */}
				<div className="contact-div-0">

					<HomeSecondaryHeader text="Get In Touch With Us" subText="Any questions?  Contact us today!" />

					<div className="">

							<div className="col">

							<div className="center-content-cpa">

								<br></br>
								<br></br>

				<CityContact />

				</div>
						</div>


					</div>
					</div>
					</div>


		if(this.state.locationInactive){



		//if(this.state.contactSection == "Default"){
			Contact = <ContactSectionDefault changeContactSection= {this.changeContactSection} />
		//}

		if(this.state.contactSection == "East Coast"){
			Contact = <ContactSection2
											fromEmail=""

											telNumber="tel:+19295818381"
											destination="New York City"
											number="(929) 581-8381"
											email="NYC@agroaccounting.com"
											mailto="mailto: NYC@agroaccounting.com"
											mapLink="https://goo.gl/maps/K3APmybWUWNehP1m7"
											address="42 West Street Brooklyn, NY 11222"
											map={mapNY}
											changeContactSection= {this.changeContactSection}
											calendly1="https://calendly.com/agroaccounting-east"



											telNumber2="tel:+12679964499"
											destination2="Philadelphia"
											number2="(267) 996-4499"
											email2="philly@agroaccounting.com"
											mailto2="mailto: philly@agroaccounting.com"
											mapLink2="https://goo.gl/maps/8bYzVKmpTUGFBuDh6"
											address2="800 E Girard Ave Floor 1 Philadelphia, PA 19125"
											calendly2="https://calendly.com/agroaccounting-east"
											map2={mapPhilly}

											telNumber3="tel:+13055495624"
											destination3="Miami"
											number3="(305) 549-5624"
											email3="miami@agroaccounting.com"
											mailto3="mailto: miami@agroaccounting.com"
											mapLink3=""
											address3="5231 SW 5th Terrace, Coral Gables, FL 33134, United States"
											map3={mapMI}
											calendly3="https://calendly.com/agroaccounting-east"



											changeContactSection= {this.changeContactSection}
										/>
		}

		if(this.state.contactSection == "Middle US"){
			Contact =  <ContactSection3

												telNumber="tel:+13129999750"
												destination="Chicago"
												number="(312) 999-9750 "
												email="chicago@agroaccounting.com"
												mailto="mailto: chicago@agroaccounting.com"
												mapLink=""
												address=""
												map={white}
												calendly1="https://calendly.com/agroaccounting-central"



												telNumber2="tel:+15122983276"
												destination2="Austin TX"
												number2="(512) 298-3276"
												email2="atx@agroaccounting.com"
												mailto2="mailto: atx@agroaccounting.com"
												mapLink2="https://goo.gl/maps/jPTiEvk3u7Eeohwq6"
												address2="106 East 6th Street, Suite 900-163 Austin, TX 78701"
												map2={mapAustin}
												calendly2="https://calendly.com/agroaccounting-central"


												telNumber3="tel:+16124245111"
												destination3="Twin Cities MN"
												number3="(612) 424-5111"
												email3="minnie@agroaccounting.com"
												mailto3="mailto: minnie@agroaccounting.com"
												mapLink3=""
												address3=""
												map3={white}
												calendly3="https://calendly.com/agroaccounting-central"


												telNumber4="tel:+13035296538"
												destination4="Denver"
												number4="(303) 529-6538"
												email4="denver@agroaccounting.com"
												mailto4="mailto: denver@agroaccounting.com"
												mapLink4=""
												address4="1461 Logan St Suite A1, Denver, CO 80203, United States"
												map4={mapDV}
												calendly4="https://calendly.com/agroaccounting-rockies"


												changeContactSection= {this.changeContactSection}
											/>
		}



		if(this.state.contactSection == "West Coast"){
			Contact =  <ContactSection4
												fromEmail=""

												telNumber="tel:+13233745399"
												destination="Los Angeles"
												number="(323) 374-5399"
												email="DTLA@agroaccounting.com"
												mailto="mailto: DTLA@agroaccounting.com"
												mapLink="https://goo.gl/maps/PWB1zvC1LdTEGjZr7"
												address="6600 Sunset Boulevard, Suite 300, Los Angeles CA 90028"
												map={mapLA}
												calendly1="https://calendly.com/agroaccounting-west"

												telNumber2="tel:+14152340115"
												destination2="San Francisco Bay Area"
												number2="(415) 234-0115"
												email2="SanFran@agroaccounting.com"
												mailto2="mailto: SanFran@agroaccounting.com"
												mapLink2="https://goo.gl/maps/Snakt7MzPmLEAgr76"
												address2="95 Third Street, 2nd Floor, San Francisco CA 94103"
												map2={mapSanFran}
												calendly2="https://calendly.com/agroaccounting-west"


												telNumber3="tel:+16194313102"
												destination3="San Diego"
												number3="(619) 431-3102"
												email3="SanDiego@agroaccounting.com"
												mailto3="mailto: SanDiego@agroaccounting.com"
												mapLink3="https://goo.gl/maps/7crDhwGFsEj7KE5G6"
												address3="600 B Street, Suite 300 San Diego, CA 92101"
												map3={mapSanDiego}
												calendly3="https://calendly.com/agroaccounting-west"


												telNumber4="tel:+19712666101"
												destination4="Portland"
												number4="(971) 266-6101"
												email4="pdx@agroaccounting.com"
												mailto4="mailto: pdx@agroaccounting.com"
												mapLink4=""
												address4=""
												map4={white}
												calendly4="https://calendly.com/agroaccounting-west"


												telNumber5="tel:+12065905608"
												destination5="Seattle"
												number5="(206) 590-5608"
												email5="seattle@agroaccounting.com"
												mailto5="mailto: seattle@agroaccounting.com"
												mapLink5=""
												address5=""
												map5={white}
												calendly5="https://calendly.com/agroaccounting-west"


												changeContactSection= {this.changeContactSection}
											/>
										}
	}

		return (
			<div>
				<MetaTags>
					<title>Accounting for Artists, Freelancers & Creative Companies</title>
					<meta name="description" content="Agro Accounting CPA provides quality & affordable accounting & consulting to artists, freelancers & creative companies; for tax preparation, estimated tax calculation, bookkeeping services & more." />
				</MetaTags>
				{/* Header Sections */}
				<div className="header-section">

					<div className="public-nav">
						<a href="/">
							<img className="logo" src={logo} alt="logo" />
						</a>

						<div className="row">
						  {/* 	<a sm={4} className="nav-link" href="/Contact">Contact Us</a>*/}
							<NavHashLink className="nav-link" sm={4}  exact smooth to="/Contact"> Contact Us </NavHashLink>
							<a sm={4} className="nav-link" href="/PriceTool">PriceTool</a>
							<a sm={4} className="nav-link" href="/cpa-free-guidance">CPA's Free Guidance</a>

							{/*  <NavLink to="/about" activeClassName="nav-link">About Us</NavLink>*/}
							<a sm={4} className="nav-link" href="/AboutUs">About Us</a>
							<a sm={4} className="nav-link" href="/Blog">Blog</a>

						</div>

						<div className="row">
							<form className="nav-link" name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="e77649c1-0718-4348-9456-50037b843ea4" /> <input type = "submit" value = "Make a Payment" /> </form>
							<a sm={6} className="nav-link" href="/ClientSignIn">Sign In</a>
							<a sm={6} className="nav-link" href="/ClientSignUp">Sign Up</a>
						</div>

						<div className="row">
							<a href="tel:+18882476829" className="mobile-only phone-text-3">Toll-free: (888) 247-6829</a>
						</div>

					</div>





					<Row>

						<Col className="half header-section-half">
							<p className="text-tiny">Tax Professionals</p>

							<Row>
								<p className="home-header header-section-half">
									Online, <br></br>Modern,<br></br>Everywhere
              </p>
								{/* <img className="bbb" src="https://seal-newyork.bbb.org/seals/blue-seal-250-52-whitetxt-bbb-172963.png" alt="bbb" /> */}
							</Row>

							<p className="home-discription header-section-half">
								We do Accounting for Artists, Freelancers and Creative Companies.  Whether you're in NYC, LA - or anywhere in between, given our safe and easy online platform - check out our affordable prices and free accounting + tax advice.
            </p>

							<div className="mobile-banner-row">
								<a href="/PriceTool" className="button-outlined-home">PriceTool</a>
								<a href="/cpa-free-guidance" className="button-filled-home">CPA’s Free Guidance</a>
							</div>

						</Col>

						<Col className="">
							<img className="home-img-right" src={one} alt="one" />
						</Col>

					</Row>
				</div>

				{/* Services Offered Sections */}
				<div className="mobile-section">
					<HomeSecondaryHeader text="We're open year-round.  Get help with income tax prep, payroll and sales tax, audit representation, and more" />


					<div className="center-content">
						<div className="home-services-offered-div">
							<div sm={6} className="home-services-offered">
								<Row className="mobile-col">
									<img className="home-services-offered-img" src={services} alt="services" />
									<Col>
										<p className="home-services-offered-heading">Tax Preparation</p>
										<p className="home-services-offered-details">for Individuals, Businesses & Not-for-Profits</p>
									</Col>
								</Row>
							</div>

							<div sm={6} className="home-services-offered">
								<Row className="mobile-col">
									<img className="home-services-offered-img" src={services} alt="services" />
									<Col>
										<p className="home-services-offered-heading">Accounting & Business Consulting</p>
										<p className="home-services-offered-details">on a One-Time or Recurring Basis</p>
									</Col>
								</Row>
							</div>
						</div>

						<div className="home-services-offered-div">
							<div sm={6} className="home-services-offered">
								<Row className="mobile-col">
									<img className="home-services-offered-img" src={services} alt="services" />
									<Col>
										<p className="home-services-offered-heading">Sales Tax & Payroll Tax</p>
										<p className="home-services-offered-details">with Complimentary Assessment of Your Filing Need</p>
									</Col>
								</Row>
							</div>

							<div sm={6} className="home-services-offered">
								<Row className="mobile-col">
									<img className="home-services-offered-img" src={services} alt="services" />
									<Col>
										<p className="home-services-offered-heading">Bookkeeping</p>
										<p className="home-services-offered-details">Connect with a Trusted Bookkeeper</p>
									</Col>
								</Row>
							</div>
						</div>

						<div className="home-services-offered-div">
							<div sm={6} className="home-services-offered">
								<Row className="mobile-col">
									<img className="home-services-offered-img" src={services} alt="services" />
									<Col>
										<p className="home-services-offered-heading">Company Formation & Tax ID Issuance</p>
										<p className="home-services-offered-details">LLC, C/S Corporation & Not-for-Profit</p>
									</Col>
								</Row>
							</div>

							<div sm={6} className="home-services-offered">
								<Row className="mobile-col">
									<img className="home-services-offered-img" src={services} alt="services" />
									<Col>
										<p className="home-services-offered-heading">Estimated Tax Calculation</p>
										<p className="home-services-offered-details">Complimentary for Tax Preparation Clients</p>
									</Col>
								</Row>
							</div>
						</div>

						<div className="home-services-offered-div">
							<div sm={6} className="home-services-offered">
								<Row className="mobile-col">
									<img className="home-services-offered-img" src={services} alt="services" />
									<Col>
										<p className="home-services-offered-heading">International/ Multinational Income</p>
										<p className="home-services-offered-details">Foreign Bank Account Reporting & More</p>
									</Col>
								</Row>
							</div>

							<div sm={6} className="home-services-offered">
								<Row className="mobile-col">
									<img className="home-services-offered-img" src={services} alt="services" />
									<Col>
										<p className="home-services-offered-heading">Audit Representation</p>
										<p className="home-services-offered-details">for Income Tax, Sales Tax & Worker's Compensation</p>
									</Col>
								</Row>
							</div>


						</div>

						<div className="home-services-offered-div">


							<div sm={6} className="home-services-offered">
								<Row className="mobile-col">
									<img className="home-services-offered-img" src={services} alt="services" />
									<Col>
										<p className="home-services-offered-heading">Tax Relief Options</p>
										<p className="home-services-offered-details">Installment Plan, Offer-in-Compromise & More</p>
									</Col>
								</Row>
							</div>
						</div>
					</div>


				</div>



				{/* WHY IT MATTERS Sections */}

				<div className="why-it-matters-background-img">
					<div className="why-it-matters-white">
						<p className="why-it-matters-sub-title">WHY IT MATTERS</p>
						<p className="why-it-matters-title">Creative Certified Public Accounting Firm</p>
						<p className="why-it-matters-para">
							We are headquartered in Brooklyn, NYC but serve clients{" "}
							<span class="bold">all across the US.</span> We serve both today's
							"young" and "well-established" adults; individuals that do
							business in jeans and sneakers or in a suit and tie. Above all
							else, at Agro Accounting CPA we prioritize individuality.
            </p>

						<a href="/Contact" className="green-button">Let's Talk</a>
					</div>
				</div>

				{/* Features Sections */}
				<div className="features-section">

					{/* 1 */}

					<div className="features-bottom">
						<div className="features">
							<Col>
								<p className="headers-heading">Trustworthy and Confidential: NO Commitment Until You Hire Us</p>
								<p className="headers-details">Check out our PriceTool to get a feel for our prices. In some cases, we might need to see your documents and learn more about you in order to give you a price quote.  For that, please know that our NO Commitment headline holds true.</p>
								<a href="/PriceTool" class="blue-button-short">PriceTool</a>
							</Col>
						</div>
						<img className="features-img features-img-right" src={featureOne} alt="img" />
					</div>


					{/* 2 */}
					<div className="features-bottom-2 gradient-blue">
						<img className="features-img-2 features-img-left" src={featureTwo} alt="img" />
						<div className="features">
							<Col>
								<p className="headers-heading">Secure and Encrypted Document Transfer</p>
								<p className="headers-details-2">Whether you're a returning client, hiring us for the first time or submitting information simply for a price quote, our document exchange portal fully complies with the new IRS security requirements + we comply with the time-honored ethics of the CPA profession!</p>
							</Col>
						</div>
					</div>



					{/* 3 */}
					<div className="features-bottom gradient-pink">
						<div className="features">
							<p className="headers-heading">Flexible Ways to Work with Us</p>
							<p className="headers-details">You'll receive the experience you need when hiring us.  Whether you want to carry out our engagement remotely (via Internet and phone) or prefer to meet us in person, our communication methods are open and responsive to your need.</p>
							<div className="Row">
								<img className="payment-img" src={paymentOne} alt="img" />
								<img className="payment-img" src={paymentTwo} alt="img" />
								<img className="payment-img" src={paymentThree} alt="img" />
								<img className="payment-img" src={paymentFive} alt="img" />
								<img className="payment-img" src={paymentFour} alt="img" />
							</div>
							<div className="Row">

								<img className="payment-img" src={seal1} alt="img" />
								<img className="payment-img" src={seal2} alt="img" />



							</div>

							<form className="nav-link" name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="e77649c1-0718-4348-9456-50037b843ea4" /> <input type = "submit" value = "Make a Payment" /> </form>
						</div>
						<img className="features-img features-img-right" src={featureThree} alt="img" />
					</div>


				</div>

				{/* FAQ Sections */}
				<div className="Container">

					<HomeSecondaryHeader text="Our intuitive A.I. is here to answer all of your questions, simple or complex, at YOUR schedule's convenience" />
					<div className="center-content-2">

						<div className="center-between">
							<p className="home-sub-section">Frequently Asked Questions</p>
							<a sclassName="home-sub-section-2" href="/cpa-free-guidance">Click Here to See More</a>
						</div>


						<div className="faq-blue-border">

							{/* FAQ 1 */}
							<div className="center-content-3">
								<Row>
									<img className="faq-img" src={faqHome} alt="img" />
									<div className="col">
										<p className="faq-heading">What is my business or freelance income?  How do I calculate it?</p>
										<p className="faq-details">
											Your business or freelance income, at a minimum, is the total of your bank account deposits and app (Venmo, PayPal, etc.) deposits for the tax return’s year. <br></br>

											Income is all of these deposits EXCEPT:<br></br>
											1. Financial support/ gifts from family and friends<br></br>
											2. Personal reimbursements, i.e. IOU repayment, Venmo for the dinner bill’s share<br></br>
											3. Personal refunds, i.e. for return of clothes you bought on Amazon <br></br>
											4. W-2 salaried income (because this goes elsewhere on your tax return)<br></br>
											5. Transfers between your bank or app accounts<br></br>
											6. Prior year tax refunds (because this goes elsewhere on your tax return)
              </p>
									</div>
								</Row>
							</div>

							{/* FAQ 2 */}
							<div className="center-content-3">
								<Row>
									<img className="faq-img" src={faqHome} alt="img" />
									<div className="col">
										<p className="faq-heading">What is the difference between income and a gift?</p>
										<p className="faq-details">
											A gift is unilateral, or one-way, “consideration.”  If someone gives you money and gets nothing in return from you (aside for the good feeling that comes from giving), then they have made you a gift.  For income tax purposes, gifts are not taxable.  However, if they are more than $15,000, then the grantor -the person making the gift- may have to pay an excise tax on a separate, gift tax return he/ she files.
                     </p>
									</div>
								</Row>
							</div>

							{/* FAQ 3 */}
							<div className="center-content-3-1">
								<Row>
									<img className="faq-img" src={faqHome} alt="img" />
									<div className="col">
										<p className="faq-heading">What are all of the tax deductions I can write off?</p>
										<p className="faq-details">
											First, please see <a className="faq-link" href="/cpa-free-guidance/income-and-expenses">“What are business or freelance expenses?”</a> to gain a true understanding of what you’re asking for.  Now, your tax deductions are finite: not limitless.  They are either on your bank account or app (Venmo, PayPal, etc.) statements, or on cash receipts you have saved.  If you don’t have any document to prove it, then it is not a tax deduction!
											Second, to determine your tax deductions, comb through your bank account and app statements for the tax return’s year.  Go through every month’s statement and every month’s transaction, one by one.  Do not expect to be done in 20 minutes: this task will take you a few hours to a few days, so pace yourself and plan your time accordingly.  Going forward, you want to do this every couple of months throughout the year.
                      Next, please see  <a className="faq-link" href="/cpa-free-guidance/income-and-expenses">“How do I gather and present my business or freelance expenses?”</a>  Once you are done with these 3 interrelated tips, take just a moment to read  <a className="faq-link" href="/cpa-free-guidance/best-practices-for-business-and-freelance-expenses">“Best practices for business or freelance expenses.”</a>
										</p>
									</div>
								</Row>
							</div>


						</div>
					</div>

				</div>




				{/* Contact Sections */}
				<div id="contact">
					{Contact}
					<br></br>
					<br></br>
					<br></br>
					{!Contact && message}
				</div>



			</div>
		);
	}
}

export default Home;
