import React from "react";
import "../index.css";

import Footer from "../components/Footer";
import PublicNav from "../components/PublicNav";
import Button from 'react-bootstrap/Button'
import TextField from "../components/TextField";

import Header3 from "../components/Header3";
import SubHeader3 from "../components/SubHeader3";


class ForgotPasswordConfirm extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			pin: "",
			password: "",
			passwordConfirm: ""

		}
	}


	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	save = () => {
		console.log("saved data")
	}


	render() {
		return (
			<div>
				<PublicNav />


				<Header3 text="Reset Password" />
				<SubHeader3 text="Please enter your password reset pin and set a new password" />
				<TextField handleChange={this.handleChange} heading="Password Reset Pin" type="text" name="pin" placeholder="Pin" />
				<TextField handleChange={this.handleChange} heading="New Password" type="password" name="password" placeholder="Password" />
				<TextField handleChange={this.handleChange} heading="Re-Type New Password" type="password" name="passwordConfirm" placeholder="Confirm Password" />

				<Button onClick={() => this.save()}>Reset Password</Button>


				<Footer />
			</div>
		);
	}
}

export default ForgotPasswordConfirm;
