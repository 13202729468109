import React from 'react'


function Footer(props) {
	let link = ""
	return (
		<div className="footer-div-quip">
			<a href="tel:+18882476829" className="phone-text-2">Toll-free: (888) 247-6829</a>
			<a href="https://www.op.nysed.gov/verification-search" className="footer-quip">Verify Us: CPA Firm License #117743 &nbsp; &nbsp; <span className="underline-footer">Link to CPA Licensing Board</span></a>
			<div className="row">
				<a className="margin-right-10" href="/Privacy">Privacy Policy</a>
				<a className="margin-right-10" href="/Terms">Terms & Conditions</a>
				<a className="margin-right-10" href="/Services">Services Description & Delivery</a>
				<a className="margin-right-10" href="/Refund">Refund Policy</a>
				<a href="/Promotions">Promotions & Client Discounts</a>
			</div>
			<div className="row">
			<a href="/Anti-Defamation-Policy">Anti-Defamation Policy</a>
			</div>
		</div>
	);
}
export default Footer;
