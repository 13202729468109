import React from 'react'
import "../index.css";


import Container from 'react-bootstrap/Container';

import chevronleft from '../Images/chevron-left.png';
import imgd4 from '../Images/Disclaimer4-img.png';


// SearchBar with a placeholder and search icon
function PriceToolBusinessStep3Expanded1(props) {
	return (
		<div>
			<Container>
				{/* BREADCRUMB */}
				<div>
					<img src={chevronleft} alt="img" />
					<button className="expanded-back-button" onClick={() => props.handleStep("Tax Preparation Business Compact")}>Business Tax Preparation Price Ranges</button>
				</div>

				{/* MAIN CONTENT */}
				<div className="box-shadow expanded-div">
					<p className="expanded-heading">Partnership-LLC and Regular (C) Corporation</p>

					<p className="expanded-text">If gross income (before expenses) is less than $100,000, then <span className="bold">$600-$1,300</span></p>
					<p className="gold-disclaimer">Most clients at Agro Accounting CPA pay toward the lower end of the range!</p>

					<p className="expanded-text">If gross income (before expenses) is over $100,000, then your price quote will depend on the size and complexity of your financial activity.  Please share your documents with us, through our safe and encrypted document portal on the next screen.  Or, give us access to your bookkeeping software at basil@agroaccounting.com.  We will get back to you by the end of today with a price quote!</p>
					<p className="gold-disclaimer">Need a bookkeeper? Hire us! We charge $45 per hour.</p>
				</div>
			</Container>


			{/* DISCLAIMER */}
			<div class="double-disclaimer">

				<div className="d4-div">
					<p className="d4-text">Need some guidance to gather and organize your business or freelance income and expenses?  Check out our free CPA advice here:
      <br></br>
						<a className="d4-link" href="/cpa-free-guidance/income-and-expenses"> Income & Expenses</a></p>
					<img className="d4-img" src={imgd4} alt="img" />
				</div>

				<div className="d5-div2">
					<p className="d4-text2">
						As easy as 1-2-3!   will save you time no matter which accountant you hire!
						Trustworthy,   100% confidential,   no commitment until you hire us!
        <a href="http://www.op.nysed.gov/opsearches.htm">  Verify us NY CPA firm license #117743  </a>
						Our document portal is safe and encrypted
        Stuck? call us, we are here to help!    <a href="https://calendly.com/agroaccounting-east">  Schedule a Call</a>
					</p>
				</div>





			</div>

		</div>
	);
}
export default PriceToolBusinessStep3Expanded1;
