import React from 'react'
import serviceInactive from '../Images/service-inactive.png';
import "../index.css";

// The Options services button
function Step1Option(props) {
	return (
		<div >

			<div >
				<div>
					<img className="serviceInactive-img" src={serviceInactive} alt="img" />
				</div>
				<p className="price-tool-step-one-option-text">{props.text}</p>
			</div>

		</div>
	);
}
export default Step1Option;
