import React from 'react'
import PropTypes from 'prop-types'

import MetaTags from 'react-meta-tags';
import PublicNav from "../components/PublicNav";
import Footer from "../components/Footer";


class Services extends React.Component {
  render () {
    return(
      <div>
      <MetaTags>
        <title>Services Description & Delivery - Agro Accounting CPA </title>
        <meta name="description" content="Agro Accounting CPA by Basil Agrocostea CPA is the one-stop destination for freelancers, artists and creative small businesses, for accounting service & help with tax preparation." />
      </MetaTags>
      <PublicNav />

      <div className="home-secondary-header-div">
        <p className="home-secondary-header">Services Description & Delivery</p>
        <div class="underline-thick"></div>
      </div>

      <div className="center-content">
      <h1 className="terms-heading"> DESCRIPTION OF SERVICES </h1>
      <p className="terms-text">
      We provide accounting and tax services as Certified Public Accountants (CPA’s).  Please visit our homepage for an introduction, and be sure to comb through the multiple informative pages of our website to learn more!  Our website’s design strives to be as simple and easy-to-use as possible.  Our website’s content strives for pure functionality: no “fluff” or upselling from us, we are accountants and not marketers.
      </p>

      <br></br>
      <br></br>


      <h1 className="terms-heading"> DELIVERY POLICY </h1>
      <p className="terms-text">
    We provide service first, ALWAYS.  Unless you engage us for a larger project - such as multiple years of tax preparation or representation in front of the tax authorities - or for a remote telephone consultation, we will never ask you for a payment upfront.  For tax preparation services, which constitute the great majority of our earnings, we file your tax return first, obtain a confirmation of acceptance for processing from the tax authorities (filing receipt), then request payment from you.  Once you have done so, we upload a copy of your tax return, filing receipts included, onto your secure AgroAccounting.com client account.
    </p>



      </div>
      <Footer />
      </div>

    );

  }
}

export default Services;
