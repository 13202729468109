import React from 'react'
import { connect } from 'react-redux'

import Authorization from './Authorization';
import EmailView from './EmailView';
import { loadGapi } from '../../actions/gmailActions';

import './index.css';
class GMail extends React.Component {

	componentWillMount() {
		this.props.loadGapi();
	}

	render() {
		console.log(this.props.fromEmail)
		return (
			<div style={{ height: '800px' }}>
				{this.props.isGapiLoaded ?
					<div style={{ height: '100%' }}>
						{this.props.isAuthenticated ?
							<EmailView fromEmail={this.props.fromEmail} />
							: <Authorization />
						}
					</div> :
					<div>
						Loading GAPI...
					</div>
				}

			</div>
		);
	}

}

const mapStateToProps = state => ({
	isGapiLoaded: state.gmail.isGapiLoaded,
	isAuthenticated: state.gmail.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
	loadGapi: () => { dispatch(loadGapi()) }
});

export default connect(mapStateToProps, mapDispatchToProps)(GMail);