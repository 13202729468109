import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import MessageListItem from './MessageListItem';



class MessageList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: 0
		}
	}


	setSelected(id, index) {
		this.props.setSelected(id);
		this.setState({
			selected: index
		});
	}

	render() {
		const messages = this.props.messages;
		console.log(messages)
		return (
			<InfiniteScroll
				initialLoad={true}
				loadMore={() => this.props.loadMore()}
				hasMore={this.props.hasNext}
				className='message-list'
			> {
					messages.map((message, index) => (
						<div onClick={() => this.setSelected(message.id, index)} key={index}>
							<MessageListItem data={message} selected={this.state.selected === index} />
						</div>
					))
				}
			</InfiniteScroll >
		);
	}

}
export default MessageList;