import React from 'react'
import PropTypes from 'prop-types'

import MetaTags from 'react-meta-tags';
import axios from "axios";
import "../index.css";

import PublicNav from "../components/PublicNav";
import Footer from "../components/Footer";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';



import logo from '../Images/logo.png';
import mapNY from '../Images/mapNY.png';
import mapLA from '../Images/mapLA.png';
import mapSanFran from '../Images/mapSanFran.png';
import mapSanDiego from '../Images/mapSanDiego.png';
import mapAustin from '../Images/mapAustin.png';
import white from '../Images/white.png';


import ContactSection1 from "../components/ContactSection1";
import ContactSection2 from "../components/ContactSection2";
import ContactSection3 from "../components/ContactSection3";
import ContactSectionDefault from "../components/ContactSectionDefault";


class Contact0 extends React.Component {
  constructor(props) {

		super(props);
		this.state = {
			contactName: "",
			contactEmail: "",
			contactMessage: "",
			emailResponse: "",
			emailBadResponse: "",
			contactSection: ""
		}
	}

	changeContactSection = (location) => {
		this.setState({
			contactSection: location
		});
	}

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state)
  }

  sendMessage = (fromEmail) => {
    //console.log("in send a message");

    let email = {
      from: fromEmail,
      name: this.state.contactName,
      email: this.state.contactEmail,
      message: this.state.contactMessage
    }

    axios
      .post('/api/taxReturns/email', email)
      .then(res => {
        console.log(res)
        //this.props.history.push("/EmailSuccesful")

        this.setState({
          emailResponse: "Email Sent!"
        });
        window.location.href = "/MessageConfirmation";


      }
      )
      .catch(err => {
        console.log(err)

        this.setState({
          emailBadResponse: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
        });
      }

      );

    //this.props.email(email);


  }




  render () {

		let section;
    let showorhide;
		let showorhide2 = "none"
		//let message = <div></div>

    if(this.props.closestCity != this.props.destination){
      showorhide = "none"
			console.log("inside showorhide")
    }

		// if(this.props.closestCity == "All"){
		// 	// showorhide2 = "block"
		// 	// console.log("inside showorhide2")
		// 	window.location.href = "/#contact"
		// }

		if(this.props.isOn){}




		 let mainBody =  <div>

						<div style={{ display: showorhide2}}>
								<ContactSectionDefault changeContactSection= {this.changeContactSection} shouldDisplay="none" />
						</div>

		      <div style={{ display: showorhide}}>
		      <Accordion preExpanded={["0"]} allowMultipleExpanded={false}>
		      <AccordionItem uuid={"0"} className="accordian">
		        <AccordionItemHeading className="accordian-heading">
		          <AccordionItemButton className="accordian-button">
		            {this.props.destination}
		        </AccordionItemButton>
		        </AccordionItemHeading>

		        <AccordionItemPanel className="accordian-text">
		          <div className="contact-div-half">
		          <div className="contact-div-1">
		          {/* Add Calendly Widget */}

		          {/* Add Phone Number & Email */}
		          <span className="bold4">{this.props.destination}</span>
		          <a href={this.props.telNumber} className="phone-text"> {this.props.number}</a>
		          <a href={this.props.mailto} className="phone-text">{this.props.email}</a>

		          {/* Add Address & Map */}
		          <a href={this.props.mapLink} className="phone-text">{this.props.address}</a>
		          <img className="contact-map2" src={this.props.map} alt="img"  />
		          </div>

		          <div className="contact-div-2">
		            <a className="calendly-widget" href={this.props.calendly}>Schedule a Call</a>
		            <br></br>
		              <div>
		                <p className="form-label">NAME</p>
		                <input className="form-input" value={this.state.contactName} onChange={this.handleChange} type="text" name="contactName" placeholder="John Doe" />
		              </div>

		              <div>
		                <p className="form-label">EMAIL</p>
		                <input className="form-input" onChange={this.handleChange} value={this.state.contactEmail} type="email" name="contactEmail" placeholder="john@gmail.com" />
		              </div>

		              <div>
		                <p className="form-label">MESSAGE</p>
		                <textarea className="form-input" type="text" name="contactMessage" value={this.state.contactMessage} onChange={this.handleChange} placeholder="How can I help?" rows="20" cols="50"></textarea>
		              </div>

		              <button className="contact-button" onClick={() => this.sendMessage(this.props.email)}>Submit</button>

		              <p className="emailResponse">{this.state.emailResponse}</p>
		              <p className="emailBadResponse">{this.state.emailBadResponse}</p>
		              </div>

		          </div>

		          <br></br>
		          <br></br>

		        </AccordionItemPanel>
		      </AccordionItem>
		      </Accordion>

		</div>
		      </div>

    return(

			<div>
				{mainBody}
			</div>


    );

  }
}

export default Contact0;
