import React from "react";
import "../index.css";

import Footer from "../components/Footer";
import PublicNav from "../components/PublicNav";
import Button from 'react-bootstrap/Button'
import TextField from "../components/TextField";

import Header3 from "../components/Header3";
import SubHeader3 from "../components/SubHeader3";


class SaveForLater extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			name: "",
			email: "",
			password: ""
		}
	}


	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	save = () => {
		console.log("saved data")
	}


	render() {
		return (
			<div>
				<PublicNav />


				<Header3 text="Save For Later" />
				<SubHeader3 text="If you need more time to think: all good.  Create an account, come back later." />
				<TextField handleChange={this.handleChange} heading="Full Name" type="text" name="name" placeholder="John Doe" />
				<TextField handleChange={this.handleChange} heading="Email" type="email" name="email" placeholder="John@gmail.com" />
				<TextField handleChange={this.handleChange} heading="Password" type="password" name="password" placeholder="" />

				<Button onClick={() => this.save()}>Save, come back later</Button>



				<Footer />
			</div>
		);
	}
}

export default SaveForLater;
