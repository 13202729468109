import React from 'react'
import "../index.css";


import PriceRangeCompact from "../components/PriceRangeCompact";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import timeline from '../Images/timeline-priceranges.png'; // Tell Webpack this JS file uses this image



// Step 3 - Compact - Tax Preparation (Individual)
function PriceToolIndividualStep3(props) {
	return (
		<div>

			<img className="timeline" src={timeline} alt="img" />


			<Container>

				<p className="steps-heading">Individual Tax Preparation Price Ranges </p>
				<p className="price-tool-sub-header">Price quotes include tax preparation and electronic filing with the Federal + State tax authority</p>
			</Container>

			<div className="priceTool-individual-step3-div">
				<Container>
					<Row>
						<button className="box-shadow compact" onClick={() => props.handleStep("Individual Tax Returns with Form W-2")}
							onTouchStart={() => props.handleStep("Individual Tax Returns with Form W-2")}>
							<PriceRangeCompact handleStep={props.handleStep} heading="Individual Tax Returns with Form W-2" text="If your income is reported on a Form W-2, please select this option." />
						</button>

						<button className="box-shadow compact" onClick={() => props.handleStep("Individual Tax Returns with Schedule C")}
							onTouchStart={() => props.handleStep("Individual Tax Returns with Schedule C")}>
							<PriceRangeCompact handleStep={props.handleStep} heading="Individual Tax Returns with Schedule C" text="1099(s),   Freelancer,   Independent Contractor,   Small Business Owner (either a Sole Proprietor or Single-Member LLC)" link="" />
						</button>
					</Row>

					<Row>
						<button className="box-shadow compact" onClick={() => props.handleStep("Individual Tax Returns with Schedule E")}
							onTouchStart={() => props.handleStep("Individual Tax Returns with Schedule E")}>
							<PriceRangeCompact handleStep={props.handleStep} heading="Individual Tax Returns with Schedule E" text="Rental Property Owner,   Airbnb Income" link="" />
						</button>

						<button className="box-shadow compact" onClick={() => props.handleStep("Individual Tax Returns with Schedule B, D and/or E: page 2")}
							onTouchStart={() => props.handleStep("Individual Tax Returns with Schedule B, D and/or E: page 2")}>
							<PriceRangeCompact handleStep={props.handleStep} heading="Individual Tax Returns with Schedule B, D and/or E: page 2" text="Stocks,   Bonds,   Mutual Funds,   Investment Income,  Crypto-Currency Trading,   Schedule K-1,   Trust Fund Income" link="" />
						</button>
					</Row>


					<button className="box-shadow compact" onClick={() => props.handleStep("Additional State Tax Returns")}
						onTouchStart={() => props.handleStep("Additional State Tax Returns")}>
						<PriceRangeCompact handleStep={props.handleStep} heading="Additional State Tax Returns" text="In case you have more than one State tax return to file." link="" />
					</button>
				</Container>

			</div>

			<div className="pricetool-footer footer">

				<button className="button-outlined" onClick={() => props.handleStep("Select Tax Preparation")}>
					Back
    </button>

				<div>


					<button className="margin-left-20 button-filled" onClick={() => props.handleStep("Modal 2 Individual")}>
						Next
    </button>
				</div>

			</div>

		</div>
	);
}
export default PriceToolIndividualStep3;
