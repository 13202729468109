import React from 'react'
import { getHeader } from '../../../actions/messageMethods';



class MessageListItem extends React.Component {
	render() {
		const message = this.props.data;

		let date = getHeader(message, 'Date');
		const time = date.substr(16, 6);
		return (
			<div className={this.props.selected ? 'message-list-item selected' : 'message-list-item'}>
				<div style={{ overflow: 'hidden', height: '100%' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<p className='email-subject'>{getHeader(message, 'Subject')} </p>
						<p >{time} </p>
					</div>
					<p>{message.snippet}</p>
				</div>
			</div>
		);
	}

}


export default MessageListItem;