import React from 'react'
import "../index.css";

import Disclaimer7 from "../components/Disclaimer7";
import Container from 'react-bootstrap/Container'

// SearchBar with a placeholder and search icon
function PriceToolOtherServices6(props) {

	// if (props.service !== "Bookkeeping Price Quote"){
	//   return null
	// }
	return (
		<div>
			{/* MAIN CONTENT */}
			<Container>
				<p className="steps-heading">Bookkeeping Price Quote</p>
				<div className="box-shadow expanded-div">
					<p className="bold">$45 per hour</p>
					<p className="blue-disclaimer">On average, we spend 1-3 hours per month entering a client's transactions, one by one.  Multiply this count by 12 then by our hourly rate, to get a feel for your total bookkeeping cost for the year.</p>
					<Disclaimer7 />
				</div>
			</Container>
			<div className="pricetool-other-footer footer">
				<button className="button-outlined" onClick={() => props.handleStep("Select a Service")}>
					Back
			    </button>
				<button className="button-filled" onClick={() => props.handleStep("Bookkeeping Modal1")}>
					Next
		        </button>
			</div>
		</div>
	);
}
export default PriceToolOtherServices6;
