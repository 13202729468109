import React from 'react'
import PropTypes from 'prop-types'
import FileBase64 from 'react-file-base64';

import ReactQuill from 'react-quill'; // ES6


class CreateBlogPost extends React.Component {

  constructor(props) {
  super(props);
  this.state = {
  title: "",
  author:"",
  description:"",
  image:null,
  text:''
  }
}



  handleChange = e => {
  this.setState({ [e.target.name]: e.target.value });
  console.log(this.state);
  }

  onChange = value => {
    this.setState({ text: value })

  }

  handleImage = (item) => {

    console.log(item);

    this.setState((state, props) => {
      return {
        image: item,
      };
    });

    // axios
    //   .put('/api/taxReturns/updateTaxReturnFilePDF', { taxReturnFile: this.state.taxReturnFile, id: id })
    //   .then(res =>
    //     //this.props.getTaxReturns()
    //     console.log(res)
    //   )
    //   .catch(err =>
    //     console.log(err)
    //
    //     //this.props.getTaxReturns()
    //   );

  }


  render () {
    console.log(this.state);
      return(
        <div>

          <ReactQuill value={this.state.text}
                  onChange={this.onChange} />

                <p>Article 1 </p>
                <p>{this.state.text}</p>

                {/*
        <p>Title</p>
        <div className="input">
          <input onChange={this.handleChange} type="text" name="title" />
        </div>

        <p>Author</p>
        <div className="input">
          <input onChange={this.handleChange} type="text" name="author" />
        </div>

        <p>Description</p>
        <div className="input">
          <input onChange={this.handleChange} type="text" name="description" />
        </div>


        <FileBase64
          multiple={false}
          onDone={this.handleImage} />

          */}



        </div>
      );
  }
}

export default CreateBlogPost;
