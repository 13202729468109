import React from 'react'
import "../index.css";

import Disclaimer7 from "../components/Disclaimer7";
import Container from 'react-bootstrap/Container'

// SearchBar with a placeholder and search icon
function PriceToolOtherServices3(props) {

	// if (props.service !== "Sales Tax & Payroll Tax Price Quotes"){
	//   return null
	// }
	return (
		<div>
			{/* MAIN CONTENT */}
			<Container>
				<p className="steps-heading">Sales Tax & Payroll Tax Price Quotes</p>

				<div className="box-shadow expanded-div">

					<Container>
						<p className="expanded-text text-center"> Sales tax preparation <span className="bold ">starts at $300</span> per year</p>
						<p className="expanded-text text-center"> Payroll tax preparation <span className="bold">starts at $800</span> per year</p>

						<Disclaimer7 />
					</Container>


				</div>
			</Container>

			<div className="pricetool-other-footer footer">
				<button className="button-outlined" onClick={() => props.handleStep("Select a Service")}>
					Back
      </button>

				<button className="button-filled" onClick={() => props.handleStep("Sales Tax & Payroll Tax Modal1")}>
					Next
      </button>
			</div>


		</div>
	);
}
export default PriceToolOtherServices3;
