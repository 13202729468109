import React from "react";
import { withRouter } from "react-router-dom";

import "../index.css";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../actions/authActions";

//import Header3 from "../components/Header3";
//import Footer from "../components/Footer";
//import PublicNav from "../components/PublicNav";
//import HomeSecondaryHeader from "../components/HomeSecondaryHeader";
//import SearchBar from "../components/SearchBar";
//import Disclaimer1 from "../components/Disclaimer1";
//import FreeGuidanceCategory from "../components/FreeGuidanceCategory";
//import TextField from "../components/TextField";
//import Button from 'react-bootstrap/Button'

import img1 from '../Images/signup.jpg';


// Returning Client Sign Up Screen
class ClientSignUp extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			name: "",
			email: "",
			password: "",
			errors: {}

		}
	}

	componentDidMount() {
		// If logged in and user navigates to Register page, should redirect them to their respectable dashboard
		if (this.props.auth.isAuthenticated) {
			this.props.history.push("/Dashboard");
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
		}
	}


	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

			// Helper function to validate email format
			validateEmail = email => {
				const re = /\S+@\S+\.\S+/;
				return re.test(email);
			};
		
		

	onSubmit = e => {
		e.preventDefault();

		const newUser = {
			name: this.state.name,
			email: this.state.email,
			password: this.state.password,
			role: "Client",
			errors: {}
		};

		//console.log(newUser)
		// Check if name, email, and password meet validation criteria
		if(!this.state.name || !this.state.email || !this.state.password) {
			// Display error message if any of the fields are empty
			alert("Name, email, and password are required");
			return;
		}
		else if (!this.validateEmail(this.state.email)) {
			// Display error message if email is invalid
			alert("Invalid email address");
			return;
		}
		else if (this.state.password.length < 6) {
			// Display error message if password is less than 6 characters
			alert("Password must be at least 6 characters long");
			return;
		}
		else {
			this.props.registerUser(newUser, this.props.history, "/ClientSignIn");
		}

		
	};


	render() {
		console.log(this.state);
		const { errors } = this.state;
		return (
			<div>

				<div className="row">
					<div className="half">
						<img className="sign-img" src={img1} alt="img1" />

					</div>

					<div className="half">
						<div className="client-sign-div">

							<p className="public-heading">Valued Client<br></br>Sign Up</p>

							<form onSubmit={this.onSubmit}>
								<div className="margin-bottom-30">
									<label>
										Full Name
                <input className="input-field-new" onChange={this.handleChange} type="text" name="name"  required />
									</label>
								</div>

								<div className="margin-bottom-30">
									<label>
										Email
                <input className="input-field-new" onChange={this.handleChange} type="email" name="email" required />
									</label>
								</div>

								<div className="margin-bottom-30">
									<label>
										Create Password
                <input className="input-field-new" onChange={this.handleChange} type="password" name="password" required />
									</label>
								</div>

								<p className="red-text">{errors.name}</p>
								<p className="red-text">{errors.email}</p>
								<p className="red-text">{errors.password}</p>

								<button type="submit" className="button-filled">Sign Up</button>
							</form>
						</div>
					</div>


				</div>





			</div>
		);
	}
}

ClientSignUp.propTypes = {
	registerUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{ registerUser }
)(withRouter(ClientSignUp));
