import React from 'react'

// SearchBar with a placeholder and search icon
function Disclaimer10(props) {
	return (
		<div className="disclaimer9-div">
			<p className="disclaimer9-heading">No Commitment Required</p>

			<p className="disclaimer9-text">We will never share or process your documents unless you hire us.  Your information will be discarded if you do not hire us.  We fully abide by the CPA Code of Ethics. CPA Firm License #117743</p>

		</div>
	);
}
export default Disclaimer10;
