import React from "react";
import { withRouter } from "react-router-dom";

import "../index.css";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../actions/authActions";

//import Footer from "../components/Footer";
//import PublicNav from "../components/PublicNav";
//import HomeSecondaryHeader from "../components/HomeSecondaryHeader";
//import SearchBar from "../components/SearchBar";
//import Disclaimer1 from "../components/Disclaimer1";
//import FreeGuidanceCategory from "../components/FreeGuidanceCategory";
//import GeneralSign from "../components/GeneralSign";
//import Button from 'react-bootstrap/Button'

import TextField from "../components/TextField";
//import Header3 from "../components/Header3";

import img1 from '../Images/beard-guy.png';


class AgroSignUp extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			name: "",
			email: "",
			JuniorAssociate : true,
			Senior: false,
			CpaEa:false,
			role: "Staff Associate",
			password: "",
			errors: {}

		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
		}
	}


	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	focus = (option) => {
		if (option === "Staff Associate") {

			this.setState((state, props) => {
				return {
					JuniorAssociate: true,
					Senior: false,
					CpaEa: false,
					role: "Staff Associate"

				};
			});

		} else if (option === "CPA/EA") {

			this.setState((state, props) => {
				return {
					Senior: false,
					JuniorAssociate: false,
					CpaEa: true,
					role: "CPA/EA"


				};
			});

		} else {

			this.setState((state, props) => {
				return {
					Senior: true,
					JuniorAssociate: false,
					CpaEa: false,
					role: "Senior Associate"


				};
			});

		}
	}


	onSubmit = e => {
		e.preventDefault();

		const newUser = {
			name: this.state.name,
			email: this.state.email,
			password: this.state.password,
			role: this.state.role
		};

		//console.log(newUser)
		// this.props.history,"/Admin/Dashboard"

		this.props.registerUser(newUser, this.props.history, "/AgroSignIn");
	};


	render() {
		console.log(this.state);
		const { errors } = this.state;

		return (
			<div>


				<div className="row">
					<div className="half">
						<img className="sign-img2" src={img1} alt="img" />

					</div>



					<div className="half">
						<div className="client-sign-div">

							<p className="public-heading">Agro Team Members Sign Up</p>

							<div>
								<button className={(this.state.JuniorAssociate) ? "button-filled" : "button-outlined"} onClick={() => this.focus("Staff Associate")}>Staff Associate</button>
								<button className={(this.state.Senior) ? "button-filled" : "button-outlined"} onClick={() => this.focus("Senior")}>Senior Associate</button>
								<button className={(this.state.CpaEa) ? "button-filled" : "button-outlined"} onClick={() => this.focus("CPA/EA")}>CPA/EA</button>

							</div>

							<form onSubmit={this.onSubmit}>
								<TextField handleChange={this.handleChange} name={"name"} type={"text"} heading={"Full Name"} placeholder={""} />
								<TextField handleChange={this.handleChange} name={"email"} type={"email"} heading={"Email"} placeholder={""} />
								<TextField handleChange={this.handleChange} name={"password"} type={"password"} heading={"Create Password"} placeholder={""} />
								<p className="red-text">{errors.name}</p>
								<p className="red-text">{errors.email}</p>
								<p className="red-text">{errors.password}</p>

								<button type="submit" className="button-filled">Sign up</button>
							</form>
						</div>
					</div>


				</div>

			</div>
		);
	}
}

AgroSignUp.propTypes = {
	registerUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{ registerUser }
)(withRouter(AgroSignUp));
