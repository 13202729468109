import React from 'react'
import MetaTags from 'react-meta-tags'

import PublicNav from "../components/PublicNav";

import Disclaimer2 from "../components/Disclaimer2";
import Footer from "../components/Footer";


import back from '../Images/chevron-left.svg';



import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

class International extends React.Component {
	render() {
		return (
			<div className="cpa-free-guidance-page">
				<MetaTags>
					<title>US Income Tax Filing Programs, Procedures & Requirements Guide</title>
					<meta name="description" content="Agro Accounting CPA helps international individuals & small businesses with US income tax filing; also, tax reporting for US individuals & small businesses with income outside of the US." />
				</MetaTags>
				<PublicNav />
				<div className="center-content-disclaimer">

					<Disclaimer2 />
				</div>

				<div className="center-content-cpa">

					<a href="/cpa-free-guidance">
						<div className="breadcrumb-div">
							<img className="back-button" src={back} alt="img" />
							<p className="cpa-detail-breadcrumb">Topic Categories</p>
						</div>
					</a>

					<p className="cpa-detail-heading">International</p>


					<Accordion
						allowMultipleExpanded={true}
						preExpanded="first" >

						<AccordionItem uuid="first" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Does my Visa type or status influence my US income tax filing?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									In general, no.  If you have been living in the US for a year or more as of December 31 of the tax return’s year then, for US income tax purposes, you are a US tax filer.  This is the case regardless of your Visa or citizenship status.  To follow, as a US tax filer you must report all income received during the tax year, whether from a US source or from abroad.  Please browse our tips in this International section of our A.I. supported FAQ page for more guidance on this topic.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									I am new to the US or recently departed.  Should I file a resident or nonresident US income tax return?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									To file a resident US income tax return, generally speaking, you must have lived in the US for a year or more as of December 31 of the tax return’s year.  If that is not the case, then you would file a nonresident US income tax return.  Resident tax filers report all worldwide income and taxes paid on their US income tax return.  Nonresident tax filers report only income earned and taxes paid in the US, on their US income tax return.
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									When do I have to report an international/ foreign bank account, investment account, real estate, etc.?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									First, please check out “When and how do I report income from outside of the US?” if you have business, freelance or rental (real estate) income from outside of the US.  Second, about foreign financial assets:
									The rule is, if you have a foreign financial asset -i.e. bank account, investment account, crypto-currency trading account- that had a value of $10,000 or more on any day of the tax return’s year, then you must report to the US government the highest value from the tax return’s year of that account.  You don’t have to pay taxes on the account’s high value, but you must report it on the Foreign Bank Account Report, “FBAR.
									To report your foreign financial asset(s), we will need from you:
                 1) The financial institution’s name and address;  <br></br>
									2) Your foreign account’s number;  <br></br>
									3) Your foreign account’s highest value during the tax return’s year.  All of this is required of each financial asset (account) with a value of $10,000 or more on any day of the tax return’s year.<br></br>
								</p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									When and how do I report income from outside of the US?
            </AccordionItemButton>

							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									If you have been living in the US for a year or more as of December 31 of the tax return’s year then, for US income tax purposes, you are a US tax filer.  This is the case regardless of your Visa or citizenship status.  To follow, as a US tax filer you must report all income received during the tax year, whether from a US source or from abroad.  Also, if you paid income taxes to another country on the income you earned or collected there, then those foreign taxes paid can generate a tax credit for you on your US income tax return. Our advice is that you gather and present your foreign income and expenses no differently than your US income and expenses.  Please browse this A.I. supported FAQ page for guidance on gathering and presenting your income and expenses.  Also use our PriceTool for more guidance and information on this topic.  It is our Free, Confidential, Non-Committal and Time-Saving tax preparation guide.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									I have a property (apartment, house, etc.) outside of the US.  Should I report the rental income from it on my US income tax return?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									First, please read “When and how do I report income from outside of the US?” to find out if you are required to report your foreign rental property to begin with.  Second, if the answer is yes, then we very much encourage you to report it.  This is because the majority of rental properties generate a loss on an income tax return -meaning, a tax benefit- as rental tax deductions include “depreciation.”  Depreciation is the write-off of your property’s purchase price + renovation costs throughout many years.  By reporting your foreign rental property, then, you are able to use a foreign tax loss (benefit) on your US income tax return!
            </p>
							</AccordionItemPanel>
						</AccordionItem>




					</Accordion>

					<Footer />

				</div>
			</div>


		);

	}
}

export default International;
