import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import axios from "axios";

//import QuickView from "../components/QuickView";
//import ClientHomeFolder from "../components/ClientHomeFolder";
//import FileManager from "../components/FileManager";
//import ClientNav from "../components/ClientNav";
import WelcomeBackClient from "../components/WelcomeBackClient";
import Footer from "../components/Footer";

import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import { getTaxReturnsByEmail } from "../actions/taxReturnActions";
//import ClientTaxReturn from "../routes/ClientTaxReturn";

//import profileimg from '../Images/profileimg.png';
import logo from '../Images/logo.png';
import folder from '../Images/folder.png';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



//import store from "../store";



class ClientDashboard extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
		}
	}

	static propTypes = {
		getTaxReturnsByEmail: PropTypes.func.isRequired
	};

	componentDidMount() {
		// If logged in and user navigates to Register page, should redirect them to their respectable dashboard
		if (!this.props.auth.isAuthenticated) {
			window.location.href = "/";
		}

		if (this.props.auth.user.role === "Intern") {
			window.location.href = "/Admin/Dashboard";
		}

		if (this.props.auth.user.role === "Senior Associate") {
			window.location.href = "/Admin/Dashboard";
		}

		if (this.props.auth.user.role === "Basil") {
			window.location.href = "/Admin/Dashboard";
		}

		this.props.getTaxReturnsByEmail(this.props.auth.user.email);

		let { taxReturns } = this.props.taxReturns;

		console.log(taxReturns)


	}

	onLogoutClick = () => {
		// e.preventDefault();
		this.props.logoutUser();
		window.location.href = "/";
	};


	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	save = () => {
		console.log("saved data")
	}

	deleteTaxReturn = (input) => {
		console.log(input);
		axios
			.post('/api/taxReturns/deleteTaxReturn', {id: input})
			.then(res => {
				console.log(res)
				this.props.getTaxReturnsByEmail(this.props.auth.user.email)
			}
			)
			.catch(err => {
				console.log("An Error has occurred")

				this.setState((state, props) => {
					return {
						fileDeleteStatus: "File delete"
					};
				});
					}
			);

	}


	render() {

		let { taxReturns } = this.props.taxReturns;
		let archiveStatus;
		console.log(taxReturns);


		let items = taxReturns.map((object, index) => (
				<div className="client-taxReturn-div" key={index}>
					{object.isAmountAgreedByClient != true ?
					//<button className="delete-button-2" onClick={()=> this.deleteTaxReturn(object._id)}>Delete</button>
					<button className="delete-button-2" onClick={()=> confirmAlert(
						{
							title: 'Wait',
							message: 'Are you sure you want to delete this tax return?',
							buttons: [
								{
									label: 'Yes',
									onClick: () => this.deleteTaxReturn(object._id)
								},
								{
									label: 'No',
									onClick: () => this.forceUpdate()
								}
							]
						}
						)}>Delete</button>



					:<p></p>
					}
				<a href={"/ClientTaxReturn/" + object._id}>
					<div>
						{object.archive == true ? <p className="archive-text">{object.archiveTaxYear}</p> : <p></p>}

						<img className="client-taxReturn-div-img" src={folder} alt="img" />
						<p className="client-taxReturn-div-service">{object.service}</p>
						<progress value="2" max="13">20%</progress>
					</div>
				</a>
				</div>
			));



		return (
			<div>

				{/*Top Part*/}
				<div>
					<div className="client-tax-dashboard-section1">

						<div className="client-nav">

							<a className="client-nav-logo" href="/">
								<img className="logo2" src={logo} alt="img" />
							</a>

							<div className="client-nav-detail">
								<a href="/Client/PriceTool" className="margin-right client-button-filled">New Order</a>

								<div className="col">
									<p className="admin-nav-name">{this.props.auth.user.name}</p>
									<p className="admin-nav-role">{this.props.auth.user.role}</p>
									<a href="/ForgotPassword">Reset Password?</a>
								</div>
								<button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>
							</div>


						</div>



						<div className="client-tax-dashboard-row-0">
							{/*Name etc*/}
							<div className="">

								<div className="col">
									<p className="client-tax-dashboard-name">{this.props.auth.user.name}</p>
									<p className="client-tax-dashboard-type">Client</p>
								</div>
							</div>



						</div>



					</div>


				</div>

				<div>
					{/*List of tax returns*/}
					<div className="row">
						{items}
					</div>



					{taxReturns.length === 0 ? <WelcomeBackClient /> : <p></p>}

					<Footer />

				</div>

			</div>
		);
	}
}

ClientDashboard.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	taxReturns: state.taxReturns,

});

export default connect(
	mapStateToProps,
	{ logoutUser, getTaxReturnsByEmail }
)(ClientDashboard);
