import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { authorize } from '../../actions/gmailActions';

const Authorization = (props) => (
	<div className='container outer'>

		<Button
			onClick={props.authorize}
			className='btn-block btn-lg btn-google'
		>
			<i className='fa fa-google' />
			Sign in with Google
         </Button>

	</div>
);


export default connect(
	undefined,
	dispatch => ({
		authorize: () => { dispatch(authorize()) }
	})
)(Authorization);