import React from 'react'
import PropTypes from 'prop-types'

import Footer from "../../components/Footer";
import PublicNav from "../../components/PublicNav";
import HomeSecondaryHeader from "../../components/HomeSecondaryHeader";

const BlogList = (props) => {
  var dateFormat = require('dateformat');
  console.log(props.data)

  // Depending on the number in the URL divide by
  let blogs = props.data.map((object,index) => {
    return(
          <div>
          <a className="blog-list-div" href={'/blog/article/' + object.id} key={index} >
          <img className="blog-list-thumbnail" src={object.thumbnail} />
          <div className="blog-list-col">
          <p className="blog-list-title text-padding">{object.title}</p>
          <p className="blog-list-text text-padding">Author: Basil Agrocostea</p>
          <p className="blog-list-text text-padding">{dateFormat(object.created_at, "dddd, mmmm dS, yyyy")}</p>
          <p className="blog-list-content" dangerouslySetInnerHTML={{ __html: object.content.substring(0,200) }}></p>
          </div>
          </a>
          </div>
        );
  });
  return(
    <div >
            {blogs}
    </div>

  );
}



export default BlogList;
