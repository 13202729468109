import React from 'react'

// Large heading used in the signup/signin screens
function SubHeader3(props) {
	return (
		<div>

			<p>{props.text}</p>

		</div>
	);
}
export default SubHeader3;
