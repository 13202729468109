import React from 'react'
import img from '../Images/bitmoji-one-finger.png';
import PublicNav from "../components/PublicNav";


class SuccessfulPayment extends React.Component {
	render() {
		return (

			<div>
				<PublicNav />
				<div className="home-secondary-header-div">
					<p className="home-secondary-header">Thanks For Paying!</p>
					<div class="underline-thick"></div>
				</div>

				<div className="center-content">

					<div className="welcome-div">
						<a className="ButtonFilled" href="/">Return Home</a>
						<img className="welcome-img" src={img} alt="img" />
					</div>

				</div>

			</div>


		);

	}
}

export default SuccessfulPayment;
