import React from "react";
import "../index.css";


//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addTaxReturn2 } from '../actions/taxReturnActions';

//import Footer from "../components/Footer";
//import Nav3 from "../components/Nav3";
//import StepsHeading from "../components/StepsHeading";

//import PriceToolStep1 from "../components/PriceToolStep1";
//import PriceToolStep2 from "../components/PriceToolStep2";
import PriceToolStep2Current from "../components/PriceToolStep2Current";
//import PriceToolIndividualStep3 from "../components/PriceToolIndividualStep3";

//import PriceToolIndividualStep3Expanded1 from "../components/PriceToolIndividualStep3Expanded1";
//import PriceToolIndividualStep3Expanded2 from "../components/PriceToolIndividualStep3Expanded2";
//import PriceToolIndividualStep3Expanded3 from "../components/PriceToolIndividualStep3Expanded3";
//import PriceToolIndividualStep3Expanded4 from "../components/PriceToolIndividualStep3Expanded4";
//import PriceToolIndividualStep3Expanded5 from "../components/PriceToolIndividualStep3Expanded5";


//import PriceToolBusinessStep3 from "../components/PriceToolBusinessStep3";
//import PriceToolBusinessStep3Expanded1 from "../components/PriceToolBusinessStep3Expanded1";
//import PriceToolBusinessStep3Expanded2 from "../components/PriceToolBusinessStep3Expanded2";
//import PriceToolBusinessStep3Expanded3 from "../components/PriceToolBusinessStep3Expanded3";

//import UploadDocuments from "../components/UploadDocuments";
import UploadDocuments2 from "../components/UploadDocuments2";

//import SaveForLater from "../components/SaveForLater";
import WhatHappensNextIndividual from "../components/WhatHappensNextIndividual";
import WhatHappensNextBusiness from "../components/WhatHappensNextBusiness";
import WhatHappensNextPriceTool2 from "../components/WhatHappensNextPriceTool2";

//import Confirmation from "../components/Confirmation";
import Confirmation2 from "../components/Confirmation2";


import Modal2Individual from "./Modal2Individual";
import Modal2Business from "./Modal2Business";
//import Modal2International from "./Modal2International";





//import PriceToolFooter from "../components/PriceToolFooter";

//import Button from 'react-bootstrap/Button'

//import PriceToolOtherServices1 from "../components/PriceToolOtherServices1";
//import PriceToolOtherServices2 from "../components/PriceToolOtherServices2";
//import PriceToolOtherServices3 from "../components/PriceToolOtherServices3";
//import PriceToolOtherServices4 from "../components/PriceToolOtherServices4";
//import PriceToolOtherServices5 from "../components/PriceToolOtherServices5";
//import PriceToolOtherServices6 from "../components/PriceToolOtherServices6";


//import Modal1 from "./Modal1";






class PriceTool2 extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			step: "Select Tax Preparation",
			service: "Select Tax Preparation",
			filingStatus: null,
			documentsLink: "",
			files: null,
			base64: null,
			notes: "",
		}
	}

	onSubmit = event => {

		let isDocumentsUploadedByClientItem = false;
		let serviceItem;
		let filingStatusItem;

		if (this.state.base64 != null) {
			if (this.state.base64.length > 0) {
				isDocumentsUploadedByClientItem = true;
			}
		}

		if (this.state.service === "Upload Documents Individual") {
			serviceItem = "Tax Preparation Individual"
		}

		if (this.state.service === "Upload Documents Business") {
			serviceItem = "Tax Preparation Business"
		}

		if (this.state.spouseFullName != null) {
			filingStatusItem = true;
		}

		var taxReturn = {

			service: serviceItem,
			filingStatus: filingStatusItem,
			documentsLink: this.state.documentsLink,
			files: this.state.files,
			notes: this.state.notes,
			step: "PriceTool Complete",
			isDocumentsUploadedByClient: isDocumentsUploadedByClientItem,
			email: this.props.auth.user.email,
			name: this.props.auth.user.name

		}
		// clientId:"5e06ce90a270f02b5f1c5c1a",

		console.log(taxReturn);

		//Send Data to API / Save Tax Return
		this.props.addTaxReturn2(taxReturn);

		this.setState((state, props) => {
			return {
				step: "Confirmation"
			};
		});



	}



	handleService = (value) => {
		console.log(value)

		this.setState({
			service: value,
			step: value
		});
	}

	handleStep = (value) => {

		if (value === "Confirmation") {
			this.onSubmit()
		}

		console.log(value)

		this.setState({
			step: value
		});
	}

	handleConfirmation = () => {
		window.location.href = '/'
	}

	handleFiles = (item) => {
		//console.log(items.name)

		// let bases =[];
		//
		// items.forEach(function (item) {
		//   bases.concat(item.base64)
		// });
		//
		// console.log(bases)

		// this.setState((state, props) => {
		//   return {
		//     base64:this.state.base64.concat(bases)
		//   };
		//});

		// let bases = items.map(function(item){
		//   return(
		//     item.base64
		//   )
		// });
		//
		// let files = items.map(function(item){
		//   return(
		//     item
		//   )
		// });
		//
		// console.log(bases)
		// console.log(files)

		this.setState((state, props) => {
			return {
				files: item,
			};
		});
	}



	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	save = () => {
		console.log("saved data")
	}

	handleShow = () => {
		this.setState({
			modalIndividual: true
		});
	}

	handleHide = () => {
		this.setState({
			modalIndividual: false
		});
	}

	updateTaxReturnNotes = () => {

		let updateObject = {
			id: "5e07a6d1736d4a155366357a",
			notes: "This is preety cool"
		}

		this.props.updateTaxReturnNotes(updateObject);
	}

	componentDidMount() {
		// if(!this.props.auth.isAuthenticated){
		//   window.location.href = "/";
		// }
	}




	render() {

		let screen;

		// Ask if individual or business
		if (this.state.step === "Select Tax Preparation") {
			screen = <PriceToolStep2Current step={this.state.step} service={this.state.service} handleStep={this.handleStep} handleService={this.handleService} />
		}

		if (this.state.step === "Tax Preparation Individual Compact") {
			screen = <Modal2Individual handleStep={this.handleStep} />
		}

		if (this.state.step === "Tax Preparation Business Compact") {
			screen = <Modal2Business handleStep={this.handleStep} />
		}

		if (this.state.step === "Modal 2 Individual") {
			screen = <Modal2Individual handleStep={this.handleStep} />
		}

		if (this.state.step === "Modal 2 Business") {
			screen = <Modal2Business handleStep={this.handleStep} />
		}


		if (this.state.step === "Upload Documents Individual") {
			screen = <UploadDocuments2
				text1="All of the year-end tax forms you have received"
				text2="Your business, freelance and/or rental income and expenses, gathered and presented per our "
				text3="Your health insurance information (months covered and total amount paid) or Form 1095, if any"
				text4="The last tax returns you filed, Federal and State"
				text5="Your driver’s license or State ID card, front and back"
				handleStep={this.handleStep}
				handleChange={this.handleChange}
				handleFiles={this.handleFiles}
				next="Confirmation"
				back="Select Tax Preparation"
				files={this.state.files}
				name={this.props.auth.user.name}
			/>
		}

		if (this.state.step === "What Happens Next Individual") {
			screen = <WhatHappensNextIndividual back="Upload Documents Individual" handleChange={this.handleChange} handleStep={this.handleStep} />
		}

		if (this.state.step === "Confirmation") {
			screen = <Confirmation2 submit={this.onSubmit} handleStep={this.handleStep} handleConfirmation={this.handleConfirmation} />
		}



		if (this.state.step === "Upload Documents Business") {
			screen = <UploadDocuments2
				text1="Give us access to your bookkeeping software at basil@agroaccounting.com"
				text2="Don't use bookkeeping software?  Your business, freelance and/or rental income and expenses, gathered and presented per our "
				text3="All of the year-end tax forms received for your company"
				text4="The last tax returns filed for your company, Federal and State"
				text5="The signing partner or director's license or State ID"
				hideForBusiness="none"
				service="business"
				handleStep={this.handleStep}
				handleChange={this.handleChange}
				handleFiles={this.handleFiles}
				next="Confirmation"
				back="Select Tax Preparation"
				files={this.state.files}
				name={this.props.auth.user.name}

			/>
		}

		if (this.state.step === "What Happens Next PriceTool2") {
			screen = <WhatHappensNextPriceTool2 back="Upload Documents Business" handleChange={this.handleChange} handleStep={this.handleStep} />
		}


		if (this.state.step === "What Happens Next Business") {
			screen = <WhatHappensNextBusiness back="Upload Documents Business" handleChange={this.handleChange} handleStep={this.handleStep} />
		}






		console.log(this.state);
		return (
			<div>
				{screen}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	taxReturns: state.taxReturns,
	auth: state.auth
});

export default connect(
	mapStateToProps,
	{ addTaxReturn2 }
)(PriceTool2);
