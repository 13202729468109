import React from 'react'
import PropTypes from 'prop-types'
import "../index.css";

import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import { getTaxReturnsByEmail } from "../actions/taxReturnActions";import axios from "axios";
import { Spinner } from 'react-bootstrap';


class LastFileManager extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      link: null,
      loading: true,
    }
  }


  // Delete Files uploaded by user
  getAllTaxReturns = () => {
    axios
      .post('/api/taxReturns/getByEmail123', { email: this.props.auth.user.email })
      .then(res => {

        let lastItem;
        let lastItemLink;

        lastItem = res.data[res.data.length - 1];

        console.log(lastItem);
        console.log(lastItem._id);

        lastItemLink = "/ClientTaxReturn/" + lastItem._id + "/file-manager"
        console.log(lastItemLink)

        this.setState((state, props) => {
          return {
            link: lastItemLink,
            loading: false
          };
        });



      }
      )
      .catch(err => { console.log("An Error has occurred")});
  }

  componentDidMount() {
    this.getAllTaxReturns();
  }



  render () {
    console.log(this.state)
    return (
      <div style={{ position: 'relative' }}>
      {this.state.loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // semi-transparent overlay
            zIndex: 9999, // higher z-index than other content
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {this.state.link && <div>
      <a href={this.state.link} className="button-filled"> Upload Documents to File Manager </a>
        </div>}
        </div>
    );

  }
}


LastFileManager.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	taxReturns: state.taxReturns,

});

export default connect(
	mapStateToProps,
	{ logoutUser, getTaxReturnsByEmail }
)(LastFileManager);
