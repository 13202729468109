import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { googleUser, logoutUser } from "../actions/authActions";
import { connect } from "react-redux";
import { Table, Media, Button } from 'reactstrap';
import logo from '../Images/logo.png';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

const AdminReviewList = ({ auth, googleUser, logoutUser }) => {
  const [list, setList] = useState([]);
  const [clientsWithReview, setClientsWithReview] = useState([]);
  const [update, setUpdate] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});


  useEffect(() => {
    if (!(auth.user.role === "Basil" || auth.user.email === "onboarding@agroaccounting.com" || auth.user.role === "Staff Associate" || auth.user.role === "Senior Associate")) {
      window.location.href = "/Admin/Dashboard";
    }
    getClientsWithoutReview();
    getClientsWithReview();
    getGoogleSession();
    getGoogleReview();
  }, []);

  const getClientsWithoutReview = async () => {
    await axios.get("/api/users/getClientsWithoutReview")
      .then(res => setList(res.data))
      .catch(err => console.log(err));
    setReviews((value) => [...value]);
  };

  const getClientsWithReview = () => {
    axios.get("/api/users/getClientsWithReview")
      .then(res => setClientsWithReview(res.data))
      .catch(err => console.log(err));
  };

  const updateUserReviewId = (value) => {
    axios.put('/api/users/updateClientReview', value)
      .then(res => {
        getClientsWithoutReview();
        getClientsWithReview();
        setReviews([...reviews]);
        
      })
      .catch(err => {
        getClientsWithoutReview();
        getClientsWithReview();
        setReviews([...reviews]);
      });
  };

  const updateGoogleSession = (value) => {
    axios.put('/api/users/updateGoogleSession', value)
      .then(res => {
        getClientsWithoutReview();
        getClientsWithReview();
        getGoogleReview();
      })
      .catch(err => {
        getClientsWithoutReview();
        getClientsWithReview();
        getGoogleReview();
      });
  };

  const getGoogleSession = () => {
    axios.post('/api/users/getGoogleSession',{id: auth.user.id})
      .then(res => {
        console.log(res);
      })
      .catch(err => {
      });
  };

  const getGoogleReview = () => {
    axios.post('/api/users/getGoogleReview', { id: auth.user.id })
      .then(res => res.data).then(response => setReviews(response))
      .catch(err => {
      });
  };

  const updateBulkClientReview = (value) => {
    axios.put('/api/users/updateBulkClientReview', value)
      .then(res => {
        getClientsWithoutReview();
        getClientsWithReview();
        setReviews([...reviews]);
      })
      .catch(err => {
        getClientsWithoutReview();
        getClientsWithReview();
        setReviews([...reviews]);
      });
  };

  const approveBulkClientReview = (value) => {
    axios.put('/api/users/approveBulkClientReview', value)
      .then(res => {
        getClientsWithoutReview();
        getClientsWithReview();
        setReviews([...reviews]);
      })
      .catch(err => {
        getClientsWithoutReview();
        getClientsWithReview();
        setReviews([...reviews]);
      });
  };

  const handleMapUsersWithReview = () => {
    const matchedReviews = list.map(user => {
      const matchingReview = reviews.filter(review =>
        clientsWithReview.every(client => client.ClientReviewId !== review.reviewId)
            ).find(review => review.reviewer.displayName === user.name);

      if (matchingReview) {
        return {
          id: user._id,
          reviewId: matchingReview.reviewId,
          reviewApproved: true
        };
      }
      return null;
    }).filter(match => match !== null);
    if (matchedReviews.length)
    updateBulkClientReview({ list: matchedReviews });
  };

  const handleSelectChange = (event, id) => {
    const selectedValue = event.target.value;
    setSelectedOption({ id: selectedValue, reviewId: id });
  };

  const handleSelectedConfirm = () => {
    if (selectedOption?.reviewId); {
      updateUserReviewId(selectedOption);
    }
  };


  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async refreshToken => {
      try{
      const result = await axios.post('https://oauth2.googleapis.com/token', {
        code: refreshToken.code,
        client_id: '447751023241-qer5dqshtjc326mm3108otot5si5s5vs.apps.googleusercontent.com',
        client_secret: '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',
        redirect_uri: 'https://agroaccounting.com',
        grant_type: 'authorization_code',
        access_type: 'offline'
      });
  
      const tokens = result.data;
      updateGoogleSession({id:auth.user.id,session:tokens});
  
      // Return the tokens in the response
      
    } catch(error) {
      console.error('Error exchanging authorization code for tokens:', error);
      
    }
  
    },
    onError:error=>{
      console.log(error)
    },
    scope:"https://www.googleapis.com/auth/userinfo.email profile openid https://www.googleapis.com/auth/plus.business.manage https://www.googleapis.com/auth/business.manage",
    accessType:"offline"
  })


  return (
    <div>
      <div className="admin-nav">
        <a className="client-nav-logo" href="/Admin/Dashboard">
          <img className="logo" src={logo} alt="img" />
        </a>
        <div className="admin-nav-detail">
          <div>
            <p className="admin-nav-name">{auth.user.name}</p>
            <p className="admin-nav-role">{auth.user.role}</p>
          </div>
        </div>
      </div>
      <div className="admin-nav-detail">
        <div>
          <p className="admin-nav-name">Connect to Google</p>
          <p className="admin-nav-role">to get client review list</p>
        </div>
        <div style={{ marginRight: "30px" }}>
          
          <Button  color='' onClick={login} > Sign in with Google</Button>
        </div>
      </div>
      {reviews.length > 0 &&
        <div className="admin-nav-detail">
          <div style={{ marginRight: "30px" }}>
            <Button onClick={()=> approveBulkClientReview({})}>Confirm Match Reviews</Button>
          </div>
          <div style={{ marginRight: "30px" }}>
            <Button onClick={handleMapUsersWithReview}>Auto Map Reviews</Button>
          </div>
        </div>}

      {auth.google?.credential || true ? <Table>
        <thead>
          <tr>
            <th>Profile</th>
            <th>Name</th>
            <th>Rating</th>
            <th>Date</th>
            <th>Comment</th>
            <th>Action</th>
          </tr>
        </thead>
        {reviews.filter(review =>
    clientsWithReview.every(client => client.ClientReviewId !== review.reviewId)
        ).map((review, index) => (
    
          <tbody key={index}>
      <tr id={review.reviewId}>
        <td style={{ maxWidth: '30px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <Media style={{width:"50px", height:"50px"}} left href={review.reviewer.profilePhotoUrl} target="_blank" rel="noopener noreferrer">
            <Media style={{width:"50px", height:"50px"}}  object src={review.reviewer.profilePhotoUrl} alt={review.reviewer.displayName} />
          </Media>
        </td>
        <td><a href={review.reviewer.profilePhotoUrl} target="_blank" rel="noopener noreferrer">{review.reviewer.displayName}</a></td>
        <td>{review.starRating}</td>
        <td>{new Date(review.createTime).toLocaleDateString()}</td>
        <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {review.comment}
              </td>
              <td style={{ minWidth: '100px', maxWidth: "500px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingRight: "20px", display: "flex" }}>  
          <select name={`select-name-${review.reviewId}`} id={`select-${review.reviewId}`} autoComplete='true' defaultValue={list.filter(user => user.ClientReviewId === review.reviewId)[0]?._id || "1"} placeholder='Select A Reviewer' onChange={(event) => handleSelectChange(event, review.reviewId)} style={{ display: "flex", width: "200px", marginLeft: "10px", marginRight: "10px", height: "35px", fontSize: "15px" }}>
                  <option value="1">Select a User</option>
            {list.map((user) => <option key={user._id} value={user._id}>{user.name}</option>)}
          </select>
                
                { (  auth.user.role === "Staff Associate" || auth.user.role === "Senior Associate" || auth.user.email === "onboarding@agroaccounting.com") &&
                  <Button onClick={() => {
                  if (document.getElementById(`select-${review.reviewId}`).value === "1")
                    console.log(document.getElementById(`select-${review.reviewId}`).value)
                  else 
                    updateUserReviewId({ id: document.getElementById(`select-${review.reviewId}`).value, reviewId: review.reviewId})
                }
                }>{"Map Review"}</Button>}
                {document.getElementById(`select-${review?.reviewId}`)?.value !== "1" && <Button
                  style={{margin:"0px 10px"}}
                  onClick={() => {
                    updateUserReviewId({ id: document.getElementById(`select-${review.reviewId}`).value, reviewId: null });
                    document.getElementById(`select-${review?.reviewId}`).value = "1";
                }
                }>{"Remove"}</Button>}
                {(auth.user.role === "Basil") && <Button onClick={() => {
                  if (document.getElementById(`select-${review.reviewId}`).value === "1")
                    console.log(document.getElementById(`select-${review.reviewId}`).value)
                  else {
                    updateUserReviewId({ id: document.getElementById(`select-${review.reviewId}`).value, reviewId: review.reviewId, reviewApproved: true })
                    document.getElementById(`select-${review?.reviewId}`).value = "1";
                  }
                
          }}>Confirm</Button> }     
        </td>
      </tr>
    </tbody>
  ))}
      </Table> :
        <p className="admin-nav-name" style={{ margin: "20px 0px", width: "100%", textAlign: "center" }}>No session is available please login with google </p>
      }
    </div>
  );
};

AdminReviewList.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  googleUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, googleUser })(AdminReviewList);
