import React from "react";
import "../index.css";


//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addTaxReturn } from '../actions/taxReturnActions';
import { updateTaxReturnNotes } from '../actions/taxReturnActions';

//import Footer from "../components/Footer";
//import Nav3 from "../components/Nav3";
//import StepsHeading from "../components/StepsHeading";

import PriceToolStep1 from "../components/PriceToolStep1";
import PriceToolStep2 from "../components/PriceToolStep2";
import PriceToolIndividualStep3 from "../components/PriceToolIndividualStep3";

import PriceToolIndividualStep3Expanded1 from "../components/PriceToolIndividualStep3Expanded1";
import PriceToolIndividualStep3Expanded2 from "../components/PriceToolIndividualStep3Expanded2";
import PriceToolIndividualStep3Expanded3 from "../components/PriceToolIndividualStep3Expanded3";
import PriceToolIndividualStep3Expanded4 from "../components/PriceToolIndividualStep3Expanded4";
import PriceToolIndividualStep3Expanded5 from "../components/PriceToolIndividualStep3Expanded5";


import PriceToolBusinessStep3 from "../components/PriceToolBusinessStep3";
import PriceToolBusinessStep3Expanded1 from "../components/PriceToolBusinessStep3Expanded1";
import PriceToolBusinessStep3Expanded2 from "../components/PriceToolBusinessStep3Expanded2";
import PriceToolBusinessStep3Expanded3 from "../components/PriceToolBusinessStep3Expanded3";

import UploadDocuments from "../components/UploadDocuments";
import SaveForLater from "../components/SaveForLater";
import WhatHappensNextIndividual from "../components/WhatHappensNextIndividual";
import WhatHappensNextBusiness from "../components/WhatHappensNextBusiness";

import Confirmation from "../components/Confirmation";


import Modal2Individual from "./Modal2Individual";
import Modal2Business from "./Modal2Business";
import Modal2International from "./Modal2International";





//import PriceToolFooter from "../components/PriceToolFooter";

//import Button from 'react-bootstrap/Button'

import PriceToolOtherServices1 from "../components/PriceToolOtherServices1";
import PriceToolOtherServices2 from "../components/PriceToolOtherServices2";
import PriceToolOtherServices3 from "../components/PriceToolOtherServices3";
import PriceToolOtherServices4 from "../components/PriceToolOtherServices4";
import PriceToolOtherServices5 from "../components/PriceToolOtherServices5";
import PriceToolOtherServices6 from "../components/PriceToolOtherServices6";


import Modal1 from "./Modal1";






class PriceTool extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			step: "Select a Service",
			service: "",
			filingStatus: null,
			documentsLink: "",
			files: null,
			base64: null,
			notes: "",
			name: null,
			spouseFullName: null,
			email: null,
			password: null,
			referredBy: ""
		}
	}

	onSubmit = event => {
		//event.preventDefault();

		// this.state.files.map
		//
		// let theOne = taxReturns.map(function(item){
		//     return(item.base64)
		// });

		let isDocumentsUploadedByClientItem = false;
		let serviceItem;
		let filingStatusItem;

		if (this.state.base64 != null) {
			if (this.state.base64.length > 0) {
				isDocumentsUploadedByClientItem = true;
			}
		}


		if (this.state.service === "Tax Preparation Individual Compact") {
			serviceItem = "Tax Preparation Individual"
		}

		if (this.state.service === "Tax Preparation Business Compact") {
			serviceItem = "Tax Preparation Business"
		}

		if (this.state.spouseFullName != null) {
			filingStatusItem = true;
		}

		var taxReturn = {

			service: serviceItem,
			filingStatus: filingStatusItem,
			documentsLink: this.state.documentsLink,
			base64: this.state.base64,
			files: this.state.files,
			notes: this.state.notes,
			name: this.state.name,
			spouseFullName: this.state.spouseFullName,
			step: "PriceTool Complete",
			referredBy: this.state.referredBy,
			isDocumentsUploadedByClient: isDocumentsUploadedByClientItem,
			email: this.state.email,
			password: this.state.password
		}
		// clientId:"5e06ce90a270f02b5f1c5c1a",

		console.log(taxReturn);

		//Send Data to API / Save Tax Return
		this.props.addTaxReturn(taxReturn);

		this.setState((state, props) => {
			return {
				step: "Confirmation"
			};
		});



	}



	handleService = (value) => {
		console.log(value)

		this.setState({
			service: value,
			step: value
		});
	}

	handleStep = (value) => {

		if (value === "Confirmation") {
			this.onSubmit()
		}

		console.log(value)

		this.setState({
			step: value
		});
	}

	handleConfirmation = () => {
		window.location.href = '/'
	}

	handleFiles = (item) => {
		//console.log(items.name)

		// let bases =[];
		//
		// items.forEach(function (item) {
		//   bases.concat(item.base64)
		// });
		//
		// console.log(bases)

		// this.setState((state, props) => {
		//   return {
		//     base64:this.state.base64.concat(bases)
		//   };
		//});

		// let bases = items.map(function(item){
		//   return(
		//     item.base64
		//   )
		// });
		//
		// let files = items.map(function(item){
		//   return(
		//     item
		//   )
		// });

		// console.log(bases)
		// console.log(files)

		this.setState((state, props) => {
			return {
				files: item
			};
		});
	}



	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	save = () => {
		console.log("saved data")
	}

	handleShow = () => {
		this.setState({
			modalIndividual: true
		});
	}

	handleHide = () => {
		this.setState({
			modalIndividual: false
		});
	}

	updateTaxReturnNotes = () => {

		let updateObject = {
			id: "5e07a6d1736d4a155366357a",
			notes: "This is preety cool"
		}

		this.props.updateTaxReturnNotes(updateObject);
	}




	render() {

		let screen;

		//Grid
		if (this.state.step === "Select a Service") {
			screen = <PriceToolStep1 service={this.state.service} handleStep={this.handleStep} handleService={this.handleService} />
		}

		// Ask if individual or business
		if (this.state.step === "Select Tax Preparation") {
			screen = <PriceToolStep2 service={this.state.service} handleStep={this.handleStep} handleService={this.handleService} />
		}

		//Tax Prep Individual Compact
		if (this.state.step === "Tax Preparation Individual Compact") {
			screen = <PriceToolIndividualStep3 handleStep={this.handleStep} />
		}

		if (this.state.step === "Individual Tax Returns with Form W-2") {
			screen = <PriceToolIndividualStep3Expanded1 handleStep={this.handleStep} />
		}

		if (this.state.step === "Individual Tax Returns with Schedule C") {
			screen = <PriceToolIndividualStep3Expanded2 handleStep={this.handleStep} />
		}

		if (this.state.step === "Individual Tax Returns with Schedule B, D and/or E: page 2") {
			screen = <PriceToolIndividualStep3Expanded3 handleStep={this.handleStep} />
		}

		if (this.state.step === "Individual Tax Returns with Schedule E") {
			screen = <PriceToolIndividualStep3Expanded4 handleStep={this.handleStep} />
		}

		if (this.state.step === "Additional State Tax Returns") {
			screen = <PriceToolIndividualStep3Expanded5 handleStep={this.handleStep} />
		}

		if (this.state.step === "Modal 2 Individual") {
			screen = <Modal2Individual handleStep={this.handleStep} />
		}

		if (this.state.step === "Modal 2 Business") {
			screen = <Modal2Business handleStep={this.handleStep} />
		}


		if (this.state.step === "Upload Documents Individual") {
			screen = <UploadDocuments
				text1="All of the year-end tax forms you have received"
				text2="Your business, freelance and/or rental income and expenses, gathered and presented per our "
				text3="Your health insurance information (months covered and total amount paid) or Form 1095, if any"
				text4="The last tax returns you filed, Federal and State"
				text5="Your driver’s license or State ID card, front and back"
				handleStep={this.handleStep}
				handleChange={this.handleChange}
				handleFiles={this.handleFiles}
				next="What Happens Next Individual"
				back="Modal 2 Individual"
				files={this.state.files}
			/>
		}

		if (this.state.step === "What Happens Next Individual") {
			screen = <WhatHappensNextIndividual back="Upload Documents Individual" handleChange={this.handleChange} handleStep={this.handleStep} />
		}

		if (this.state.step === "Confirmation") {
			screen = <Confirmation name={this.state.name} email={this.state.email} password={this.state.password} submit={this.onSubmit} handleStep={this.handleStep} handleConfirmation={this.handleConfirmation} />
		}

		if (this.state.step === "Save For Later") {
			screen = <SaveForLater handleStep={this.handleStep} />
		}

		//Tax Prep Business Compact
		if (this.state.step === "Tax Preparation Business Compact") {
			screen = <PriceToolBusinessStep3 handleStep={this.handleStep} />
		}

		if (this.state.step === "Partnership-LLC and Regular (C) Corporation") {
			screen = <PriceToolBusinessStep3Expanded1 handleStep={this.handleStep} />
		}

		if (this.state.step === "S Corporation") {
			screen = <PriceToolBusinessStep3Expanded2 handleStep={this.handleStep} />
		}

		if (this.state.step === "Not-For-Profit (Information Only) Returns") {
			screen = <PriceToolBusinessStep3Expanded3 handleStep={this.handleStep} />
		}

		if (this.state.step === "Upload Documents Business") {
			screen = <UploadDocuments
				text1="Give us access to your bookkeeping software at basil@agroaccounting.com"
				text2="Don't use bookkeeping software?  Your business, freelance and/or rental income and expenses, gathered and presented per our "
				text3="All of the year-end tax forms received for your company"
				stimulusText="Amount of March 2021 Stimulus check, if any "
				hideForBusiness="none"
				service="business"
				text4="The last tax returns filed for your company, Federal and State"
				text5="The signing partner or director's license or State ID"
				handleStep={this.handleStep}
				handleChange={this.handleChange}
				handleFiles={this.handleFiles}
				next="What Happens Next Business"
				back="Modal 2 Business"
				files={this.state.files}

			/>
		}

		if (this.state.step === "What Happens Next Business") {
			screen = <WhatHappensNextBusiness back="Upload Documents Business" handleChange={this.handleChange} handleStep={this.handleStep} />
		}

		if (this.state.step === "Company Formation & Tax ID Issuance") {
			screen = <PriceToolOtherServices1 handleStep={this.handleStep} />
		}

		if (this.state.step === "Company Formation & Tax ID Issuance Modal1") {
			screen = <Modal1 handleStepValue="Company Formation & Tax ID Issuance" heading="How does our price range sound?" text="If you'd like to hire us to set up a company for you and/or get you a tax ID, then Thank You! + let's schedule a time to go over the process and to get things started." handleStep={this.handleStep} />
		}

		if (this.state.step === "Accounting & Business Consulting") {
			screen = <PriceToolOtherServices2 handleStep={this.handleStep} />
		}

		if (this.state.step === "Accounting & Business Consulting Modal1") {
			screen = <Modal1 handleStepValue="Accounting & Business Consulting" heading="How does our price range sound?" text="We'd love to speak with you!  Would you like to meet in person (at our office) or would you prefer talking remotely, over the phone?" handleStep={this.handleStep} />
		}

		if (this.state.step === "Sales Tax & Payroll Tax") {
			screen = <PriceToolOtherServices3 handleStep={this.handleStep} />
		}

		if (this.state.step === "Sales Tax & Payroll Tax Modal1") {
			screen = <Modal1 handleStepValue="Sales Tax & Payroll Tax" handleStep={this.handleStep} heading="How does our price range sound?" text="If you'd like to work with us for payroll and/or sales tax preparation, then Thank You! + let's schedule a time to go over the process and to get things started." />
		}

		if (this.state.step === "Estimated Tax Calculation") {
			screen = <PriceToolOtherServices5 handleStep={this.handleStep} />
		}

		if (this.state.step === "Estimated Tax Calculation Modal1") {
			screen = <Modal1 handleStepValue="Estimated Tax Calculation" handleStep={this.handleStep} heading="How does our price range sound?" text="If you'd like for us to prepare your estimated tax payment documents, then Thank You! + let's schedule a time to go over the process and to get thing started." />
		}


		if (this.state.step === "Bookkeeping") {
			screen = <PriceToolOtherServices6 handleStep={this.handleStep} />
		}

		if (this.state.step === "Bookkeeping Modal1") {
			screen = <Modal1 handleStepValue="Bookkeeping" handleStep={this.handleStep} heading="How does our price range sound?" text="If you'd like to hire us for bookkeeping, then Thank You! + let's schedule a time to go over the process and to get things started." />
		}

		if (this.state.step === "International/ Multinational Income") {
			screen = <PriceToolOtherServices4 handleStep={this.handleStep} />
		}

		if (this.state.step === "International/ Multinational Income Modal2") {
			screen = <Modal2International handleStepValue="International/ Multinational Income" handleStep={this.handleStep} />
		}

		if (this.state.step === "Audit Representation") {
			screen = <Modal1 handleStepValue="Select a Service" handleStep={this.handleStep} heading="How about we do this:" text="If you'd like for us to represent you under audit, then Thank You! + let's schedule a time to go over your service need and to get things started." />
		}

		if (this.state.step === "Tax Relief Options") {
			screen = <Modal1 handleStepValue="Select a Service" handleStep={this.handleStep} heading="How about we do this:" text="If you'd like for us to seek and carry out tax relief options for you, then Thank You! + let's schedule a time to go over what you're looking (and eligible) for, then to get things started." />
		}






		console.log(this.state);
		return (
			<div>

				{screen}

			</div>
		);
	}
}

const mapStateToProps = state => ({
	taxReturns: state.taxReturns
});

export default connect(
	mapStateToProps,
	{ addTaxReturn, updateTaxReturnNotes }
)(PriceTool);
