import React from 'react'
import "../index.css";

import PriceRangeCompact from "../components/PriceRangeCompact";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import timeline from '../Images/timeline-priceranges.png'; // Tell Webpack this JS file uses this image


// SearchBar with a placeholder and search icon
function PriceToolBusinessStep3(props) {
	return (
		<div>
			<img className="timeline" src={timeline} alt="img" />

			<Container>

				<p className="steps-heading">Business Tax Preparation Price Ranges</p>
				<p className="price-tool-sub-header">Price quotes include tax preparation and electronic filing with the Federal + State tax authority</p>
			</Container>

			<div className="priceTool-individual-step3-div">
				<Container>

					<Row>
						<button className="compact" onClick={() => props.handleStep("Partnership-LLC and Regular (C) Corporation")}
							onTouchStart={() => props.handleStep("Partnership-LLC and Regular (C) Corporation")}>
							<PriceRangeCompact heading="Partnership-LLC and Regular (C) Corporation" link="" />
						</button>

						<button className="box-shadow compact" onClick={() => props.handleStep("S Corporation")}
							onTouchStart={() => props.handleStep("S Corporation")}>
							<PriceRangeCompact heading="S Corporation" text="" link="" />
						</button>
					</Row>

					<Row>
						<button className="box-shadow compact" onClick={() => props.handleStep("Not-For-Profit (Information Only) Returns")}
							onTouchStart={() => props.handleStep("Not-For-Profit (Information Only) Returns")}>
							<PriceRangeCompact heading="Not-For-Profit (Information Only) Returns" text="" link="" />
						</button>
					</Row>

				</Container>
			</div>

			<div className="pricetool-footer footer">

				<button className="button-outlined" onClick={() => props.handleStep("Select Tax Preparation")}>
					Back
      </button>

				<div>

					<button className="margin-left-20 button-filled" onClick={() => props.handleStep("Modal 2 Business")}>
						Next
      </button>

				</div>

			</div>


		</div>
	);
}
export default PriceToolBusinessStep3;
