import React from 'react'
import "../index.css";

import Disclaimer4 from "../components/Disclaimer4";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import chevronleft from '../Images/chevron-left.png';


// SearchBar with a placeholder and search icon
function PriceToolIndividualStep3Expanded2(props) {
	return (
		<div>
			<Container>
				<Col>


					{/* BREADCRUMB */}
					<div>
						<img src={chevronleft} alt="img" />
						<button className="expanded-back-button" onClick={() => props.handleStep("Tax Preparation Individual Compact")}>Individual Tax Preparation Price Ranges</button>
					</div>

					{/* MAIN CONTENT */}
					<div className="box-shadow expanded-div">
						<p className="expanded-heading">Individual Tax Returns with Schedule C </p>

						<p className="expanded-text">Tax return with up to 3 Forms 1099 + 1 Form W-2 = <span className="bold">$300</span></p>
						<p className="gold-disclaimer">Assuming your income AFTER business expenses is less than $30,000.  Agro Accounting CPA helps modest-income tax filers!</p>

						<p className="expanded-text">Tax return with additional Forms 1099 (after the first 3) = <span className="bold">$25 each</span></p>
						<p className="gold-disclaimer">If you have a lot of 1099's, expect a bulk discount from us!</p>

						<p className="expanded-text">Courtesy discount for low level of activity = <span className="bold">$25-$75</span></p>
						<p className="gold-disclaimer">For annual income under $10,000, and 30 or less income + expense transactions during the year.</p>

						<p className="expanded-text">Married taxpayers filing jointly can expect to EACH pay the cost of a stand-alone tax return, MINUS an overall discount of 25-35%.</p>
					</div>


					{/* DISCLAIMER */}
					<Disclaimer4 />
				</Col>
			</Container>

		</div>
	);
}
export default PriceToolIndividualStep3Expanded2;
