import React from 'react'
import PublicNav from "../components/PublicNav";
import "../index.css";
import axios from "axios";


class ForgetPassword1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			confirmationMessage: "",
			error: ""
		}
	}


	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	sendMessage = () => {

		axios
			.post('/api/users/checkEmail', { email: this.state.email })
			.then(res =>

				// Send the Reset Link Email
				axios
					.post('/api/users/sendResetPasswordLink', { email: this.state.email })
					.then(res =>
						console.log(res),
						this.setState((state, props) => {
							return {
								error: "",
								confirmationMessage: "Check your email for the password reset link"
							};
						}))
					.catch(err => console.log(err))


			)
			.catch(err =>
				console.log(err),

				this.setState((state, props) => {
					return {
						error: "The email you entered doesnt match our records"
					};
				})


			);


	}



	render() {
		return (
			<div>
				<PublicNav />
				<div className="home-secondary-header-div">
					<p className="home-secondary-header">Forgot Password?</p>
					<p className="normal-text-grey">Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
					<div class="underline-thick"></div>
				</div>

				<div className="center-content">
					<p>Email</p>
					<input className="form-input" type="email" name="email" placeholder="Enter your email on file" onChange={this.handleChange} />
					<button onClick={() => this.sendMessage()} className="client-button-filled">Send Link</button>
					<p className="emailResponse">{this.state.confirmationMessage}</p>
					<p className="emailBadResponse">{this.state.error}</p>

				</div>

			</div>


		);

	}
}

export default ForgetPassword1;
