import React from 'react'
import PropTypes from 'prop-types'

import ContactSection1 from "../components/ContactSection1";
import ContactSection2 from "../components/ContactSection2";
import ContactSection3 from "../components/ContactSection3";
import ContactSectionDefault from "../components/ContactSectionDefault";
import Contact0 from "../components/Contact0";


import logo from '../Images/logo.png';
import mapNY from '../Images/mapNY.png';
import mapPhilly from '../Images/mapPhilly.png';
import mapLA from '../Images/mapLA.png';
import mapSanFran from '../Images/mapSanFran.png';
import mapSanDiego from '../Images/mapSanDiego.png';
import mapAustin from '../Images/mapAustin.png';
import white from '../Images/white.png';
import mapMI from '../Images/mapMI.png';
import mapDV from '../Images/mapDV.png';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';


class CityContact extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      city:"",
      contactName: "",
      contactEmail: "",
      contactMessage: "",
      emailResponse: "",
      emailBadResponse: "",

			locationInactive: false,


      userLat: "",
      userLong: "",
      closestCity:"",

      cityData: [

        {
          name: "New York City",
          lat:40.712776,
          long:-74.005974,
        },
        {
          name: "Philadelphia",
          lat:39.952583,
          long:-75.165222,
        },
        {
          name: "Chicago",
          lat:41.878113,
          long:-87.629799,
        },
        {
          name: "Austin TX",
          lat:30.267153,
          long:-97.743057,
        },
        {
          name: "Twin Cities MN",
          lat:44.977489,
          long:-93.264374,
        },
        {
          name: "Los Angeles",
          lat:34.052235,
          long:-118.243683,
        },
        {
          name: "San Francisco Bay Area",
          lat:37.774929,
          long:-122.419418,
        },
        {
          name: "San Diego",
          lat:32.715736,
          long:-117.161087,
        },
        {
          name: "Portland",
          lat:45.512230,
          long:-122.658722,
        },

      ]
    }
  }

  componentDidMount() {
    if ("geolocation" in navigator) {
      //console.log("Available");
    } else {
      console.log("Not Available");
    }

		// Get the location of the user
		this.getLocation();

    // Get the closest city from the user
    //this.getClosestCity();
  }

  //Get location of current user
  getLocation = () => {
  navigator.geolocation.getCurrentPosition(

  position => {
  // this.setState({
  //   userLat: position.coords.latitude,
  //   userLong: position.coords.longitude
  // });

	let distances = [];
	let sorted;

	// Get the distance for every city from the user and add to array
	this.state.cityData.forEach(city => {
	distances.push( this.getDistance(position.coords.latitude,
																	 position.coords.longitude,
																	 city.lat,
																	 city.long,
																	 city.name));

	 //Sorted by distance
	 sorted = distances.sort(function(a, b){ return a.distanceFromUser-b.distanceFromUser});
	 //console.log(sorted);

	 // Get the closest city
	 this.setState({
		 closestCity: sorted[0].name
	 });
 })

},
  error => {
   console.error("Error Code = " + error.code + " - " + error.message);

	 this.setState({
			locationInactive: true
		});

  }

)}





  // Get the distance between user and a particular city
   getDistance = (lat1, lon1, lat2, lon2, city) => {

		 //console.log(lat1, lon1, lat2, lon2, city);
    var radlat1 = Math.PI * lat1/180;
 		var radlat2 = Math.PI * lat2/180;
 		var theta = lon1-lon2;
 		var radtheta = Math.PI * theta/180;
 		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
 		if (dist > 1) {
 			dist = 1;
 		}
 		dist = Math.acos(dist);
 		dist = dist * 180/Math.PI;
 		dist = dist * 60 * 1.1515;
 		dist = dist * 1.609344

    return {name: city, distanceFromUser:dist};
}




  render () {

		//console.log(this.state);

    return(
      <div>
      <Accordion preExpanded={["0"]} allowMultipleExpanded={true}>

				<Contact0
				closestCity="All"
					/>



          <Contact0
          telNumber="tel:+19295818381"
          destination="New York City"
          number="(929) 581-8381"
          email="NYC@agroaccounting.com"
          mailto="mailto: NYC@agroaccounting.com"
          mapLink="https://goo.gl/maps/K3APmybWUWNehP1m7"
          address="42 West Street Brooklyn, NY 11222"
          map={mapNY}
          calendly="https://calendly.com/agroaccounting-east"
          hide={true}
          closestCity={this.state.closestCity}
					isOn={true}
            />


          <Contact0
          telNumber="tel:+12679964499"
          destination="Philadelphia"
          number="(267) 996-4499"
          email="philly@agroaccounting.com"
          mailto="mailto: philly@agroaccounting.com"
          mapLink="https://goo.gl/maps/8bYzVKmpTUGFBuDh6"
          address="800 E Girard Ave Floor 1 Philadelphia, PA 19125"
          calendly="https://calendly.com/agroaccounting-east"
          map={mapPhilly}
          hide={true}
          closestCity={this.state.closestCity}
					isOn={true}

          />

            <Contact0
            telNumber="tel:+13055495624"
            destination="Miami"
            number="(305) 549-5624"
            email="miami@agroaccounting.com"
            mailto="mailto: miami@agroaccounting.com"
            mapLink=""
            address="5231 SW 5th Terrace, Coral Gables, FL 33134, United States"
            map={mapMI}
            calendly="https://calendly.com/agroaccounting-east"
            hide={true}
            closestCity={this.state.closestCity}
						isOn={true}

            />  




          <Contact0
            telNumber="tel:+13129999750"
            destination="Chicago"
            number="(312) 999-9750 "
            email="chicago@agroaccounting.com"
            mailto="mailto: chicago@agroaccounting.com"
            mapLink=""
            address=""
            map={white}
            calendly="https://calendly.com/agroaccounting-central"
            hide={true}
            closestCity={this.state.closestCity}
						isOn={true}

            />

            <Contact0
            telNumber="tel:+15122983276"
            destination="Austin TX"
            number="(512) 298-3276"
            email="atx@agroaccounting.com"
            mailto="mailto: atx@agroaccounting.com"
            mapLink="https://goo.gl/maps/jPTiEvk3u7Eeohwq6"
            address="106 East 6th Street, Suite 900-163 Austin, TX 78701"
            map={mapAustin}
            calendly="https://calendly.com/agroaccounting-central"
            hide={true}
            closestCity={this.state.closestCity}
						isOn={true}

            />

            <Contact0
            telNumber="tel:+16124245111"
            destination="Twin Cities MN"
            number="(612) 424-5111"
            email="minnie@agroaccounting.com"
            mailto="mailto: minnie@agroaccounting.com"
            mapLink=""
            address=""
            map={white}
            calendly="https://calendly.com/agroaccounting-central"
            hide={true}
            closestCity={this.state.closestCity}
						isOn={true}

            />

            <Contact0
            telNumber="tel:+13035296538"
            destination="Denver"
            number="(303) 529-6538"
            email="denver@agroaccounting.com"
            mailto="mailto: denver@agroaccounting.com"
            mapLink=""
            address="1461 Logan St Suite A1, Denver, CO 80203, United States"
            map={mapDV}
            calendly="https://calendly.com/agroaccounting-rockies"
            hide={true}
            closestCity={this.state.closestCity}
						isOn={true}

            />


          <Contact0
           telNumber="tel:+13233745399"
           destination="Los Angeles"
           number="(323) 374-5399"
           email="DTLA@agroaccounting.com"
           mailto="mailto: DTLA@agroaccounting.com"
           mapLink="https://goo.gl/maps/PWB1zvC1LdTEGjZr7"
           address="6600 Sunset Boulevard, Suite 300, Los Angeles CA 90028"
           map={mapLA}
           calendly="https://calendly.com/agroaccounting-west"
           hide={true}
           closestCity={this.state.closestCity}
					 isOn={true}

           />


           <Contact0
           telNumber="tel:+14152340115"
           destination="San Francisco Bay Area"
           number="(415) 234-0115"
           email="SanFran@agroaccounting.com"
           mailto="mailto: SanFran@agroaccounting.com"
           mapLink="https://goo.gl/maps/Snakt7MzPmLEAgr76"
           address="95 Third Street, 2nd Floor, San Francisco CA 94103"
           map={mapSanFran}
           calendly="https://calendly.com/agroaccounting-west"
           hide={true}
           closestCity={this.state.closestCity}
					 isOn={true}

           />


           <Contact0
           telNumber="tel:+16194313102"
           destination="San Diego"
           number="(619) 431-3102"
           email="SanDiego@agroaccounting.com"
           mailto="mailto: SanDiego@agroaccounting.com"
           mapLink="https://goo.gl/maps/7crDhwGFsEj7KE5G6"
           address="600 B Street, Suite 300 San Diego, CA 92101"
           map={mapSanDiego}
           calendly="https://calendly.com/agroaccounting-west"
           hide={true}
           closestCity={this.state.closestCity}
					 isOn={true}

           />


           <Contact0
           telNumber="tel:+19712666101"
           destination="Portland"
           number="(971) 266-6101"
           email="pdx@agroaccounting.com"
           mailto="mailto: pdx@agroaccounting.com"
           mapLink=""
           address=""
           map={white}
           calendly="https://calendly.com/agroaccounting-west"
           hide={true}
           closestCity={this.state.closestCity}
					 isOn={true}
         />

          <Contact0
           telNumber="tel:+12065905608"
           destination="Seattle"
           number="(206) 590-5608"
           email="seattle@agroaccounting.com"
           mailto="mailto: seattle@agroaccounting.com"
           mapLink=""
           address=""
           map={white}
           calendly="https://calendly.com/agroaccounting-west"
           hide={true}
           closestCity={this.state.closestCity}
					 isOn={true}

           />



      </Accordion>
      </div>

    );

  }
}

export default CityContact;
