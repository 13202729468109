import React from 'react'
import { connect } from 'react-redux'

import MessageList from './MessageList';
import MessageContent from './MessageContent';

import { getMessageList, fetchMessageList } from '../../../actions/gmailActions';
import { getHeader } from '../../../actions/messageMethods';

function getEmail(string) {
	let Str = string;
	const s = Str.indexOf('<') + 1;
	const e = Str.indexOf('>');
	Str = Str.substring(s, e);
	return Str;
}

function filterMessages(messages, fromEmail) {
	return messages.filter(function (message) {
		const email = getEmail(getHeader(message, 'From')).replace(/\s/g, '');
		return email === fromEmail;
	});
}

class MailView extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			firstContentLoaded: false
		}

		this.refElement = React.createRef();
		this.setSelectedID = this.setSelectedID.bind(this);
		this.loadMore = this.loadMore.bind(this);
	}

	componentWillMount() {
		this.props.getMessages("INBOX");
	}
	componentWillReceiveProps(nextProp) {
		if (!this.state.firstContentLoaded) {
			const messages = filterMessages(nextProp.messages, this.props.fromEmail);
			if (messages.length !== 0) {
				this.child.setSelectedId(messages[0].id);
				this.setState({
					firstContentLoaded: true
				});
			}
		}
	}
	loadMore() {
		if (!this.props.isLoading) {
			this.props.fetchMessages("INBOX");
		}
	}

	setSelectedID(id) {
		this.child.setSelectedId(id);
	}

	render() {
		const fromEmail = this.props.fromEmail;
		let messages = filterMessages(this.props.messages, fromEmail);

		return (
			<div style={{ display: 'flex', height: '100%' }}>
				<div style={{ width: '30%', height: '100%', border: '1px solid #E1E6ED' }}>
					<MessageList
						setSelected={(id) => this.setSelectedID(id)}
						messages={messages}
						hasNext={this.props.hasNext}
						loadMore={this.loadMore} />
				</div>
				<div style={{ width: '70%', border: '1px solid #E1E6ED', height: '100%', overflowY: 'scroll' }}>
					<MessageContent onRef={ref => (this.child = ref)} />
				</div>
			</div>
		);
	}

}


const mapStateToProps = state => ({
	messages: state.gmail.messages,
	isLoading: state.gmail.isLoading,
	hasNext: typeof state.gmail.nextPageToken !== 'undefined'
});

const mapDispatchToProps = dispatch => ({
	getMessages: (listName) => { dispatch(getMessageList(listName)) },
	fetchMessages: (listName) => { dispatch(fetchMessageList(listName)) }
});

export default connect(mapStateToProps, mapDispatchToProps)(MailView);