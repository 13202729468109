import React from 'react'
import MetaTags from 'react-meta-tags';

import PublicNav from "../components/PublicNav";

import Disclaimer2 from "../components/Disclaimer2";
import Footer from "../components/Footer";


import back from '../Images/chevron-left.svg';



import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

class BestPractices extends React.Component {
	render() {
		return (
			<div className="cpa-free-guidance-page">
				<MetaTags>
					<title>Tax Planning & Consulting Services for Freelancers & Freelance Workers</title>
					<meta name="description" content="We provide trusted tax planning & consulting services; we suggest the best practices for business & freelance income & expenses.  For artists & freelance workers." />
				</MetaTags>
				<PublicNav />
				<div className="center-content-disclaimer">
					<Disclaimer2 />
				</div>

				<div className="center-content-cpa">
					<a href="/cpa-free-guidance">
						<div className="breadcrumb-div">
							<img className="back-button" src={back} alt="img" />
							<p className="cpa-detail-breadcrumb">Topic Categories</p>
						</div>
					</a>

					<p className="cpa-detail-heading">BEST PRACTICES FOR BUSINESS & FREELANCE EXPENSES</p>


					<Accordion
						allowMultipleExpanded={true}
						preExpanded="first second" >

						<AccordionItem uuid="first" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									1.
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Open a separate checking account to distinguish business or freelance expenses from personal, living expenses.  Some expenses are mixed while others are murky, unclear.  The best way to shoot down an IRS auditor’s suspicion is to track the two in separate bank accounts.  This second account doesn’t necessarily need to be opened as a business account at the bank, it just has to be used in that way by you.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem uuid="second" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									2.
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Gathering and presenting your business or freelance expenses is a time-consuming process.  It is the grunt work of your tax filing!  In the same way a college student shouldn’t begin a 5-page research paper the night before it is due, don’t begin to determine your expenses thinking it will take you an hour or less.  It might take you days!  That’s just the “nature of the beast,” so do it periodically throughout the year.
            </p>
							</AccordionItemPanel>
						</AccordionItem>




					</Accordion>

					<Footer />

				</div>
			</div>


		);

	}
}

export default BestPractices;
