import React from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import axios from "axios";
import logo from '../Images/logo.png';
import folder from '../Images/folder.png';
import { getTaxReturns } from '../actions/taxReturnActions';
import HomeSecondaryHeader from "../components/HomeSecondaryHeader";
import { logoutUser } from "../actions/authActions";

class AdminArchive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  static propTypes = {
		getTaxReturns: PropTypes.func.isRequired
	};

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
			window.location.href = "/";
		}
    
      //this.getAll()
      this.props.getTaxReturns();

  }

  onLogoutClick = () => {
    // e.preventDefault();
    this.props.logoutUser();
    window.location.href = "/";
  };

  getAll = () => {
    console.log("Here in get all");

    axios
    .get('/api/taxReturns/getArchivedTaxReturns')
      .then(res => {
        //console.log(res)

        this.setState((state, props) => {
          return {
            data: res.data
          };
        });
      })
      .catch(err =>
        console.log(err)
      );
  }


  render () {
    let { taxReturns } = this.props.taxReturns;
    let archived = taxReturns.filter(item => item.archive == true);
    let unique = [...new Set(archived.map(item => item.archiveTaxYear))];

    console.log(archived);
    console.log(unique);

    let items = unique.sort(function(a, b){return b-a}).map((object,index) => {
			return <a href={"/Admin/Archive/" + object}>
			  <div className="admin-archive-taxReturn-div" key={index}>
				<img className="admin-taxreturn-img" src={folder} alt="img" />

        <div className="col">
        <p className="archiveTaxYearTitle">Tax Year</p>
				<p className="archiveTaxYear" >{object}</p>
        </div>
			</div>
		</a>
  });


    return(
      <div>
        <div className="admin-nav">
          <a className="client-nav-logo" href="/Admin/Dashboard">
            <img className="logo" src={logo} alt="img" />
          </a>

          <div className="admin-nav-detail">

            <div>
              <p className="admin-nav-name">{this.props.auth.user.name}</p>
              <p className="admin-nav-role">{this.props.auth.user.role}</p>
            </div>

            <button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>

          </div>
        </div>

        <HomeSecondaryHeader text="Tax Returns Archives" />

        <div className="row-space-between">
          {items}
        </div>


      </div>
    )
  }
}

AdminArchive.propTypes = {
	auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,

};


const mapStateToProps = state => ({
	auth: state.auth,
  taxReturns: state.taxReturns
});

export default connect(
	mapStateToProps,
	{logoutUser,getTaxReturns}
)(AdminArchive);
