import React from 'react'
import "../index.css";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import chevronleft from '../Images/chevron-left.png';



// SearchBar with a placeholder and search icon
function PriceToolIndividualStep3Expanded5(props) {
	return (
		<div>
			<Container>
				<Col>

					{/* BREADCRUMB */}
					<div>
						<img src={chevronleft} alt="img" />
						<button className="expanded-back-button" onClick={() => props.handleStep("Tax Preparation Individual Compact")}>Individual Tax Preparation Price Ranges</button>
					</div>

					{/* MAIN CONTENT */}
					<div className="box-shadow expanded-div">
						<p className="expanded-heading">Additional State Tax Returns</p>

						<Container>
						<p className="expanded-text-0">After the first (after your State of residence) = <span className="bold">$75 each</span> <br></br> May be up to $200 each, depending on the complexity.</p>
						<p className="gold-disclaimer">Most clients at Agro Accounting CPA pay toward the lower end of the range!</p>
						</Container>

					</div>

				</Col>
			</Container>
		</div>
	);
}
export default PriceToolIndividualStep3Expanded5;
