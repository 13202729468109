import {
	GAPI_LOAD_REQUEST,
	GAPI_LOAD_SUCCESS,
	AUTHORIZED,
	UNAUTHORIZED,
	GET_MESSAGE_LIST_REQUEST,
	GET_MESSAGE_LIST_SUCCESS,
	FETCH_MESSAGE_LIST_SUCCESS,
	FETCH_MESSAGE_LIST_REQUEST
} from '../actions/types'

const initialState = {
	isGapiLoaded: false,
	isAuthenticated: false,

	//Message List Values
	isLoading: false,
	messages: [],
	nextPageToken: ''
};

export default function authorizeReducer(state = initialState, action) {
	switch (action.type) {
		case GAPI_LOAD_REQUEST:
			return {
				...state,
				isGapiLoaded: false
			};
		case GAPI_LOAD_SUCCESS:
			return {
				...state,
				isGapiLoaded: true
			};

		case AUTHORIZED:
			return {
				...state,
				isAuthenticated: true
			};
		case UNAUTHORIZED:
			return {
				...state,
				isAuthenticated: false
			};

		case GET_MESSAGE_LIST_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_MESSAGE_LIST_SUCCESS:
			return {
				...state,
				messages: action.payload.messages,
				isLoading: false,
				nextPageToken: action.payload.nextPageToken
			};
		case FETCH_MESSAGE_LIST_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_MESSAGE_LIST_SUCCESS:
			return {
				...state,
				messages: [
					...state.messages,
					...action.payload.messages
				],
				isLoading: false,
				nextPageToken: action.payload.nextPageToken
			};

		default:
			return state;
	}
}