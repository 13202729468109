import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { googleUser, logoutUser } from "../actions/authActions";
import { connect } from "react-redux";
import { Table, Media, Button } from 'reactstrap';
import logo from '../Images/logo.png';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

const AdminGmailApi = ({ auth, googleUser, logoutUser }) => {
  const [token, setToken] = useState({});

  useEffect(() => {
    if (auth.user.role !== "Basil") {
      window.location.href = "/Admin/Dashboard";
    }
  }, []);

  
  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async refreshToken => {
      try{
      const result = await axios.post('https://oauth2.googleapis.com/token', {
        code: refreshToken.code,
        client_id: '563007923513-pe5uu1nqrptds9lriqih2j1ah42kpoup.apps.googleusercontent.com',//'41270093684-dhifemmil837hnntbrch0fn5bufl5d9l.apps.googleusercontent.com',
        client_secret: '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',//'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',
        redirect_uri: 'https://agroaccounting.com',
        grant_type: 'authorization_code',
        access_type: 'offline'
      });
  
        const tokens = result.data;
        setToken(tokens);
  
      // Return the tokens in the response
      
    } catch(error) {
      console.error('Error exchanging authorization code for tokens:', error);
      
    }
  
    },
    onError:error=>{
      console.log(error)
    },
    scope:"https://www.googleapis.com/auth/userinfo.email profile openid https://mail.google.com",
    accessType:"offline"
  })

  console.log(token)

  return (
    <div>
      <div className="admin-nav">
        <a className="client-nav-logo" href="/Admin/Dashboard">
          <img className="logo" src={logo} alt="img" />
        </a>
        <div className="admin-nav-detail">
          <div>
            <p className="admin-nav-name">{auth.user.name}</p>
            <p className="admin-nav-role">{auth.user.role}</p>
          </div>
        </div>
      </div>
      <div className="admin-nav-detail">
        <div>
          <p className="admin-nav-name">Connect to Google</p>
          <p className="admin-nav-role">to enable gmail api</p>
        </div>
        <div style={{ marginRight: "30px" }}>
          
          <Button  color='' onClick={login} > Sign in with Google</Button>
        </div>
      </div>
      {token?.access_token &&
      <>
      <p>
          Refresh Token: <span>{`"${token.refresh_token}"`}</span>    
      </p>
      </>
      }
    </div>
  );
};

AdminGmailApi.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  googleUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, googleUser })(AdminGmailApi);
