import React from 'react'
import PropTypes from 'prop-types'
import "../index.css";
import Table from 'react-bootstrap/Table'

import { connect } from "react-redux";
import { getTaxReturns } from '../actions/taxReturnActions';



class AdminClientList extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
		}
	}

	static propTypes = {
		getTaxReturns: PropTypes.func.isRequired
	};


	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			window.location.href = "/";
		}

		this.props.getTaxReturns();

	}



	render() {
		return (
			<div>

				<p>Client List</p>

				<Table responsive>
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Service</th>
							<th>Amount Agreed</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1</td>
							<td>Table cell</td>
							<td>Table cell</td>
							<td>Table cell</td>
						</tr>
						<tr>
							<td>2</td>
							<td>Table cell</td>
							<td>Table cell</td>
							<td>Table cell</td>
						</tr>
						<tr>
							<td>3</td>
							<td>Table cell</td>
							<td>Table cell</td>
							<td>Table cell</td>
						</tr>
					</tbody>
				</Table>


			</div>
		);
	}
}
AdminClientList.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	taxReturns: state.taxReturns

});

export default connect(
	mapStateToProps,
	{ getTaxReturns }
)(AdminClientList);
