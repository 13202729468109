import React from 'react'

import Form from 'react-bootstrap/Form'

// The Big headings
function TextField(props) {
	return (
		<div>
			<Form>
				<label>
					{props.heading}
					<input onChange={props.handleChange} type={props.type} name={props.name} placeholder={props.placeholder} />
				</label>
			</Form>

		</div>
	);
}
export default TextField;
