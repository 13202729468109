import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios";
import "../index.css";


import HomeSecondaryHeader from "./HomeSecondaryHeader";
import back from '../Images/chevron-left.svg';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';



class ContactSection2 extends React.Component {

  constructor(props) {

		super(props);
		this.state = {
			contactName: "",
			contactEmail: "",
			contactMessage: "",
			emailResponse: "",
			emailBadResponse: "",


			contactNameLA: "",
			contactEmailLA: "",
			contactMessageLA: "",
			emailResponseLA: "",
			emailBadResponseLA: "",

      contactNameSF: "",
      contactEmailSF: "",
      contactMessageSF: "",
      emailResponseSF: "",
      emailBadResponseSF: "",

      contactNameSD: "",
      contactEmailSD: "",
      contactMessageSD: "",
      emailResponseSD: "",
      emailBadResponseSD: ""


		}
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}


	sendMessage = (fromEmail) => {
		//console.log("in send a message");

		let email = {
			from: fromEmail,
			name: this.state.contactName,
			email: this.state.contactEmail,
			message: this.state.contactMessage
		}

		axios
			.post('/api/taxReturns/email', email)
			.then(res => {
				console.log(res)
				//this.props.history.push("/EmailSuccesful")

				this.setState({
					emailResponse: "Email Sent!"
				});
				window.location.href = "/MessageConfirmation";


			}
			)
			.catch(err => {
				console.log(err)

				this.setState({
					emailBadResponse: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
				});
			}

			);

		//this.props.email(email);


	}







	sendMessageLA = () => {
		//console.log("in send a message");

		let email = {
			name: this.state.contactNameLA,
			email: this.state.contactEmailLA,
			message: this.state.contactMessageLA
		}

		axios
			.post('/api/taxReturns/emailla', email)
			.then(res => {
				console.log(res)
				//this.props.history.push("/EmailSuccesful")

				this.setState({
					contactNameLA: "",
					contactEmailLA: "",
					contactMessageLA: "",
					emailBadResponseLA: "",
					emailResponseLA: "Email Sent!"
				});
				window.location.href = "/MessageConfirmation";


			}
			)
			.catch(err => {
				console.log(err)

				this.setState({
					contactNameLA: "",
					contactEmailLA: "",
					contactMessageLA: "",
					emailResponseLA: "",
					emailBadResponseLA: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
				});
			}

			);

		//this.props.email(email);


	}


  sendMessageSF = () => {
    //console.log("in send a message");

    let email = {
      name: this.state.contactNameSF,
      email: this.state.contactEmailSF,
      message: this.state.contactMessageSF
    }

    axios
      .post('/api/taxReturns/emailsf', email)
      .then(res => {
        console.log(res)
        //this.props.history.push("/EmailSuccesful")

        this.setState({
          contactNameSF: "",
          contactEmailSF: "",
          contactMessageSF: "",
          emailBadResponseSF: "",
          emailResponseSF: "Email Sent!"
        });
				window.location.href = "/MessageConfirmation";




      }
      )
      .catch(err => {
        console.log(err)

        this.setState({
          contactNameSF: "",
          contactEmailSF: "",
          contactMessageSF: "",
          emailResponseSF: "",
          emailBadResponseSF: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
        });
      }

      );

    //this.props.email(email);


  }



  sendMessageSD = () => {
    //console.log("in send a message");

    let email = {
      name: this.state.contactNameSD,
      email: this.state.contactEmailSD,
      message: this.state.contactMessageSD
    }

    axios
      .post('/api/taxReturns/emailsd', email)
      .then(res => {
        console.log(res)
        //this.props.history.push("/EmailSuccesful")

        this.setState({
          contactNameSD: "",
          contactEmailSD: "",
          contactMessageSD: "",
          emailBadResponseSD: "",
          emailResponseSD: "Email Sent!"
        });
				window.location.href = "/MessageConfirmation";


      }
      )
      .catch(err => {
        console.log(err)

        this.setState({
          contactNameSD: "",
          contactEmailSD: "",
          contactMessageSD: "",
          emailResponseSD: "",
          emailBadResponseSD: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
        });
      }

      );

    //this.props.email(email);


  }



	componentWillReceiveProps(nextProps) {

		if (nextProps) {
			this.setState({
				emailResponse: nextProps
			});
		}

	}



  render () {
    return(

      <div className="contact-div-0">

      <HomeSecondaryHeader text="Get In Touch With Us" subText="Any questions?  Contact us today!" />

      <div className="">

          <div className="col">

          <div className="center-content-cpa">

            <button className="button-none" onClick={() => this.props.changeContactSection("Default")}>
              <div className="breadcrumb-div">
                <img className="back-button" src={back} alt="img" />
                <p className="cpa-detail-breadcrumb-2">Back</p>
              </div>
            </button>

            <br></br>
            <br></br>


          <Accordion allowMultipleExpanded={false}>

            <AccordionItem className="accordian">
              <AccordionItemHeading className="accordian-heading">
                <AccordionItemButton className="accordian-button">
									{this.props.destination}
 							</AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel className="accordian-text">
                <div className="contact-div-half">
                <div className="contact-div-1">
                {/* Add Calendly Widget */}

                {/* Add Phone Number & Email */}
                <span className="bold4">{this.props.destination}</span>
                <a href={this.props.telNumber} className="phone-text"> {this.props.number}</a>
                <a href={this.props.mailto} className="phone-text">{this.props.email}</a>

                {/* Add Address & Map */}
                <a href={this.props.mapLink} className="phone-text">{this.props.address}</a>
                <img className="contact-map2" src={this.props.map} alt="img"  />
                </div>

                <div className="contact-div-2">
                  <a className="calendly-widget" href={this.props.calendly1}>Schedule a Call</a>
                  <br></br>
                    <div>
                      <p className="form-label">NAME</p>
                      <input className="form-input" value={this.state.contactName} onChange={this.handleChange} type="text" name="contactName" placeholder="John Doe" />
                    </div>

                    <div>
                      <p className="form-label">EMAIL</p>
                      <input className="form-input" onChange={this.handleChange} value={this.state.contactEmail} type="email" name="contactEmail" placeholder="john@gmail.com" />
                    </div>

                    <div>
                      <p className="form-label">MESSAGE</p>
                      <textarea className="form-input" type="text" name="contactMessage" value={this.state.contactMessage} onChange={this.handleChange} placeholder="How can I help?" rows="20" cols="50"></textarea>
                    </div>

                    <button className="contact-button" onClick={() => this.sendMessage(this.props.email)}>Submit</button>

                    <p className="emailResponse">{this.state.emailResponse}</p>
                    <p className="emailBadResponse">{this.state.emailBadResponse}</p>
                    </div>

                </div>

                <br></br>
                <br></br>

              </AccordionItemPanel>
            </AccordionItem>





            <AccordionItem className="accordian">
              <AccordionItemHeading className="accordian-heading">
                <AccordionItemButton className="accordian-button">
                  {this.props.destination2}
            </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordian-text">
              <div className="contact-div-half" style={{marginTop:'10px'}}>
                    <div className="contact-div-1" style={{fontWeight:600, fontStyle: "italic"}}>
                    In Texas, we are CPA firm BA Tax CPA, PLLC.  Agro Accounting CPA, P.C. is not a Texas CPA firm.
                      </div>
                      <div className="contact-div-2" style={{textWeight:600}}>
                    
                      </div>
                      </div>
                    <div className="contact-div-half">
                    
                <div className="contact-div-1">
                {/* Add Calendly Widget */}
                {/* Add Phone Number & Email */}
                <span className="bold4">{this.props.destination2}</span>
                <a href={this.props.telNumber2} className="phone-text"> {this.props.number2}</a>
                <a href={this.props.mailto2} className="phone-text">{this.props.email2}</a>

                {/* Add Address & Map */}
                <a href={this.props.mapLink2} className="phone-text">{this.props.address2}</a>
                <img className="contact-map2" src={this.props.map2} alt="img"  />
                </div>

                <div className="contact-div-2">
                  <a className="calendly-widget" href={this.props.calendly2}>Schedule a Call</a>
                  <br></br>
                    <div>
                      <p className="form-label">NAME</p>
                      <input className="form-input" value={this.state.contactName} onChange={this.handleChange} type="text" name="contactName" placeholder="John Doe" />
                    </div>

                    <div>
                      <p className="form-label">EMAIL</p>
                      <input className="form-input" onChange={this.handleChange} value={this.state.contactEmail} type="email" name="contactEmail" placeholder="john@gmail.com" />
                    </div>

                    <div>
                      <p className="form-label">MESSAGE</p>
                      <textarea className="form-input" type="text" name="contactMessage" value={this.state.contactMessage} onChange={this.handleChange} placeholder="How can I help?" rows="20" cols="50"></textarea>
                    </div>

                    <button className="contact-button" onClick={() => this.sendMessage(this.props.email2)}>Submit</button>

                    <p className="emailResponse">{this.state.emailResponse}</p>
                    <p className="emailBadResponse">{this.state.emailBadResponse}</p>
                    </div>


              </div>

                <br></br>
                <br></br>

              </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem className="accordian">
              <AccordionItemHeading className="accordian-heading">
                <AccordionItemButton className="accordian-button">
									{this.props.destination3}
            </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordian-text">

                <div className="contact-div-half">
                <div className="contact-div-1">
                {/* Add Calendly Widget */}
                {/* Add Phone Number & Email */}
                <span className="bold4">{this.props.destination3}</span>
                <a href={this.props.telNumber3} className="phone-text"> {this.props.number3}</a>
                <a href={this.props.mailto3} className="phone-text">{this.props.email3}</a>

                {/* Add Address & Map */}
                <a href={this.props.mapLink3} className="phone-text">{this.props.address3}</a>
                <img className="contact-map2" src={this.props.map3} alt="img"  />
                </div>

                <div className="contact-div-2">
                  <a className="calendly-widget" href={this.props.calendly3}>Schedule a Call</a>
                  <br></br>
                    <div>
                      <p className="form-label">NAME</p>
                      <input className="form-input" value={this.state.contactName} onChange={this.handleChange} type="text" name="contactName" placeholder="John Doe" />
                    </div>

                    <div>
                      <p className="form-label">EMAIL</p>
                      <input className="form-input" onChange={this.handleChange} value={this.state.contactEmail} type="email" name="contactEmail" placeholder="john@gmail.com" />
                    </div>

                    <div>
                      <p className="form-label">MESSAGE</p>
                      <textarea className="form-input" type="text" name="contactMessage" value={this.state.contactMessage} onChange={this.handleChange} placeholder="How can I help?" rows="20" cols="50"></textarea>
                    </div>

                    <button className="contact-button" onClick={() => this.sendMessage(this.props.email3)}>Submit</button>

                    <p className="emailResponse">{this.state.emailResponse}</p>
                    <p className="emailBadResponse">{this.state.emailBadResponse}</p>
                    </div>

              </div>

              </AccordionItemPanel>
            </AccordionItem>



						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									{this.props.destination4}
						</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">

								<div className="contact-div-half">
								<div className="contact-div-1">
								{/* Add Calendly Widget */}
								{/* Add Phone Number & Email */}
								<span className="bold4">{this.props.destination4}</span>
								<a href={this.props.telNumber4} className="phone-text"> {this.props.number4}</a>
								<a href={this.props.mailto4} className="phone-text">{this.props.email4}</a>

								{/* Add Address & Map */}
								<a href={this.props.mapLink4} className="phone-text">{this.props.address4}</a>
								<img className="contact-map2" src={this.props.map4} alt="img"  />
								</div>

								<div className="contact-div-2">
									<a className="calendly-widget" href={this.props.calendly4}>Schedule a Call</a>
									<br></br>
										<div>
											<p className="form-label">NAME</p>
											<input className="form-input" value={this.state.contactName} onChange={this.handleChange} type="text" name="contactName" placeholder="John Doe" />
										</div>

										<div>
											<p className="form-label">EMAIL</p>
											<input className="form-input" onChange={this.handleChange} value={this.state.contactEmail} type="email" name="contactEmail" placeholder="john@gmail.com" />
										</div>

										<div>
											<p className="form-label">MESSAGE</p>
											<textarea className="form-input" type="text" name="contactMessage" value={this.state.contactMessage} onChange={this.handleChange} placeholder="How can I help?" rows="20" cols="50"></textarea>
										</div>

										<button className="contact-button" onClick={() => this.sendMessage(this.props.email4)}>Submit</button>

										<p className="emailResponse">{this.state.emailResponse}</p>
										<p className="emailBadResponse">{this.state.emailBadResponse}</p>
										</div>

							</div>

							</AccordionItemPanel>
						</AccordionItem>











          </Accordion>
          <br></br>
          <br></br>
        </div>






































          </div>

      </div>

      </div>
    );

  }
}

export default ContactSection2;
