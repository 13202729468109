import React from 'react'
import "../index.css";

import img from '../Images/bitmoji-one-finger.png';

// SearchBar with a placeholder and search icon
function NoTaxReturnsYet(props) {
	return (
		<div className="welcome-div">
			<p className="welcome-text1">Hey There Team! There are no tax returns to show yet.</p>
			<img className="welcome-img" src={img} alt="img" />
		</div>
	);
}
export default NoTaxReturnsYet;
