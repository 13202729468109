import React from 'react'
import PropTypes from 'prop-types'

import MetaTags from 'react-meta-tags';
import PublicNav from "../components/PublicNav";
import Footer from "../components/Footer";

class Refund extends React.Component {
  render () {
    return(

      <div>

      <MetaTags>
        <title>Refund Policy - Agro Accounting CPA </title>
        <meta name="description" content="Agro Accounting CPA by Basil Agrocostea CPA is the one-stop destination for freelancers, artists and creative small businesses, for accounting service & help with tax preparation." />
      </MetaTags>
      <PublicNav />

      <div className="home-secondary-header-div">
        <p className="home-secondary-header">Refund Policy</p>
        <div class="underline-thick"></div>
      </div>

      <div className="center-content">
      <p className="terms-text">
      <br></br>

      Last updated March 27, 2020
      <br></br>
      <br></br>

      Thank you for hiring our services.  We hope you were happy with our service!  However, if you are not completely satisfied with us, for any reason, please contact us at:
      basil@agroaccounting.com.  Please see below for more information on our return policy.
      <br></br>
      <br></br>
      Please Note
      <br></br>
      ■ No refund is available for services performed, unless payment was submitted twice, three times, etc. by mistake.  If you are approved for a refund, then your refund will be processed and a credit will automatically be applied to your credit card or original method of payment, as soon as possible.
      <br></br>
      <br></br>

      QUESTIONS
      <br></br>


      If you have any questions concerning our return policy, please contact us at:
      <br></br>
      <b>(888) 247-6829 (AGRO TAX)</b>
      <br></br>
      <b>basil@agroaccounting.com</b>
      </p>


      <br></br>
      <br></br>

      <Footer />



      </div>

      </div>

    );
  }
}

export default Refund;
