import React from 'react'
import "../index.css";

import Container from 'react-bootstrap/Container'

// SearchBar with a placeholder and search icon
function PriceToolOtherServices1(props) {

	// if (props.service !== "Company Formation & Tax ID Issuance"){
	//   return null
	// }


	return (
		<div>
			<Container>

				{/* MAIN CONTENT */}

				<p className="expanded-heading2">Company Formation & Tax ID Issuance</p>

				<div className="box-shadow expanded-div">

					<p className="blue-disclaimer">Price quotes include all government fees and, for New York LLC’s, all advertising costs</p>

					<p className="expanded-text">
						New York LLC formation = <span className="bold">$1,000</span> <br></br>
						California LLC formation = <span className="bold">$600</span> <br></br>
						Corporation formation = <span className="bold">$600</span><br></br>
						Corporation formation with S-Corp election = <span className="bold">$750</span><br></br>
						Tax ID (EIN) issuance = <span className="bold">$50</span><br></br>
						Not-for-Profit formation + IRS 501(c)( 3 ) application = <span className="bold">$1,800-$2,500</span>
    </p>

					<p className="gold-disclaimer">Most clients at Agro Accounting CPA pay toward the lower end of the range!</p>

				</div>
			</Container>

			<div className="pricetool-other-footer footer">
				<button className="button-outlined" onClick={() => props.handleStep("Select a Service")}>
					Back
    </button>

				<button className="button-filled" onClick={() => props.handleStep("Company Formation & Tax ID Issuance Modal1")}>
					Next
    </button>
			</div>


		</div>
	);
}
export default PriceToolOtherServices1;
