import React from 'react'
import chevronDown from '../Images/chevron-down.png'; // Tell Webpack this JS file uses this image

import "../index.css";

function PriceRangeCompact(props) {
	return (
		<div className="" >

			<div className="">
				<p className="price-range-compact-heading">{props.heading}</p>
				<p className="price-range-compact-text">{props.text}</p>
			</div>

			<div>
				<img src={chevronDown} alt="img" />
				<p className="price-range-compact-seemore">See More</p>
			</div>


		</div>
	);
}
export default PriceRangeCompact;
