import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import PropTypes from 'prop-types'
import "../index.css";

import axios from "axios";

import { connect } from "react-redux";
import { sendStripePayment } from '../actions/taxReturnActions';



// This is for the Client Portal
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeMessagePositive:"",
      stripeMessageNegative:""
    }
    this.submit = this.submit.bind(this);
  }


    static propTypes = {
    sendStripePayment: PropTypes.func.isRequired
    };

  async submit(ev) {
    let {token} = await this.props.stripe.createToken({name: this.props.name});

    let item = {
      taxReturnId: this.props.taxReturnId,
      token:token,
      amount:this.props.amount*100
    }

    //this.props.sendStripePayment(item);

    axios
      .post('/api/taxReturns/sendStripePayment', item)
      .then(
        this.setState({
          stripeMessagePositive: "Payment Complete!",
          stripeMessageNegative: ""
        })
      )
      .catch(err => {
        this.setState({
          stripeMessagePositive: "",
          stripeMessageNegative: "There was an error proccessing your payment. Please contact us at basil@agroaccounting.com"
        })
        console.log(err)
      }
      );

    // let response = await fetch("/charge", {
    //   method: "POST",
    //   headers: {"Content-Type": "text/plain"},
    //   body: token.id
    // });
    //
    // if (response.ok) console.log("Purchase Complete!")
  }

   createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'ProductSansRegular, Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

  render() {

    let paymentForm

    if(this.props.isAmountPayedByClient == false){

      paymentForm = <div className="checkout">

        <p className="stripe-heading">Please complete payment to download your completed tax return <span className="blue-text"></span></p>
        <p className="normal-text"></p>

        <CardElement
          {...this.createOptions()}
           />
         <button className="stripe-button" onClick={this.submit}>Pay - ${this.props.amount}</button>
         <p className="normal-text">Currency in US Dollars</p>


      </div>

    }

    if(this.state.stripeMessagePositive === "Payment Complete!" || this.props.isAmountPayedByClient == true){
      paymentForm = <p className="emailResponse">Thank You for paying! We will upload your filed tax return soon.</p>
    }

    if(this.state.stripeMessageNegative === "There was an error proccessing your payment. Please contact us at basil@agroaccounting.com"){
      paymentForm = <p className="emailBadResponse">{this.state.stripeMessageNegative}</p>
    }

    return (
      <div>

        {paymentForm}

      </div>

    );
  }
}

//export default injectStripe(CheckoutForm);




const mapStateToProps = state => ({
  taxReturns: state.taxReturns,

});
export default connect(
  mapStateToProps,
  { sendStripePayment }
)(injectStripe(CheckoutForm));
