import React from 'react'
import MetaTags from 'react-meta-tags';

import PublicNav from "../components/PublicNav";

import Disclaimer2 from "../components/Disclaimer2";
import Footer from "../components/Footer";


import back from '../Images/chevron-left.svg';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

class WorkingWithUs extends React.Component {
	render() {
		return (
			<div className="cpa-free-guidance-page">
				<MetaTags>
					<title>Tax Preparation Guide for Artists, Entrepreneurs & Freelance Workers</title>
					<meta name="description" content="Work with Agro Accounting CPA, the Certified Public Accounting firm providing income tax preparation & dedicated support to freelancers & artists." />
				</MetaTags>
				<PublicNav />
				<div className="center-content-disclaimer">
					<Disclaimer2 />
				</div>

				<div className="center-content-cpa">

					<a href="/cpa-free-guidance">
						<div className="breadcrumb-div">
							<img className="back-button" src={back} alt="img" />
							<p className="cpa-detail-breadcrumb">Topic Categories</p>
						</div>
					</a>

					<p className="cpa-detail-heading">Working With Us</p>

					<Accordion
						allowMultipleExpanded={true}
						preExpanded="first" >

						<AccordionItem uuid="first" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What are the services you offer?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Please visit the redesigned and updated Services Offered section of our website.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Can you tell me about working with you and about your onboarding process or procedure?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Dear Accounting & Tax Services Shopper, over the last 9 years we have helped hundreds of -possibly over one thousand- freelancers, small business owners, creative individuals and companies, and startups: all just like you.  You are our bread and butter, our core client base.  Along the way, we have identified those questions and situations that repeatedly come up to us.  For this reason, we have developed this A.I. supported FAQ page and PriceTool (user experience portal).
									The age of Siri, Cortana and artificial intelligence is upon us.  In order to efficiently yet emphatically answer all of our client and would-be client questions, we employ it.  Rest assured, however, that every word you read on this webpage was methodically chosen by us.  The advice you read here is all professional and time-tested.  So, won’t you please give our online interface a try?
									Take 10 or so minutes, at your schedule’s convenience, to browse our A.I. supported FAQ page.  Then, please interact with our PriceTool.  It is our Free, Confidential, Non-Committal and Time-Saving tax preparation guide.  Use our professional guide to get you tax preparation ready, whether you hire us or any other tax preparer.  Let’s start here!
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What information do you need from me to prepare my income tax return?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Please go through our PriceTool to thoroughly answer your question.  It is our Free, Confidential, Non-Committal and Time-Saving tax preparation guide.  Along the way, it will ask you for your necessary information input.  Use our professional guide to get you tax preparation ready, whether you hire us or any other tax preparer!  Even so, to give you some direction:
        For the tax return’s year, we will need to see:  1) All of the year-end tax forms you have received;  2) Your business, freelance and/or rental income and expenses, gathered and presented per our guidance on this A.I. supported FAQ page;  3) Your health insurance information (months covered and total amount paid) or Form 1095, if any;  4) The last tax returns you filed, Federal and State; and  5) Your driver’s license or State ID card, front and back.  Please use our secure, encrypted document exchange portal to send us your private information.</p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									When should I get you my tax documents and information for my tax return’s preparation?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									The ideal and best time would be in the month of February.  The later you get it to us during the tax filing season, the busier we will be: which means, the less time we will have to answer your every question in great detail.  Also, if you get us your tax documents and information a week or less before the deadline, then we might not be able to file your tax return on time.  If we do, then we may charge you a $50 premium for the last-minute filing effort undertaken for you.
        </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									How much will you charge me to prepare my income tax return?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									The answer depends on how involved your financial situation is.  Some people have a straightforward, Form W-2 tax filing that takes under an hour to prepare.  Others have, for example, multiple business and investment income sources; file jointly with their spouse; moved from one state to another during the year; etc.  Clearly, one price does not fit all!  So:
									Please go through our PriceTool to thoroughly answer your question.  It is our Free, Confidential, Non-Committal and Time-Saving tax preparation guide.  Along the way, it will ask you for your necessary information input.  Use our professional guide to get you tax preparation ready, whether you hire us or any other tax preparer!
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What is your turnaround time to prepare my income tax return?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									During the busy tax season (Feb 1 - Apr 15), we typically ask for a 10-day turnaround time.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Are you taking on/ do you have room for new clients?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Of course, always!  We work extensively on our staff development and on our workflow systems in order to continually grow without sacrificing quality of service.
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Would I be able to schedule a phone call or in-person meeting with you?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Absolutely!  First, please take 10 or so minutes, at your schedule’s convenience, to browse our A.I. supported FAQ page.  Then, please interact with our PriceTool.  It is our Free, Confidential, Non-Committal and Time-Saving tax preparation guide.  Over the years, we have identified those questions and situations that repeatedly come up to us.  So, we have developed these online tools to efficiently yet emphatically answer all of our client and would-be client questions.
            Once you have familiarized yourself with our process and absorbed that portion of our free, CPA advice you find relevant to you, please book a phone call or in-person meeting here: <a href="https://calendly.com/agroaccounting-east">Calendly</a>
								</p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Do you offer any discount or incentive for referring clients to you?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Yes!  For any existing client:  If you refer us to 3 individuals in a similar tax position as you, your tax preparation is Free.  If you are a business and refer us to 4 businesses in a similar tax position as you, your annual accounting & tax service is Free.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									My financial and tax situation is a mess!  Do you still think you can help me?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Believe it or not, your financial or tax situation is not a mess!  It may be complex, but not complicated.  What you want to do is: First, take a deep breath and pat yourself on the back for taking this first step to action.  That said, we will get through this together, one step at a time.  Please understand you will have to invest your share of time, which may be significant, to get your information tax preparation ready.  If you have multiple years of back taxes to file, then approach each year independently from the other: gather and organize your information by year.  If you have multiple moving parts to every tax return’s year, then tackle each activity of your tax return’s year separately, one at a time.  “Rome was not built in one day.”
									Please follow our PriceTool for more guidance and information.  It is our Free, Confidential, Non-Committal and Time-Saving tax preparation guide.  Also be sure to browse our Income & Expenses section, plus other tips, on this A.I. supported FAQ page.
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What if I don’t hire you: what happens to my private information, which I had shared with you through your PriceTool?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									We will not use or share your information in absolutely any way that you don’t allow us to.  If you don’t hire us, then we will discard those documents you had shared with us.  We are a New York licensed CPA firm -license #117743- and we fully abide by our State’s and profession’s code of ethics.  You can verify our license here: <a href="http://www.op.nysed.gov/opsearches.htm">http://www.op.nysed.gov/opsearches.htm</a> (bottom of page)
            </p>
							</AccordionItemPanel>
						</AccordionItem>


					</Accordion>

					<Footer />

				</div>
			</div>


		);

	}
}

export default WorkingWithUs;
