import React from 'react'
import "../index.css";


function Disclaimer7(props) {
	return (
		<div className="disclaimer7">
			<p className="disclaimer7-bold">Hey There!</p>
			<p className="disclaimer7-text">Compare to a midtown Manhattan CPA with the same level of experience, who charges 2 or 3 times more!</p>
		</div>
	);
}
export default Disclaimer7;
