import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios";
import "../index.css";


import HomeSecondaryHeader from "./HomeSecondaryHeader";
import Footer from "../components/Footer";

import back from '../Images/chevron-left.svg';


class ContactSection extends React.Component {

  constructor(props) {

		super(props);
		this.state = {
			contactName: "",
			contactEmail: "",
			contactMessage: "",
			emailResponse: "",
			emailBadResponse: ""


		}
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	sendMessage = () => {
		//console.log("in send a message");

		let email = {
			name: this.state.contactName,
			email: this.state.contactEmail,
			message: this.state.contactMessage
		}

		axios
			.post('/api/taxReturns/emailnyc', email)
			.then(res => {
				console.log(res)
				//this.props.history.push("/EmailSuccesful")

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailBadResponse: "",
					emailResponse: "Email Sent!"
				});
        window.location.href = "/MessageConfirmation";





			}
			)
			.catch(err => {
				console.log(err)

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailResponse: "",
					emailBadResponse: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
				});
			}

			);

		//this.props.email(email);


	}

	componentWillReceiveProps(nextProps) {

		if (nextProps) {
			this.setState({
				emailResponse: nextProps
			});
		}

	}



  render () {
    return(

      <div className="contact-div-0">

      <HomeSecondaryHeader text="Drop Us a Line" subText="Any questions?  Contact us today!" />

      <div className="contact-div-half">

          <div className="contact-div-1">

          <button className="button-none" onClick={() => this.props.changeContactSection("Default")}>
            <div className="breadcrumb-div">
              <img className="back-button" src={back} alt="img" />
              <p className="cpa-detail-breadcrumb-2">Back</p>
            </div>
          </button>
          <br></br>
          {/* Add Calendly Widget */}
          <a className="calendly-widget" href="https://calendly.com/agroaccounting-east">Schedule a Call</a>

          {/* Add Phone Number & Email */}
          <a href={this.props.telNumber} className="phone-text">{this.props.number}</a>
          <a href={this.props.mailto} className="phone-text">{this.props.email}</a>

          {/* Add Address & Map */}
          <a href={this.props.mapLink} className="phone-text">{this.props.address}</a>
          <img className="contact-map2" src={this.props.map} alt="img"   />
          <br></br>
          <br></br>
          </div>

          <div className="contact-div-2">
              <div>
                <p className="form-label">NAME</p>
                <input className="form-input" value={this.state.contactName} onChange={this.handleChange} type="text" name="contactName" placeholder="John Doe" />
              </div>

              <div>
                <p className="form-label">EMAIL</p>
                <input className="form-input" onChange={this.handleChange} value={this.state.contactEmail} type="email" name="contactEmail" placeholder="john@gmail.com" />
              </div>

              <div>
                <p className="form-label">MESSAGE</p>
                <textarea className="form-input" type="text" name="contactMessage" value={this.state.contactMessage} onChange={this.handleChange} placeholder="How can I help?" rows="20" cols="50"></textarea>
              </div>

              <button className="contact-button" onClick={() => this.sendMessage()}>Submit</button>

              <p className="emailResponse">{this.state.emailResponse}</p>
              <p className="emailBadResponse">{this.state.emailBadResponse}</p>
              </div>
      </div>

      <Footer />
      </div>
    );

  }
}

export default ContactSection;
