import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import PropTypes from 'prop-types'
import "../index.css";

import axios from "axios";

import { connect } from "react-redux";
import { sendStripePayment } from '../actions/taxReturnActions';



// Payment Page
class CheckoutForm2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stripeMessagePositive: "",
			hide: false
		}
		this.submit2 = this.submit2.bind(this);
	}


	static propTypes = {
		sendStripePayment: PropTypes.func.isRequired
	};

	submit1 = () => {
			console.log("Here in Submit 1")

			this.setState((state, props) => {
	  	return {hide: true};
			});

			this.submit2();
	}

	async submit2(ev) {
		let { token } = await this.props.stripe.createToken({ name: this.props.name });

		let item = {
			taxReturnId: this.props.taxReturnId,
			token: token,
			amount: this.props.amount * 100
		}

		//this.props.sendStripePayment(item);

		axios
			.post('/api/taxReturns/sendStripePayment2', item)
			.then(
				this.setState({
					stripeMessagePositive: "Payment Complete!"
				})
			)
			.catch(err => console.log(err)
			);

		// let response = await fetch("/charge", {
		//   method: "POST",
		//   headers: {"Content-Type": "text/plain"},
		//   body: token.id
		// });
		//
		// if (response.ok) console.log("Purchase Complete!")
	}

	createOptions = () => {
		return {
			style: {
				base: {
					fontSize: '16px',
					color: '#424770',
					fontFamily: 'ProductSansRegular, Open Sans, sans-serif',
					letterSpacing: '0.025em',
					'::placeholder': {
						color: '#aab7c4',
					},
				},
				invalid: {
					color: '#c23d4b',
				},
			}
		}
	};

	render() {
		console.log(this.state);
		let button = <p></p>;
		if (this.state.stripeMessagePositive === "Payment Complete!") {
			button = <a className="button-filled-home" href="/">Return Home</a>
		}
		return (
			<div className="checkout">
				<p className="stripe-heading"> <span className="blue-text"></span></p>
				<p className="normal-text"></p>

				<CardElement
					{...this.createOptions()}
				/>
			<button className={this.state.hide == true ? "hide" : "stripe-button"} onClick={this.submit1}>Pay - ${this.props.amount}</button>
				<p className="normal-text">Currency in US Dollars</p>
			<p className="emailResponse">{this.state.stripeMessagePositive}</p>
				<br></br>
				<br></br>
				<a href="https://www.paypal.me/BasilA" className={this.state.hide == true ? "hide" : "bold3"}>Pay us through Paypal</a>
				{button}

			</div>
		);
	}
}

//export default injectStripe(CheckoutForm);




const mapStateToProps = state => ({
	taxReturns: state.taxReturns,

});
export default connect(
	mapStateToProps,
	{ sendStripePayment }
)(injectStripe(CheckoutForm2));
