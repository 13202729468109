import React from 'react'
import "../index.css";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { getTaxReturnsByEmail, updateTaxReturnAcceptOffer, updateTaxReturnFiles, updateSheetsLink } from '../actions/taxReturnActions';
import { logoutUser } from "../actions/authActions";
import timeline from '../Images/timeline-complete.png'; // Tell Webpack this JS file uses this image
import confirmation from '../Images/confirmation.png'; // Tell Webpack this JS file uses this image
import Disclaimer12 from "../components/Disclaimer12";
import LastFileManager from "../components/LastFileManager";


import img from '../Images/bitmoji-one-finger.png';


class Confirmation2 extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			link: null,
			item: null,
			taxReturns: null
		}
	}

	static propTypes = {
		getTaxReturnsByEmail: PropTypes.func.isRequired
	};


	componentDidMount() {


	}


		render() {


			console.log(this.state);








	return (

		<div className=" center-col welcome-div">
			<br></br>
			<br></br>

			<img className="timeline" src={timeline} alt="img" />

				<div className="aligncenter">

			<p className="welcome-text1">Hello Dear Client</p>
			<p className="welcome-text2">Thank you for creating a new work order, to complete this order please upload your documents to the File Manager. Thanks! </p>

				<br></br>
				<br></br>

			<LastFileManager />
			<img className="welcome-img" src={img} alt="img" />

			</div>
		</div>
	);
}
}

Confirmation2.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	taxReturns: state.taxReturns,

});

export default connect(
	mapStateToProps,
	{ logoutUser, getTaxReturnsByEmail }
)(Confirmation2);
