import React from 'react'

import basil from '../Images/basil-disclaimer-one.png';



// SearchBar with a placeholder and search icon
function Disclaimer2(props) {
	return (
		<div className="disclaimer2-div">

			<p className="disclaimer2-text">Please skim each topic category first, for general orientation.  After, read any FAQ(s) that interest you slowly and thoroughly.  Come back whenever you can!
          <span className="disclaimer-bold"> Bookmark Us</span>
			</p>
			<img className="disclaimer2-img" src={basil} alt="img" />


		</div>
	);
}
export default Disclaimer2;
