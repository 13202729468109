import React from 'react'
import "../index.css";

import Disclaimer8 from "../components/Disclaimer8";
import Container from 'react-bootstrap/Container';

// SearchBar with a placeholder and search icon
function PriceToolOtherServices4(props) {

	// if (props.service !== "Foreign Bank Account Report (FBAR) Price Quote"){
	//   return null
	// }


	return (
		<div>


			{/* MAIN CONTENT */}
			<Container>
				<p className="steps-heading">Foreign Bank Account Report (FBAR) Price Quote</p>

				<div className="box-shadow expanded-div">

					<p className="expanded-text text-center"><span className="bold">$50 for 1-2</span> foreign bank accounts with $10,000 or more</p>
					<Disclaimer8 />

				</div>
			</Container>

			<div className="pricetool-other-footer footer">
				<button className="button-outlined" onClick={() => props.handleStep("Select a Service")}>
					Back
      </button>

				<button className="button-filled" onClick={() => props.handleStep("International/ Multinational Income Modal2")}>
					Next
      </button>
			</div>


		</div>
	);
}
export default PriceToolOtherServices4;
