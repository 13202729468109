import React from 'react'
import PropTypes from 'prop-types'

class NearestCitySac extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      city:"",
      contactName: "",
      contactEmail: "",
      contactMessage: "",
      emailResponse: "",
      emailBadResponse: "",

			locationInactive: false,


      userLat: "",
      userLong: "",
      closestCity:"",

      cityData: [

        {
          name: "New York City",
          lat:40.712776,
          long:-74.005974,
        },
        {
          name: "Philadelphia",
          lat:39.952583,
          long:-75.165222,
        },
        {
          name: "Miami",
          lat:25.77427,
          long:-80.19366,
        },
        {
          name: "Chicago",
          lat:41.878113,
          long:-87.629799,
        },
        {
          name: "Austin TX",
          lat:30.267153,
          long:-97.743057,
        },
        {
          name: "Twin Cities MN",
          lat:44.977489,
          long:-93.264374,
        },
        {
          name: "Denver",
          lat:39.742043,
          long:-104.991531,
        },
        {
          name: "Los Angeles",
          lat:34.052235,
          long:-118.243683,
        },
        {
          name: "San Francisco Bay Area",
          lat:37.774929,
          long:-122.419418,
        },
        {
          name: "San Diego",
          lat:32.715736,
          long:-117.161087,
        },
        {
          name: "Portland",
          lat:45.512230,
          long:-122.658722,
        },
        {
          name: "Seattle",
          lat:47.60621,
          long:-122.33207,
        },

      ]
    }
  }


  componentDidMount() {
    if ("geolocation" in navigator) {
      //console.log("Available");
    } else {
      console.log("Not Available");
    }

    // Get the location of the user
    this.getLocation();

    // Get the closest city from the user
    //this.getClosestCity();
  }





    //Get location of current user
    getLocation = () => {
    navigator.geolocation.getCurrentPosition(

    position => {

    let distances = [];
    let sorted;
    let link;

    // Get the distance for every city from the user and add to array
    this.state.cityData.forEach(city => {
    distances.push( this.getDistance(position.coords.latitude,
                                     position.coords.longitude,
                                     city.lat,
                                     city.long,
                                     city.name));

     //Sorted by distance
     sorted = distances.sort(function(a, b){ return a.distanceFromUser-b.distanceFromUser});
     //console.log(sorted);

     // Get the closest city
  	 this.setState({
  		 closestCity: sorted[0].name
  	 });

   })

  },
    error => {
     console.error("Error Code = " + error.code + " - " + error.message);

     this.setState({
        locationInactive: true
      });

    }

  )}





    // Get the distance between user and a particular city
     getDistance = (lat1, lon1, lat2, lon2, city) => {

       //console.log(lat1, lon1, lat2, lon2, city);
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344

      return {name: city, distanceFromUser:dist};
  }








  render () {

    let link = "https://calendly.com/agroaccounting-east222";

    if(this.state.closestCity == "New York City" || this.state.closestCity == "Philadelphia"){

      link = "https://calendly.com/agroaccounting-east";

    }else if(this.state.closestCity == "Chicago" || this.state.closestCity == "Austin TX" || this.state.closestCity == "Twin Cities MN" ){

        link = "https://calendly.com/agroaccounting-central";

    }else if(this.state.closestCity == "Los Angeles" || this.state.closestCity == "San Francisco Bay Area" || this.state.closestCity == "San Diego" || this.state.closestCity == "Portland"){

        link = "https://calendly.com/agroaccounting-west";

      }


    console.log(this.state);


    return(
      <div>
          <a className="" href={link}>Schedule a Call</a>
      </div>
    );

  }

}


export default NearestCitySac;
