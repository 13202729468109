import React from 'react'
import MetaTags from 'react-meta-tags';

import PublicNav from "../components/PublicNav";

import Disclaimer2 from "../components/Disclaimer2";
import Footer from "../components/Footer";


import back from '../Images/chevron-left.svg';



import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

class BusinessOrganization extends React.Component {
	render() {
		return (
			<div className="cpa-free-guidance-page">
				<MetaTags>
					<title>Tax Preparation Guide, Planning & Reporting for Small Businesses</title>
					<meta name="description" content="We are CPA accountants for effective business organization; offering professional bookkeeping, accounting & tax services to individuals and small businesses." />
				</MetaTags>
				<PublicNav />
				<div className="center-content-disclaimer">

					<Disclaimer2 />
				</div>

				<div className="center-content-cpa">

					<a href="/cpa-free-guidance">
						<div className="breadcrumb-div">
							<img className="back-button" src={back} alt="img" />
							<p className="cpa-detail-breadcrumb">Topic Categories</p>
						</div>
					</a>

					<p className="cpa-detail-heading">Business Organization</p>


					<Accordion
						allowMultipleExpanded={true}
						preExpanded="first" >

						<AccordionItem uuid="first" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Which type of business entity should I form and operate under?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Dear Accounting & Tax Services Shopper, over the last 9 years we have helped hundreds of -possibly over one thousand- freelancers, small business owners, creative individuals and companies, and startups: all just like you.  You are our bread and butter, our core client base.  Along the way, we have identified those questions and situations that repeatedly come up to us.  For this reason, we have developed this A.I. supported FAQ page and PriceTool (user experience portal).
									The age of Siri, Cortana and artificial intelligence is upon us.  In order to efficiently yet emphatically answer all of our client and would-be client questions, we employ it.  Rest assured, however, that every word you read on this webpage was methodically chosen by us.  The advice you read here is all professional and time-tested.  So, won’t you please give our online interface a try?
									Take 10 or so minutes, at your schedule’s convenience, to browse our A.I. supported FAQ page.  Then, please interact with our PriceTool.  It is our Free, Confidential, Non-Committal and Time-Saving tax preparation guide.  Use our professional guide to get you tax preparation ready, whether you hire us or any other tax preparer.  Let’s start here!
              <br></br>
									Not-for-profits aside, this brings your business entity options down to either an LLC or to a sole proprietorship (or to a general partnership, if you have one or more partners).  Our advice to modest-income taxpayers -who make $40,000 or less per year- is for you to obtain just a DBA, “Doing Business As,” certificate.  You do so at your local county courthouse.  It could cost you ten (10) times less than forming an LLC!  At this early stage of your business’ existence, a DBA will provide you with the same professional presentation -with a business name- as an LLC.  Once you have a DBA certificate, we can obtain a tax ID for you, to isolate your DBA (sole proprietorship) from your Social Security Number.  You can then open a business bank account in your DBA’s name and tax ID.
									Both a DBA’s (sole proprietorship) and a single-member LLC’s financial activity are filed on their owner’s personal income tax return.  Please see “I am a small business owner, how do I file my tax return?  What type of tax return do I file?” for more information on that topic.
									What about the legal protection of an LLC or corporation?  First, an LLC, “Limited Liability Company,” has the same limited liability (legal) protection as a corporation.  Second, for modest-income taxpayers: unless you have major valuable assets such as a property, car or expensive jewelry, the limited liability may not mean much for you.  Nonetheless, we always suggest a business owner carry the appropriate amount of business insurance, as determined by your occupation, no matter your business structure.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Can you help [our newly formed company] get our books set up?  Can you help us build the right accounting framework?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Yes, absolutely.  We always seem to be asked this type of question during tax season though!  Dear Accounting & Tax Services Shopper, we are more than happy to serve in any accounting, bookkeeping referral, business consulting and/or tax preparation capacity you select us for.  Yet, we respectfully ask you to make this particular inquiry after April 15.  Our time and energy during tax season is focused on reporting for the year(s) just passed, not on the current calendar year.
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What is your professional opinion of QuickBooks, FreshBooks, Xero, Wave and other bookkeeping software?            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Throughout our FAQ page, you would see mention of Google Sheets spreadsheets over and over again to track income and expenses, but not of bookkeeping software.  For the record: we don’t object to the use of bookkeeping software.  However, through the years we have learned that many freelancers, sole proprietors and single-member LLC’s get into more trouble (attempting!) to use bookkeeping software than using a spreadsheet to track their financial activity.
            </p>
							</AccordionItemPanel>
						</AccordionItem>




					</Accordion>

					<Footer />

				</div>
			</div>


		);

	}
}

export default BusinessOrganization;
