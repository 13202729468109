import React from 'react'
import PropTypes from 'prop-types'

import MetaTags from 'react-meta-tags';
import axios from "axios";
import "../index.css";

import PublicNav from "../components/PublicNav";
import Footer from "../components/Footer";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

import logo from '../Images/logo.png';
import mapNY from '../Images/mapNY.png';
import mapPhilly from '../Images/mapPhilly.png';
import mapMI from '../Images/mapMI.png';
import mapDV from '../Images/mapDV.png';
import mapLA from '../Images/mapLA.png';
import mapSanFran from '../Images/mapSanFran.png';
import mapSanDiego from '../Images/mapSanDiego.png';
import mapAustin from '../Images/mapAustin.png';
import white from '../Images/white.png';


import ContactSection1 from "../components/ContactSection1";
import ContactSection2 from "../components/ContactSection2";
import ContactSection3 from "../components/ContactSection3";
import ContactSection4 from "../components/ContactSection4";

import ContactSectionDefault from "../components/ContactSectionDefault";

import CityContact from "../routes/cityContact";
import HomeSecondaryHeader from "../components/HomeSecondaryHeader";


class Contact extends React.Component {
  constructor(props) {

		super(props);
		this.state = {
			contactName: "",
			contactEmail: "",
			contactMessage: "",
			emailResponse: "",
			emailBadResponse: "",
      contactSection: "",
      locationInactive: false,
      userLat: 0,
      userLong: 0,

		}
	}

  changeContactSection = (location) => {
    this.setState({
      contactSection: location
    });
  }

  componentDidMount() {
    if ("geolocation" in navigator) {
      console.log("Available");
    } else {
      console.log("Not Available");
    }

    // Get the location of the user
    this.getLocation();

    // Get the closest city from the user
    //this.getClosestCity();
  }

  getLocation = () => {
  navigator.geolocation.getCurrentPosition(

  position => {

    this.setState({
      userLat: position.coords.latitude,
      userLong: position.coords.longitude
    });

  //	console.log(position.coords.latitude);
  //	console.log(position.coords.longitude);
  },
  error => {
   console.error("Error Code = " + error.code + " - " + error.message);

   this.setState({
      locationInactive: true
    });

  });

  }

  getCurrentLocation = () => {

		navigator.geolocation.getCurrentPosition(

		position => {

			this.setState({
				userLat: position.coords.latitude,
				userLong: position.coords.longitude
			});
		},

		error => {
		 console.error("Error Code = " + error.code + " - " + error.message);
		});

	}

  changeContactSection = (location) => {
    this.setState({
      contactSection: location
    });
  }


  render () {

    let message = <div className="contact-div-0 contact-message-height reallycoolcss">
               <div className="center-content-cpa">
               <Accordion preExpanded={["0"]} allowMultipleExpanded={false}>
               <AccordionItem uuid={"0"} className="accordian">
                 <AccordionItemHeading className="accordian-heading">
                   <AccordionItemButton className="accordian-button">
                 </AccordionItemButton>
                 </AccordionItemHeading>
                 <AccordionItemPanel className="accordian-text">
                   <div className="contact-div-half">
                     <p className="accordian-heading-contact">Working… please give us just another 2 seconds max :)</p>
                   </div>
                   <br></br>
                   <br></br>
                 </AccordionItemPanel>
               </AccordionItem>
               </Accordion>
          </div>
          </div>


    if(this.state.userLat != 0){

      message = <p></p>

    }

    let Contact;
    //console.log(this.state);

    {/*		{City Contact} */}
    Contact =	<div>
        {/*		{Contact} */}
        <div className="contact-div-0">

          <HomeSecondaryHeader text="Get In Touch With Us" subText="Any questions?  Contact us today!" />

          <div className="">

              <div className="col">

              <div className="center-content-cpa">

                <br></br>
                <br></br>

        <CityContact />

        </div>
            </div>


          </div>
          </div>
          </div>


    if(this.state.locationInactive){



    //if(this.state.contactSection == "Default"){
      Contact = <ContactSectionDefault changeContactSection= {this.changeContactSection} />
    //}

    if(this.state.contactSection == "East Coast"){
      Contact = <ContactSection2
                      fromEmail=""

                      telNumber="tel:+19295818381"
                      destination="New York City"
                      number="(929) 581-8381"
                      email="NYC@agroaccounting.com"
                      mailto="mailto: NYC@agroaccounting.com"
                      mapLink="https://goo.gl/maps/K3APmybWUWNehP1m7"
                      address="42 West Street Brooklyn, NY 11222"
                      map={mapNY}
                      changeContactSection= {this.changeContactSection}
                      calendly1="https://calendly.com/agroaccounting-east"



                      telNumber2="tel:+12679964499"
                      destination2="Philadelphia"
                      number2="(267) 996-4499"
                      email2="philly@agroaccounting.com"
                      mailto2="mailto: philly@agroaccounting.com"
                      mapLink2="https://goo.gl/maps/8bYzVKmpTUGFBuDh6"
                      address2="800 E Girard Ave Floor 1 Philadelphia, PA 19125"
                      calendly2="https://calendly.com/agroaccounting-east"
                      map2={mapPhilly}

                      telNumber3="tel:+13055495624"
											destination3="Miami"
											number3="(305) 549-5624"
											email3="miami@agroaccounting.com"
											mailto3="mailto: miami@agroaccounting.com"
											mapLink3=""
											address3="5231 SW 5th Terrace, Coral Gables, FL 33134, United States"
											map3={mapMI}
											calendly3="https://calendly.com/agroaccounting-east"



                      changeContactSection= {this.changeContactSection}
                    />
    }

    if(this.state.contactSection == "Middle US"){
      Contact =  <ContactSection3

                        telNumber="tel:+13129999750"
                        destination="Chicago"
                        number="(312) 999-9750 "
                        email="chicago@agroaccounting.com"
                        mailto="mailto: chicago@agroaccounting.com"
                        mapLink=""
                        address=""
                        map={white}
                        calendly1="https://calendly.com/agroaccounting-central"



                        telNumber2="tel:+15122983276"
                        destination2="Austin TX"
                        number2="(512) 298-3276"
                        email2="BATaxCPAPLLC@gmail.com"
                        mailto2="mailto: BATaxCPAPLLC@gmail.com"
                        mapLink2="https://goo.gl/maps/jPTiEvk3u7Eeohwq6"
                        address2="106 East 6th Street, Suite 900-163 Austin, TX 78701"
                        map2={mapAustin}
                        calendly2="https://calendly.com/agroaccounting-central"


                        telNumber3="tel:+16124245111"
                        destination3="Twin Cities MN"
                        number3="(612) 424-5111"
                        email3="minnie@agroaccounting.com"
                        mailto3="mailto: minnie@agroaccounting.com"
                        mapLink3=""
                        address3=""
                        map3={white}
                        calendly3="https://calendly.com/agroaccounting-central"

                        telNumber4="tel:+13035296538"
												destination4="Denver"
												number4="(303) 529-6538"
												email4="denver@agroaccounting.com"
												mailto4="mailto: denver@agroaccounting.com"
												mapLink4=""
												address4="1461 Logan St Suite A1, Denver, CO 80203, United States"
												map4={mapDV}
												calendly4="https://calendly.com/agroaccounting-rockies"


                        changeContactSection= {this.changeContactSection}
                      />
    }



    if(this.state.contactSection == "West Coast"){
      Contact =  <ContactSection4
                        fromEmail=""

                        telNumber="tel:+13233745399"
                        destination="Los Angeles"
                        number="(323) 374-5399"
                        email="DTLA@agroaccounting.com"
                        mailto="mailto: DTLA@agroaccounting.com"
                        mapLink="https://goo.gl/maps/PWB1zvC1LdTEGjZr7"
                        address="9415 Culver Boulevard, Suite 342, Culver City CA 90232"
                        map={mapLA}
                        calendly1="https://calendly.com/agroaccounting-west"

                        telNumber2="tel:+14152340115"
                        destination2="San Francisco Bay Area"
                        number2="(415) 234-0115"
                        email2="SanFran@agroaccounting.com"
                        mailto2="mailto: SanFran@agroaccounting.com"
                        mapLink2="https://goo.gl/maps/Snakt7MzPmLEAgr76"
                        address2="95 Third Street, 2nd Floor, San Francisco CA 94103"
                        map2={mapSanFran}
                        calendly2="https://calendly.com/agroaccounting-west"


                        telNumber3="tel:+16194313102"
                        destination3="San Diego"
                        number3="(619) 431-3102"
                        email3="SanDiego@agroaccounting.com"
                        mailto3="mailto: SanDiego@agroaccounting.com"
                        mapLink3="https://goo.gl/maps/7crDhwGFsEj7KE5G6"
                        address3="600 B Street, Suite 300 San Diego, CA 92101"
                        map3={mapSanDiego}
                        calendly3="https://calendly.com/agroaccounting-west"


                        telNumber4="tel:+19712666101"
                        destination4="Portland"
                        number4="(971) 266-6101"
                        email4="pdx@agroaccounting.com"
                        mailto4="mailto: pdx@agroaccounting.com"
                        mapLink4=""
                        address4=""
                        map4={white}
                        calendly4="https://calendly.com/agroaccounting-west"

                        telNumber5="tel:+12065905608"
												destination5="Seattle"
												number5="(206) 590-5608"
												email5="seattle@agroaccounting.com"
												mailto5="mailto: seattle@agroaccounting.com"
												mapLink5=""
												address5=""
												map5={white}
												calendly5="https://calendly.com/agroaccounting-west"


                        changeContactSection= {this.changeContactSection}
                      />
                    }
  }




    return(
      <div>

      <MetaTags>
        <title>Contact Us - Agro Accounting CPA </title>
        <meta name="description" content="Agro Accounting CPA by Basil Agrocostea CPA is the one-stop destination for freelancers, artists and creative small businesses, for accounting service & help with tax preparation." />
      </MetaTags>
      <PublicNav />

        {/* Contact Sections */}
				<div id="contact">
					{Contact}
					<br></br>
					<br></br>
					<br></br>
					{!Contact && message}
				</div>

      </div>
    );
  }
}

export default Contact;
