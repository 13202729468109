import React from 'react'
import MetaTags from 'react-meta-tags';
import PublicNav from "../components/PublicNav";

import Disclaimer2 from "../components/Disclaimer2";
import Footer from "../components/Footer";


import back from '../Images/chevron-left.svg';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

class AdditionalTaxQuestions extends React.Component {
	render() {
		return (
			<div className="cpa-free-guidance-page">
				<MetaTags>
					<title>Tax Preparation for Independent Contractors, Freelancers & Artists</title>
					<meta name="description" content="We offer quality, affordable, expert help preparing income taxes & answering tax questions; for startups, artists, freelancers, independent contractors & self-employed individuals." />
				</MetaTags>
				<PublicNav />
				<div className="center-content-disclaimer">

					<Disclaimer2 />
				</div>

				<div className="center-content-cpa">

					<a href="/cpa-free-guidance">
						<div className="breadcrumb-div">
							<img className="back-button" src={back} alt="img" />
							<p className="cpa-detail-breadcrumb">Topic Categories</p>
						</div>
					</a>

					<p className="cpa-detail-heading">Additional Tax Questions</p>


					<Accordion
						allowMultipleExpanded={true}
						preExpanded="first" >

						<AccordionItem uuid="first" className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What are the major tax filing deadlines?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Forms W-2 and 1099 must be issued to recipients by January 31 after the tax return’s year.  Corporation (C and S), and partnership tax returns are due by March 15.  Individual tax returns -including tax returns for freelancers and small business owners that are sole proprietors or single-member LLC’s- are due by April 15.  Lastly, not-for-profit information returns (Form 990 series) are due by May 15, after the tax return’s year.
									Filing for an extension of time grants you a six-month extension of time to file your return.  However, it does not stop late interest from building up, if you have a tax due as of your original tax filing deadline.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									How do I maximize my income tax refund?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									We actually won’t attempt to answer that question because it is the very purpose of the tax preparer’s profession to lawfully minimize a client’s taxes owed and, where possible, maximize a client’s tax refund.  Instead, we point out that a refund is primarily a reimbursement of taxes already paid and sometimes, according to specific and few government rules, a stipend or credit.  That said, we hope this leaves you with a more honest understanding of the tax refund system.
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									When will I receive my income tax refund?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									If we requested a bank direct deposit for you, then you should expect to receive both Federal and State income tax refunds in less than 30 days.  During the busy tax season, the IRS can sometimes deposit Federal tax refunds in just a few days!  You can always track your Federal refund’s status on the IRS website, irs.gov, and your State refund’s status on the State tax department website.  (Google it, your “State + tax department.”  In New York, it is tax.ny.gov.)
          </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What is the minimum amount of income I need to have in order to file an income tax return?
            </AccordionItemButton>

							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									It is approximately $12,000 (the standard deduction amount) IF your income was not business or freelance.  If your income was business or freelance, then self-employment tax “kicks in” when business or freelance income is $400 or more.
									Even if you did not earn this type of income, keep in mind your W-2(s) may have taxes over-withheld, which could trigger an income tax refund for you.  Also, low-income taxpayers may be eligible for refundable tax credits like the Earned Income Credit and Child Tax Credit.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Is it better for my spouse and I to file a married-joint or married-separate tax return?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									It depends.  Usually, but not always, it is better to file jointly, especially when the taxpayer and spouse have a large difference in level of income.  If you’re unsure which is the better scenario for you, then we will prepare a joint income tax return and run a filing jointly vs. separately calculation in order to determine if we should actually split that tax return into two.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									I am a small business owner, how do I file my tax return?  What type of tax return do I file?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									IF you are a Form 1099 recipient, freelancer, sole proprietor or have a single-member LLC, THEN we report your business income and expenses on Schedule C, which is an attachment to (part of) your personal income tax return.  Aside for a small LLC fee and maybe a NYC business tax return, you will not have to file additional, separate business tax returns.
									IF you have a partnership, an LLC with 2 or more members (partners), a regular (C) Corp or an S-Corp, THEN you will have separate business tax returns to file, in addition to your personal income tax return.
									Please follow our PriceTool for more guidance and information on this topic.  It is our Free, Confidential, Non-Committal and Time-Saving tax preparation guide.  Also be sure to browse our Income & Expenses tips on this A.I. supported FAQ page.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									How should I set money aside for estimated or projected taxes?  When do I need to make quarterly tax payments?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									For everyone that doesn’t have income taxes withheld from their pay -that does not receive a Form W-2 at year-end- you are responsible for setting money aside for taxes, and for prepaying them throughout the year before the tax filing season.  This said, the “estimated tax” amount you are responsible to prepay is just that, an estimate: it does not have to be exact.
									Roughly speaking, you determine the amount of taxes for you to set aside throughout the year by adding the total Federal and State taxes from your prior year income tax returns.  From the 2018 tax forms, these were:  1) Line 15 of IRS Form 1040, “Total tax” and  2) Line 62 on page 4 of New York Form IT-201, “Enter amount from line 61.”  (If your income amount or type has changed a lot from year to year, or if you also have salaried, W-2 income, then this simplified method may not work well for you.)
									What we do for all of our clients, at no additional cost, is to prepare your next year estimated tax calculation with the preparation of your current year income tax return.  So, we would tell you how much money to set aside, the best way to do that, and when to make your estimated tax (pre)payments throughout the year before the tax filing season.
          </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Do I need to file taxes quarterly?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Unless you have payroll tax or sales tax responsibilities, you do not have a quarterly tax return to file.  However, you might have to pay your taxes throughout the year in 4 quarterly installments.  For more information on that topic, please check out “How should I set money aside for estimated or projected taxes?  When do I need to make quarterly tax payments?”
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									I have not filed an income tax return in several years.  What should I do, where do I start?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Your first step is to find out what the IRS knows about your non-filings and what demands, if any, it makes of you.  You’ll find this out by obtaining a Wages and Income Transcript and an Account Transcript from the IRS, for each of the years you had not filed an income tax return.
              To obtain these two kinds of transcripts (records) from the IRS, either: <br></br>
									1) Go to irs.gov to make the transcripts’ request online;  <br></br>
									2) Call the IRS at (800) 829-1040; or <br></br>
									3) Recommended: Google “IRS office location near me” and make an appointment with it, in order to receive your transcripts immediately in person.  Once you have obtained your IRS transcripts, send them to us for an initial assessment of your tax situation.  Please use our secure, encrypted document exchange portal to send us your private information.
            </p>
							</AccordionItemPanel>
						</AccordionItem>

						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									I know it’s late in the season, and I need to file for an extension of time.  How do I go about that?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Please Google “IRS Form 4868” and, for New York State residents, “New York Form IT-370.”  You should be able to fill out these tax forms for free and online.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									What is the difference between a Form W-4 and W-2; W-9 and 1099?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									Forms W-4 and W-9 are input or data collection forms.  A Form W-4 collects necessary personal information from a salaried person (someone who has taxes withheld from their pay) on behalf of their employer.  A Form W-9 collects same, necessary information from a freelancer or small business owner, where taxes were not withheld from their pay.
									Forms W-2 and 1099 are the output, income reporting forms you receive at year-end.  If you’re someone that does the hiring, then you issue such forms at year-end.  If you filled out a Form W-4 when you were hired, then you will receive a Form W-2 at year-end.  If you filled out a Form W-9 when your services were engaged, then you will receive a Form 1099 at year-end.
            </p>
							</AccordionItemPanel>
						</AccordionItem>


						<AccordionItem className="accordian">
							<AccordionItemHeading className="accordian-heading">
								<AccordionItemButton className="accordian-button">
									Do you facilitate Refund Anticipation Loans?
            </AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel className="accordian-text">
								<p>
									No.  Refund Anticipation Loans, “RAL’s,” are financial products offered by third-party (other) service providers for a fee and are brokered by tax preparers.  Most of the time, RAL’s take a big bite out of the taxpayer’s refund.  Additionally, they are prevalent in blue-collar and minority neighborhoods.  We don’t believe in making money off of people, especially working-class good people, where it is not needed.  So, we don’t offer RAL’s.
            </p>
							</AccordionItemPanel>
						</AccordionItem>




					</Accordion>

					<Footer />

				</div>
			</div>


		);

	}
}

export default AdditionalTaxQuestions;
