import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios";

import HomeSecondaryHeader from "../components/HomeSecondaryHeader";
import PriceRangeCompact from "../components/PriceRangeCompact";
import img1 from '../Images/ny.png';
import img2 from '../Images/ca4.png';
import img3 from '../Images/midwest.png';

import Footer from "../components/Footer";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class ContactSectionDefault extends React.Component {

  constructor(props) {

		super(props);
		this.state = {
			contactName: "",
			contactEmail: "",
			contactMessage: "",
			emailResponse: "",
			emailBadResponse: ""


		}
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	sendMessage = () => {
		//console.log("in send a message");

		let email = {
			name: this.state.contactName,
			email: this.state.contactEmail,
			message: this.state.contactMessage
		}

		axios
			.post('/api/taxReturns/email', email)
			.then(res => {
				console.log(res)
				//this.props.history.push("/EmailSuccesful")

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailBadResponse: "",
					emailResponse: "Email Sent!"
				});




			}
			)
			.catch(err => {
				console.log(err)

				this.setState({
					contactName: "",
					contactEmail: "",
					contactMessage: "",
					emailResponse: "",
					emailBadResponse: "Email Could not be delivered! Please check if all fields are filled and if your email is valid"
				});
			}

			);

		//this.props.email(email);


	}

	componentWillReceiveProps(nextProps) {

		if (nextProps) {
			this.setState({
				emailResponse: nextProps
			});
		}

	}



  render () {
    return(

      <div className="contact-div-0">


      <div style={{ display: this.props.shouldDisplay}}>
      <HomeSecondaryHeader text="Get in Touch with Us" />
      </div>

        <Container>
					<div className="row-contact">

            <button onClick={() => this.props.changeContactSection("East Coast")} className="free-guidance-category-div">
        			<a href={this.props.link}>
        				<div className="free-guidance-category-button">
        					<img className="free-guidance-category-img" src={img1} alt="img" />
        					<p className="select-location-heading">East Coast</p>
        				</div>
        			</a>
        		</button>

            <button onClick={() => this.props.changeContactSection("Middle US")} className="free-guidance-category-div">
              <a href={this.props.link}>
                <div className="free-guidance-category-button">
                  <img className="free-guidance-category-img" src={img3} alt="img" />
                  <p className="select-location-heading">Middle US</p>
                </div>
              </a>
            </button>


            <button onClick={() => this.props.changeContactSection("West Coast")} className="free-guidance-category-div">
              <a href={this.props.link}>
                <div className="free-guidance-category-button">
                  <img className="free-guidance-category-img" src={img2} alt="img" />
                  <p className="select-location-heading">West Coast</p>
                </div>
              </a>
            </button>

          </div>
      </Container>




      <Footer />

      </div>
    )

  }
}

export default ContactSectionDefault;
