import React from 'react'
import "../index.css";

import img from '../Images/Disclaimer4-img.png';


// SearchBar with a placeholder and search icon
function Disclaimer6(props) {
	return (
		<div className="d4-div">
			<p className="d4-text">Need some guidance to gather and organize your rental property or Airbnb income and expenses? Check out our free CPA advice here   Check out our free CPA advice here:
      <br></br>
				<a className="d4-link" href="/cpa-free-guidance/airbnb-and-rental-property"> Airbnb & Rental Property</a></p>
			<img className="d6-img" src={img} alt="img" />
		</div>
	);
}
export default Disclaimer6;
