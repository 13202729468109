import React from 'react'
import "../index.css";

import Container from 'react-bootstrap/Container'

// SearchBar with a placeholder and search icon
function PriceToolOtherServices5(props) {

	// if (props.service !== "Estimated Tax Calculation Price Quote"){
	//   return null
	// }


	return (
		<div>


			{/* MAIN CONTENT */}
			<Container>
				<p className="steps-heading">Estimated Tax Calculation Price Quote</p>
				<div className="box-shadow expanded-div">
					<p className="blue-disclaimer">Next year's estimated tax calculation is complimentary (free!) when you also hire us to prepare your individual or business tax return.</p>
					<p className="expanded-text text-center">Estimated Tax Calculation for Non Tax Preparation Clients: <span className="bold">$150-$200</span> </p>
				</div>
			</Container>


			<div className="pricetool-other-footer footer">
				<button className="button-outlined" onClick={() => props.handleStep("Select a Service")}>
					Back
			    </button>
				<button className="button-filled" onClick={() => props.handleStep("Estimated Tax Calculation Modal1")}>
					Next
			    </button>
			</div>
		</div>
	);
}
export default PriceToolOtherServices5;
