import React from 'react'
import "../index.css";


function Disclaimer8(props) {
	return (
		<div className="disclaimer7">
			<p className="disclaimer7-text">Your FBAR attaches to your individual income tax return.  Please select "Tax Preparation" in Step 1 of this PriceTool for income tax return pricing.</p>
		</div>
	);
}
export default Disclaimer8;
