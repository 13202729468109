import React from "react";
import "../index.css";

import Back from '../Images/x-circle-grey.png';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

//How does our price range sound? Sound good / book appoiment
// True for step 3 of individul and business tax prep and Other Services (International/ Multinational Income)
class Modal2International extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			modalIndividual: true
		}
	}

	handleShow = () => {
		this.setState({
			modalIndividual: true
		});
	}

	handleHide = () => {
		this.setState({
			modalIndividual: false
		});
	}

	calendly = () => {
		window.location.href = "https://calendly.com/agroaccounting-east";

	}


	render() {
		return (
			<div>
				<Container>
					<div className="box-shadow">

						<div className="modal2-div">

							<button className="button-no-style" onClick={() => this.props.handleStep("International/ Multinational Income")}>
								<img src={Back} alt="img" />
							</button>
							<p className="steps-heading dark-blue">How does our price range sound?</p>

							<Row>
								<div className="modal1">

									<p className="normal-text-center-large">If something is really not clear or confusing, please set up an appointment on Calendly.</p>
									<a className="button-filled" href="https://calendly.com/agroaccounting-east">
										Schedule a Call
                  </a>

								</div>

								<div className="modal1">

									<p className="normal-text-center-large">Sounds good?  Awesome, let's get you started!</p>
									<button className="button-filled" onClick={() => this.props.handleStep("Select Tax Preparation")}>
										Get Started
                  </button>

								</div>
							</Row>
						</div>

					</div>
				</Container>
			</div>
		);
	}
}

export default Modal2International;
