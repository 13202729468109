import React, { Component } from 'react'
import { connect } from 'react-redux'
import renderHtml from 'react-render-html'
import { Button, ButtonGroup, FormGroup, ListGroup, ListGroupItem } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import filesize from 'filesize'

import { downloadAttachment, getMessage, reply } from '../../../actions/gmailActions'
import { getHeader } from '../../../actions/messageMethods'


function getFrom(string) {
	let Str = string;
	const s = Str.indexOf('<');
	const e = Str.indexOf('>') + 1;
	Str = Str.replace(Str.substring(s, e), "");
	return Str;
}

class MessageContent extends Component {

	constructor(props) {
		super(props);

		this.state = this.getInitialState();

		this.onReplyChange = this.onReplyChange.bind(this);
		this.onSendReply = this.onSendReply.bind(this);

		this.setSelectedId = this.setSelectedId.bind(this);
	}

	componentDidMount() {
		this.props.onRef(this);
		this.props.getMessage(this.props.id);
	}
	componentWillUnmount() {
		this.props.onRef(undefined)
	}

	getInitialState() {
		return {
			reply: '',
			attachments: [],
			dropzoneActive: false
		};
	}
	setSelectedId = (id) => {
		this.props.getMessage(id);
	}


	onDrop(files) {
		this.setState({ dropzoneActive: false });
		for (let i = 0; i < files.length; i++) {
			const reader = new FileReader();
			reader.onload = () => {
				this.setState({
					attachments: [
						...this.state.attachments,
						{
							name: files[i].name,
							size: files[i].size,
							type: files[i].type,
							blob: reader.result
						}
					]
				})
			};
			reader.readAsBinaryString(files[i]);
		}
	}

	removeAttachment(file) {
		this.setState({
			attachments: this.state.attachments.filter(item => item !== file)
		})
	}

	onReplyChange(e) {
		this.setState({ reply: e.target.value })
	}

	onSendReply(e) {
		e.preventDefault();
		const { message } = this.props;
		const { reply, attachments } = this.state;
		this.props.reply(message, reply, attachments);
		this.setState(this.getInitialState());
	}

	render() {
		//		let dropzoneRef;

		const dropzoneOverlayStyle = {
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			padding: '2.5em 0',
			background: 'rgba(0,0,0,0.5)',
			textAlign: 'center',
			color: '#fff'
		};
		return (
			<div className='message-content'>
				{this.props.isLoaded && (
					<div>
						<div>
							<div>
								<span className='message-content-email'>{getFrom(getHeader(this.props.message, 'From'))}  </span>
								<span>to </span>
								<span className='message-content-email'>{getHeader(this.props.message, 'To')}</span>

								<p>{getHeader(this.props.message, 'Date')} </p>
							</div>
							<div>
								<span className='message-content-subject'> {getHeader(this.props.message, 'Subject')}</span>
							</div>
							{this.props.message.payload.htmlBody === '' ? (
								<i>Пустое сообщение</i>
							) : (
									renderHtml(this.props.message.payload.htmlBody)
								)}

							{this.props.message.payload.attachments.length ? (
								<div>
									<hr />
									<ButtonGroup vertical>
										{this.props.message.payload.attachments.map((attachment, index) => (
											<a
												key={index}
												onClick={() => this.props.downloadAttachment(this.props.message.id, attachment)}
												style={{ cursor: 'pointer' }}
												href="#/"
											>
												Download
											{"  " + attachment.filename}
												({filesize(attachment.body.size)})
                        			</a>
										))}
									</ButtonGroup>
								</div>
							) :
								(
									null
								)}
						</div>
						<form onSubmit={this.onSendReply}>
							<FormGroup>
								<Dropzone
									disableClick
									style={{ position: "relative" }}
									onDrop={this.onDrop.bind(this)}
									onDragEnter={() => this.setState({ dropzoneActive: true })}
									onDragLeave={() => this.setState({ dropzoneActive: false })}
								>
									{({ getRootProps, getInputProps }) => (
										<div {...getRootProps({ className: 'dropzone' })}
											style={{ position: 'relative', maxWidth: '600px' }}>
											{this.state.dropzoneActive &&
												<div style={dropzoneOverlayStyle}>
													Drag files here to attach them to the letter ...
									  		</div>}
											<textarea
												placeholder='Write your reply here'
												name='message'
												value={this.state.reply}
												onChange={this.onReplyChange}
												rows={3}
												required
												className='message-input'
											/>
										</div>

									)}
								</Dropzone>

							</FormGroup>

							<ListGroup>
								{this.state.attachments.map((file, index) => (
									<ListGroupItem key={index} listItem={true}>
										{file.name} ({filesize(file.size)})
									<a
											onClick={() => this.removeAttachment(file)}
											style={{ cursor: 'pointer' }}
											href="#/"
										>
											Remove
									</a>
									</ListGroupItem>
								))}
							</ListGroup>


							<div style={{ maxWidth: '600px' }}>
								<Button
									type='submit'
									className='pull-right'
									bsStyle='primary'
									style={{ margin: 'auto', marginBottom: '20px', float: 'right', height: '35px', width: '105px', borderRadius: '20px', backgroundColor: '#4880FF' }}
								>
									Send
		 					 </Button>
							</div>
						</form>
					</div>
				)
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	message: state.gmailContent.message,
	isLoaded: !!state.gmailContent.message
});

const mapDispatchToProps = dispatch => ({
	getMessage: (id) => { dispatch(getMessage(id)) },
	downloadAttachment: (messageId, attachment) => { dispatch(downloadAttachment(messageId, attachment)) },
	reply: (messageToReply, replyMessage, attachments) => { dispatch(reply(messageToReply, replyMessage, attachments)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageContent);