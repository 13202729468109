import React from 'react'
// SearchBar with a placeholder and search icon

import NearestCitySac from "../locations/NearestCitySac.js"

function Disclaimer5(props) {
	return (
		<div className="d5-div">

			<p className="d4-text">
				As easy as 1-2-3!   will save you time no matter which accountant you hire!
				Trustworthy,   100% confidential,   no commitment until you hire us!
        <a href="http://www.op.nysed.gov/opsearches.htm">  Verify us NY CPA firm license #117743  </a>
				Our document portal is safe and encrypted
        Stuck? call us, we are here to help!    <a href="https://calendly.com/agroaccounting-east">  Schedule a Call</a>
			</p>

		</div>
	);
}
export default Disclaimer5;
