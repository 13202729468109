import React from 'react'
//import PropTypes from 'prop-types'

//import Form from 'react-bootstrap/Form'
//import FormControl from 'react-bootstrap/FormControl'
//import FormCheck from 'react-bootstrap/FormCheck'
//import Button from 'react-bootstrap/Button'

import StepsHeading from "../components/StepsHeading";
//import Step1Option from "../components/Step1Option";
import Disclaimer3 from "../components/Disclaimer3";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import serviceInactive from '../Images/service-inactive.png';

import timeline from '../Images/timeline-taxprep.png'; // Tell Webpack this JS file uses this image



// Step 2 - Are you a Individual or Business
function PriceToolStep2Current(props) {
	return (
		<div>
			<img className="timeline" src={timeline} alt="img" />
			<StepsHeading text="Are you an Individual, Business or Not-for-Profit?" />
			<div>
				<Container>

					<Row>
						<Col sm={5}>
							<button className="select-tax-prep-div" onClick={() => props.handleService("Upload Documents Individual")}>
								<Container>
									<p className="steps-heading-minor">Individual</p>

									<img src={serviceInactive} alt="img" />
									<p className="normal-text-grey">Includes Individuals/ Couples</p>
									<div>

										<p className="select-tax-prep-text">Freelancer</p>

										<p className="select-tax-prep-text">Independent Contractor</p>

										<p className="select-tax-prep-text">Small Business Owner (either a Sole Proprietor or Single-Member LLC)</p>

									</div>
								</Container>
							</button>
						</Col>

						<Col sm={5}>
							<button className="select-tax-prep-div" onClick={() => props.handleService("Upload Documents Business")}>
								<Container>
									<p className="steps-heading-minor">Business or Not-for-Profit</p>
									<img src={serviceInactive} alt="img" />
									<p className="normal-text-grey">Classifed as</p>

									<div>
										<p className="select-tax-prep-text">Regular (C) Corporation</p>

										<p className="select-tax-prep-text">Partnership or LLC with 2 or More Members/ Partners</p>

										<p className="select-tax-prep-text">S Corporation</p>

										<p className="select-tax-prep-text">Not-for-Profit</p>
									</div>

								</Container>
							</button>
						</Col>

						<Col sm={2}>
							<Disclaimer3 />
						</Col>
					</Row>
				</Container>
			</div>


			<div className="pricetool-footer footer">

				<a className="button-outlined" href="/Client/Dashboard">
					Back
      </a>

				<div>



					<button className={props.service === "Select Tax Preparation" ? "button-grey" : "button-filled"} disabled={props.service === "Select Tax Preparation" ? true : false} onClick={() => props.handleStep(props.service)}>
						{props.service === "Select Tax Preparation" ? "Select a type" : "Next"}
					</button>

				</div>

			</div>



		</div>
	);
}
export default PriceToolStep2Current;
