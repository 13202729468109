import React from 'react'

import basil from '../Images/basil-disclaimer-one.png';



// SearchBar with a placeholder and search icon
function Disclaimer1(props) {
	return (
		<div className="disclaimer2-div">

			<p className="disclaimer2-text">
				Here are the topic categories of our FAQ's database. We recommend you skim each topic category first, for general orientation. After, please read any FAQ(s) that interest you slowly and thoroughly.
          <span className="disclaimer-bold"> Bookmark Us</span>
			</p>
			<img className="disclaimer2-img" src={basil} alt="img" />
		</div>
	);
}
export default Disclaimer1;
