import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import { getTaxReturns } from '../actions/taxReturnActions';
import store from "../store";




class Dashboard extends Component {
	onLogoutClick = e => {
		e.preventDefault();
		this.props.logoutUser();
	};

	static propTypes = {
		getTaxReturns: PropTypes.func.isRequired
	};

	componentDidMount() {



		// If logged in and user navigates to Register page, should redirect them to their respectable dashboard
		if (!this.props.auth.isAuthenticated) {
			window.location.href = "/";
		}

		// If user is a client get all Tax Returns matching client by clientId
		if (this.props.auth.user.role === "Client") {
			console.log("This user is a client");
			window.location.href = "/Client/Dashboard";
		}

		if (this.props.auth.user.role === "Intern" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "Basil") {
			console.log("This user is from Agro Team Members");
			store.dispatch(getTaxReturns());
			window.location.href = "/Admin/Dashboard";


			// Get Tax Returns
			this.props.getTaxReturns();

		}



	}

	render() {
		//const { user } = this.props.auth;
		const { taxReturns } = this.props.taxReturns;
		console.log(taxReturns)


		return (
			<div style={{ height: "75vh" }} className="container valign-wrapper">


				<button onClick={this.onLogoutClick}>Logout</button>

			</div>
		);
	}
}

Dashboard.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	taxReturns: state.taxReturns,

});

export default connect(
	mapStateToProps,
	{ logoutUser, getTaxReturns }
)(Dashboard);
