import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

// SearchBar with a placeholder and search icon
function CPAFreeGuidanceDetailDropdown(props) {
	return (
		<div>

			<Dropdown>
				<Dropdown.Toggle id="dropdown-basic">
					{props.heading}
				</Dropdown.Toggle>

				<Dropdown.Menu>
					<p> {props.text1}
						<a href="/">{props.link}</a>
						{props.text2}
					</p>
				</Dropdown.Menu>
			</Dropdown>




		</div>
	);
}
export default CPAFreeGuidanceDetailDropdown;
