import React from 'react'


import logo from '../Images/logo.png';


function PublicNav(props) {
	return (
		<div className="public-nav">
			<a href="/">
				<img className="logo" src={logo} alt="logo" />
			</a>

			<div className="row">
				<a sm={4} className="nav-link" href="/Contact">Contact Us</a>
				<a sm={4} className="nav-link" href="/PriceTool">PriceTool</a>
				<a sm={4} className="nav-link" href="/cpa-free-guidance">CPA's Free Guidance</a>

				{/*  <NavLink to="/about" activeClassName="nav-link">About Us</NavLink>*/}
				<a sm={4} className="nav-link" href="/AboutUs">About Us</a>
				<a sm={4} className="nav-link" href="/Blog">Blog</a>

			</div>

			<div className="row">
				<form className="nav-link" name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="e77649c1-0718-4348-9456-50037b843ea4" /> <input type = "submit" value = "Make a Payment" /> </form>
				{/* <a className="nav-link" href="/Payment">Make a Payment</a>*/}
				<a sm={6} className="nav-link" href="/ClientSignIn">Sign In</a>
				<a sm={6} className="nav-link" href="/ClientSignUp">Sign Up</a>
			</div>

			<div className="row">
				<a href="tel:+18882476829" className="mobile-only phone-text-3">Toll-free: (888) 247-6829</a>
			</div>

		</div>
	);
}
export default PublicNav;
