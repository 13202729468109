import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios";

import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import logo from '../Images/logo.png';

import img from '../Images/bitmoji-one-finger.png';


class AdminUnAuthorized extends React.Component {
  render () {
    let { auth } = this.props;

    return(
      <div>

      <div className="admin-nav">
        <a className="client-nav-logo" href="/">
          <img className="logo" src={logo} alt="img" />
        </a>

        {/*<input placeholder="Client Name" type="text" name="search" onChange={this.handleChange} className="" value={this.state.search}/> */}

        <div className="admin-nav-detail">


          <div>
            <p className="admin-nav-name">{auth.user.name}</p>
            <p className="admin-nav-role">{auth.user.role}</p>
          </div>

          <button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>
        </div>
      </div>

      <div className="welcome-div">
        <p className="welcome-text1">Please request access from Basil to see database information</p>
      </div>




      </div>

    );

  }
}


AdminUnAuthorized.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(
	mapStateToProps,
	{ logoutUser }
)(AdminUnAuthorized);
