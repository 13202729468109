import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios";

import Footer from "../../components/Footer";
import PublicNav from "../../components/PublicNav";
import HomeSecondaryHeader from "../../components/HomeSecondaryHeader";


class BlogArticle extends React.Component {
  constructor(props) {
  super(props);
  this.state = {
  data:[]
  }
}

componentDidMount() {
  this.getBlogs();
}

getBlogs = () => {
  const { id } = this.props.match.params;


  axios
  .post('/api/blog/getBlogByID', {id: id})
  .then(res => {

    console.log(res.data.object)

    this.setState((state, props) => {
      return {
        data: res.data.object
      };
    });


  })
  .catch(err =>
    console.log("An Error has occurred")
  );
}


  render () {
    var dateFormat = require('dateformat');

    let data = this.state.data;
    console.log(data)
    let image;
    let content;
    if(data.thumbnail){
      image = <img className="blog-article-pic" src={data.thumbnail} />
    }
    return(
      <div className="blue-background">
          <PublicNav />
          <div  className="blog-article-page">
          <HomeSecondaryHeader text={data.title} />
          <br></br>
          <br></br>
          <div className="center-image">
          {image}
          <p></p>
          </div>
          <p className="blog-article-arthor">Author: Basil Agrocostea</p>
          <p className="blog-article-date">{dateFormat(data.created_at, "dddd, mmmm dS, yyyy")}</p>

          <div>
          <br></br>
          <br></br>
          <p className="blog-article-content" dangerouslySetInnerHTML={{ __html: data.content }}></p>
          </div>
          </div>
          <Footer />

      </div>
    );
  }
}

export default BlogArticle;
