export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_GOOGLE_USER = "SET_GOOGLE_USER";

export const GET_TAXRETURN = "GET_TAXRETURN";
export const ADD_TAXRETURN = "ADD_TAXRETURN";
export const UPDATE_TAXRETURN = "UPDATE_TAXRETURN";
export const TAXRETURN_LOADING = "TAXRETURN_LOADING";

export const GAPI_LOAD_REQUEST = "GAPI_LOAD_REQUEST";
export const GAPI_LOAD_SUCCESS = "GAPI_LOAD_SUCCESS";

export const AUTHORIZED = 'AUTHORIZED';
export const UNAUTHORIZED = 'UNAUTHORIZED';

export const GET_MESSAGE_LIST_REQUEST = 'GET_MESSAGE_LIST_REQUEST';
export const GET_MESSAGE_LIST_SUCCESS = 'GET_MESSAGE_LIST_SUCCESS';
export const FETCH_MESSAGE_LIST_REQUEST = 'FETCH_MESSAGE_LIST_REQUEST';
export const FETCH_MESSAGE_LIST_SUCCESS = 'FETCH_MESSAGE_LIST_SUCCESS';

export const GET_MESSAGE_REQUEST = 'GET_MESSAGE_REQUEST';
export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';