import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import axios from "axios";

import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

import NoTaxReturnsYet from "../components/NoTaxReturnsYet";

import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import { getTaxReturns } from '../actions/taxReturnActions';
import store from "../store";

import logo from '../Images/logo.png';
import folder from '../Images/folder.png';

import img from '../Images/bitmoji-one-finger.png';


class AdminDashboard extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			filter: "All",
			otherFilter: "",
			search: ""
		}
	}


	filter = (value) => {
		console.log(value);
		this.setState({
			filter: value
		})
	}

	otherFilterFunction = (value) => {
		console.log(value);
		this.setState({
			otherFilter: value
		})
	}

	static propTypes = {
		getTaxReturns: PropTypes.func.isRequired
	};

	onLogoutClick = () => {
		// e.preventDefault();
		this.props.logoutUser();
		window.location.href = "/";
	};


	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			window.location.href = "/";
		}

		if (this.props.auth.user.role === "Client") {
			window.location.href = "/Client/Dashboard";
		}

		// if (this.props.auth.user.employeeAccess === false){
		// 	window.location.href = "/Admin/Unauthorized";
		//
		// }

		this.props.getTaxReturns();
		console.log(store.getState())

	}


	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state)
	}

	save = () => {
		console.log("saved data")
	}

	archiveFile = (values) => {
		console.log(values);
	}


	render() {
		let { taxReturns } = this.props.taxReturns;
		console.log(taxReturns);
		console.log(this.state)
		let { auth } = this.props;
		let items = [];
		console.log(auth);
		let nonArchived = taxReturns.filter(item => item.archive != true);
		console.log(nonArchived);
		let archived = taxReturns.filter(item => item.archive == true);

		let individual = [];
		let business = [];
		let searchFilter = [];
		let filterClass;
		let otherFilterArray;
		let UnacceptedPriceQuote = [];


		// ALL TAX RETURNS
		if (this.state.filter == "All" && this.state.search == "") {
			items = nonArchived.map((object, index) => {
				if (object.isAmountAgreedByClient === true) {
					return <a href={"/AdminTaxReturn/" + object._id}>
						<div className="client-taxReturn-div" key={index}>
							<img className="admin-taxreturn-img" src={folder} alt="img" />
							<p className="admin-taxreturn-name" >{object.name}</p>
							<p className="admin-taxreturn-service" >{object.service}</p>
						</div>
					</a>
				}
			})
		}


		// INDIVIDUAL TAX RETURNS
		if (this.state.filter == "Individual") {
			individual = nonArchived.filter(item => item.service == 'Tax Preparation Individual')

			items = individual.map((object, index) => {
				if (this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate") {
					if (object.isAmountAgreedByClient === true) {
						return <a href={"/AdminTaxReturn/" + object._id}>
							<div className="client-taxReturn-div" key={index}>
								<img className="admin-taxreturn-img" src={folder} alt="img" />
								<p className="admin-taxreturn-name" >{object.name}</p>
								<p className="admin-taxreturn-service" >{object.service}</p>
							</div>
						</a>
					}
				}

				if (this.props.auth.user.role === "Basil" || this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "CPA/EA") {
					return <a href={"/AdminTaxReturn/" + object._id}>
						<div className="client-taxReturn-div" key={index}>
							<img className="admin-taxreturn-img" src={folder} alt="img" />
							<p className="admin-taxreturn-name" >{object.name}</p>
							<p className="admin-taxreturn-service" >{object.service}</p>
						</div>
					</a>
				}
				return null;
			})
		}


		// BUSINESS TAX RETURNS
		if (this.state.filter == "Business") {
			business = nonArchived.filter(item => item.service == 'Tax Preparation Business')

			items = business.map((object, index) => {
				if (this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate") {
					if (object.isAmountAgreedByClient === true) {
						return <a href={"/AdminTaxReturn/" + object._id}>
							<div className="client-taxReturn-div" key={index}>
								<img className="admin-taxreturn-img" src={folder} alt="img" />
								<p className="admin-taxreturn-name" >{object.name}</p>
								<p className="admin-taxreturn-service" >{object.service}</p>
							</div>
						</a>
					}
				}

				if (this.props.auth.user.role === "Basil" || this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "CPA/EA") {
					return <a href={"/AdminTaxReturn/" + object._id}>
						<div className="client-taxReturn-div" key={index}>
							<img className="admin-taxreturn-img" src={folder} alt="img" />
							<p className="admin-taxreturn-name" >{object.name}</p>
							<p className="admin-taxreturn-service" >{object.service}</p>
						</div>
					</a>
				}
				return null;
			})
		}


		// OUTSTANDING WORKORDERS NOT ACCEPTED PRICE QUOTE
		if (this.state.filter == "Unaccepted Price Quote") {
			UnacceptedPriceQuote = nonArchived.filter(item => item.isAmountAgreedByClient == false)

			items = UnacceptedPriceQuote.map((object, index) => {
				if (this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate") {
					if (object.isAmountAgreedByClient === true) {
						return <a href={"/AdminTaxReturn/" + object._id}>
							<div className="client-taxReturn-div" key={index}>
								<img className="admin-taxreturn-img" src={folder} alt="img" />
								<p className="admin-taxreturn-name" >{object.name}</p>
								<p className="admin-taxreturn-service" >{object.service}</p>
							</div>
						</a>
					}
				}

				if (this.props.auth.user.role === "Basil" || this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "CPA/EA") {
					return <a href={"/AdminTaxReturn/" + object._id} key={"parent_" + index}>
						<div className="client-taxReturn-div" key={index}>
							<img className="admin-taxreturn-img" src={folder} alt="img" />
							<p className="admin-taxreturn-name" >{object.name}</p>
							<p className="admin-taxreturn-service" >{object.service}</p>
						</div>
					</a>
				}
				return null;
			})
		}


		// SEARCH TAX RETURNS
		// if(this.state.search.length > 0){
		// searchFilter = nonArchived.filter(item => item.name.startsWith(this.state.search.toUpperCase()) == true || item.name.startsWith(this.state.search.toLowerCase()) == true)
		//
		// items = searchFilter.map((object, index) => {
		// 	if (this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate") {
		// 		if (object.isAmountAgreedByClient === true) {
		// 			return <a href={"/AdminTaxReturn/" + object._id}>
		// 				<div className="client-taxReturn-div" key={index}>
		// 					<img className="admin-taxreturn-img" src={folder} alt="img" />
		// 					<p className="admin-taxreturn-name" >{object.name}</p>
		// 					<p className="admin-taxreturn-service" >{object.service}</p>
		// 				</div>
		// 			</a>
		// 		}
		// 	}
		//
		// 	if (this.props.auth.user.role === "Basil") {
		// 		return <a href={"/AdminTaxReturn/" + object._id}>
		// 			<div className="client-taxReturn-div" key={index}>
		// 				<img className="admin-taxreturn-img" src={folder} alt="img" />
		// 				<p className="admin-taxreturn-name" >{object.name}</p>
		// 				<p className="admin-taxreturn-service" >{object.service}</p>
		// 			</div>
		// 		</a>
		// 	}
		// 	return null;
		// })
		// }


		// Other Filter
		if (this.state.otherFilter.length > 0) {
			otherFilterArray = nonArchived.filter(item => item[this.state.otherFilter] == true)

			items = otherFilterArray.map((object, index) => {
				if (this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate") {
					if (object.isAmountAgreedByClient === true) {
						return <a href={"/AdminTaxReturn/" + object._id}>
							<div className="client-taxReturn-div" key={index}>
								<img className="admin-taxreturn-img" src={folder} alt="img" />
								<p className="admin-taxreturn-name" >{object.name}</p>
								<p className="admin-taxreturn-service" >{object.service}</p>
							</div>
						</a>
					}
				}

				if (this.props.auth.user.role === "Basil") {
					return <a href={"/AdminTaxReturn/" + object._id}>
						<div className="client-taxReturn-div" key={index}>
							<img className="admin-taxreturn-img" src={folder} alt="img" />
							<p className="admin-taxreturn-name" >{object.name}</p>
							<p className="admin-taxreturn-service" >{object.service}</p>
						</div>
					</a>
				}
				return null;
			})
		}



		// Revenue Recieved
		let revenueRecievedArray = taxReturns.map((object, index) => {
			let a = 0;
			if (object.isAmountAgreedByClient == true && object.isAmountPayedByClient == true) {
				a += object.amount
			}
			return a
		})

		var revenueRecieved = revenueRecievedArray.reduce(function (a, b) {
			return a + b;
		}, 0);
		//console.log(revenueRecieved)


		// Revenue Outstanding
		let revenueOutstandingArray = taxReturns.map((object, index) => {
			let a = 0;
			if (object.isAmountAgreedByClient == true) {
				if (!object.archive) {
					a += object.amount
				}
			}
			return a
		})

		var revenueOutstanding = revenueOutstandingArray.reduce(function (a, b) {
			return a + b;
		}, 0);
		//console.log(revenueOutstanding)



		console.log("nonArchived", nonArchived)

		return (
			<div className="admin-dashboard col">

				<div className="admin-nav">
					<a className="client-nav-logo" href="/">
						<img className="logo" src={logo} alt="img" />
					</a>

					{/*<input placeholder="Client Name" type="text" name="search" onChange={this.handleChange} className="" value={this.state.search}/> */}

					<div className="admin-nav-detail">


						<div>
							<p className="admin-nav-name">{auth.user.name}</p>
							<p className="admin-nav-role">{auth.user.role}</p>
						</div>

						<button onClick={() => this.onLogoutClick()} className="client-button-filled" >Logout</button>
					</div>
				</div>

				<div className="admin-graph-div">

					{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate" || this.props.auth.user.role === "CPA/EA") ?

						<p></p>
						:
						<div>
							<div className="admin-graph">
								<p className="admin-graph-value">${revenueOutstanding}</p>
								<p className="admin-graph-title">Total Revenues</p>
							</div>
						</div>
					}

					{(this.props.auth.user.employeeAccess === true) ?

						<div className="admin-graph-div">
							<div className="admin-graph">
								<p className="admin-graph-value">{nonArchived.filter(f => f.isAmountAgreedByClient).length}</p>
								<p className="admin-graph-title">Client Count</p>
							</div>

							<div className="admin-graph">
								<p className="admin-graph-value">{archived.length}</p>
								<p className="admin-graph-title">Tax Returns Archives</p>
								<a className="archive-link" href="/Admin/Archive">Open Archives -></a>
							</div>
						</div>
						: <div></div>
					}

					{(this.props.auth.user.role === "Basil") ?
						<div>
							<a href="/Admin/ApproveUser" className="client-button-filled">User Access</a>
						</div>
						:
						<div></div>
					}

					{(this.props.auth.user.role === "Basil") &&
						<div>
							<span style={{ padding: "10px" }}>
								<a href="/Admin/ReviewList" className="client-button-filled">Review List</a>
							</span>

						</div>
					}



					{(this.props.auth.user.role === "Staff Associate" || this.props.auth.user.role === "Senior Associate") ?

						<p></p>
						:

						<div>
							{/*
							<p className="admin-graph-value">100%</p>
							<p className="admin-graph-title">PriceTool Conversion</p>
							*/}
						</div>

					}

				</div>





				{/*List of tax returns*/}
				{(this.props.auth.user.employeeAccess === true ?
					<div>
						<div className="col">


							<div className="row-between-0">
								<p className="margin-left-20 admin-workflow-heading">Clients</p>

								{/*
						<div className="margin-left-20">
							<button className="filter-button" onClick={() => this.otherFilterFunction("isDocumentsUploadedByClient")}>Is Documents Uploaded By Client</button>
							<button className="filter-button" onClick={() => this.otherFilterFunction("isAmountAgreedSentByTeamMember")}>Is Amount Agreed Sent By Team Member</button>
							<button className="filter-button" onClick={() => this.otherFilterFunction("isEFileAuthAccepted")}>Is EFile Auth Accepted</button>
							<button className="filter-button" onClick={() => this.otherFilterFunction("isTaxReturnUploadedByTeamMember")}>Is Tax Return Uploaded By Team Member</button>
						</div>
						*/}


								{/* Button Group */}
								<div>
									<button
										className={`${this.state.filter === "All" ? "active-filter-button" : "inactive-filter-button"
											}`}
										onClick={() => this.filter("All")}
									>
										All Accepted Price Quotes
									</button>
									<button
										className={`${this.state.filter === "Individual" ? "active-filter-button" : "inactive-filter-button"
											}`}
										onClick={() => this.filter("Individual")}
									>
										Individual
									</button>
									<button
										className={` ${this.state.filter === "Business" ? "active-filter-button" : "inactive-filter-button"
											}`}
										onClick={() => this.filter("Business")}
									>
										Business
									</button>
									{((this.props.auth.user.role === "Basil") || (this.props.auth.user.email === "onboarding@agroaccounting.com") || (this.props.auth.user.email === "jonathan.cpa@agroaccounting.com")) ?
										<button
											className={` ${this.state.filter === "Unaccepted Price Quote" ? "active-filter-button" : "inactive-filter-button"
												}`}
											onClick={() => this.filter("Unaccepted Price Quote")}
										>
											Unaccepted Price Quote
										</button> : <div></div>}
								</div>

							</div>



							<div className="admin-taxreturn-div">
								{items}
							</div>
						</div>


						{taxReturns.length === 0 ? <NoTaxReturnsYet /> : <p></p>}


						<p></p>
					</div>
					:

					<div className="welcome-div">
						<p className="welcome-text1">Please request access from Basil to see database information</p>
					</div>
				)}





			</div>
		);
	}
}

AdminDashboard.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	taxReturns: state.taxReturns,

});

export default connect(
	mapStateToProps,
	{ logoutUser, getTaxReturns }
)(AdminDashboard);
