import React from 'react'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';

import PublicNav from "../components/PublicNav";
import Footer from "../components/Footer";

class AntiDefamationPolicy extends React.Component {
  render () {
    return(
      <div>
      <MetaTags>
        <title>Anti Defamation Policy - Agro Accounting CPA </title>
        <meta name="description" content="Agro Accounting CPA by Basil Agrocostea CPA is the one-stop destination for freelancers, artists and creative small businesses, for accounting service & help with tax preparation." />
      </MetaTags>
      <PublicNav />

      <div className="home-secondary-header-div">
        <p className="home-secondary-header">Anti Defamation Policy</p>
        <div class="underline-thick"></div>
      </div>

      <div className="center-content">
   
          <h1 className="terms-heading"> Purpose </h1>

          <p className="terms-text">
          Agro Accounting CPA P.C. is committed to providing its services to you1 in a safe and respectful manner. We deeply believe that every person is deserving of respect, value, equity and kindness. It is our intention to conduct all of our business with you in a professional manner that leaves everyone feeling valued. In furtherance of these goals, we have developed this framework that will govern our conduct toward you and your conduct toward us. This is meant to ensure that everyone is treated fairly.
          <br></br>
          <br></br>
          We prohibit and will not tolerate any form of bullying by and against our clients, employees, supervisors, managers, applicants for employment, and others. Everybody is responsible for maintaining and contributing to an environment that is free from bullying and is expected to conduct themselves in a manner that demonstrates professional conduct and mutual respect for others.
          </p>


          <br></br>
          <h1 className="terms-heading"> Prohibited Conduct </h1>

          <p className="terms-text">
          For purposes of this policy, abusive conduct includes/is defined as acts or omissions that a reasonable person would find hostile, offensive, humiliating, or intimidating. This may include but is not limited to:  Verbal or written abuse, including derogatory remarks, insults, and epithets.  Verbal, nonverbal, or physical conduct of a threatening, intimidating, or humiliating nature.  Conduct, whether verbal, or nonverbal, intended to embarrass, shame, or otherwise belittle.
          <br></br>
          <br></br>
          This list is illustrative only and not exhaustive. No form of bullying or abusive conduct will be tolerated. We understand that accounting and taxes are a sensitive and stressful matter. However, it is not our place, as accountants and tax preparers, to bear the burden of client or would-be client stress and/or discontent, stemming from your individual perception of what your service outcome from us should be or to what extent our service communications should be carried out.
          <br></br>
          <br></br>
          Communication or conduct need not be hateful, transphobic, homophobic, racist, sexist, or bigoted to be considered abusive conduct; however, any conduct which could be described by a reasonable person as hateful, bigoted, transphobic, homophobic, racist, or sexist is considered abusive conduct.
          </p>


          <br></br>
          <h1 className="terms-heading"> No Retaliation </h1>

          <p className="terms-text">
          Terms used within this Policy have the same meaning as defined in Terms and Conditions https://www.agroaccounting.com/Terms) unless otherwise specifically defined herein.
          <br></br>
          <br></br>
          We prohibit any form of discipline, reprisal, intimidation, or retaliation for reporting incidents of bullying or abusive conduct of any kind, so please inform us at basil@agroaccounting.com if you believe that any interaction you have with any of our employees constituted abusive conduct. Conversely, please know we do not tolerate any form of discipline, reprisal, intimidation, or retaliation against us for not meeting your individual perception of what your service outcome from us should be or to what extent our service communications should be carried out.
          </p>

          <br></br>
          <h1 className="terms-heading"> Your Interactions with Us </h1>

          <p className="terms-text">
          You agree to take part in this Policy and to abide by its terms. You shall not communicate with any employee, agent, owner, contractor, or staff of Company (collectively “Agro”) in a manner which is hostile, offensive, humiliating, or intimidating. You shall not take part in any activity aimed at Agro which constitutes abusive conduct as defined herein.
          <br></br>
          <br></br>
          All non-public communications by and between you and Agro are confidential. Parties agree to keep confidential all communications by and between you and Agro. Parties will not post, disclose, transmit, or otherwise make available to the public, or third parties, communications between you and Agro, unless required by federal or state regulation or law.
          </p>


          <br></br>
          <h1 className="terms-heading"> Duty to Objectivity and Duty to Notify </h1>

          <p className="terms-text">
          You agree to provide Company at least five (5) business days’ notice prior to posting a “Dispute” on any Public Forum. A “Dispute” shall mean a post, comment, review, or rating on a publicly published site, app, or medium which allows users to rank, review, or otherwise comment on services available for public consumption, which provides Company a rating of less than 60% or its equivalent (this may be 6 out of ten; three stars our of five, etc.) or a post, comment, review, or rating which would constitute abusive conduct. Prior to posting or otherwise making publicly available such a Dispute, you agree to contact Company and provide Company with: 1. a planned draft of the Dispute and a description of the reasons why you think such is appropriate; and 2. the opportunity to participate in a dialogue with you for no less than 15 minutes to attempt to address the reasons underlying your planned Dispute.
          <br></br>
          <br></br>
          In the event you fail to provide notice or participate in the mandatory dialogue described above, you agree you have no right to make public such Dispute and you will immediately, delete, remove, or otherwise make unavailable such a Dispute.
          <br></br>
          <br></br>
          In the event you post a Dispute properly following the dialogue mandated above, you agree to provide objective facts within such dispute explaining for the reason of its posting or publication. Those facts are to be limited to the objective and professional failings of Company. Such failings are limited to: 1. lack of ability to provide the exact advertised and contracted work; 2. lack of professionalism – given a legal understanding of “professionalism;” 3. failure to meet deadlines; 4. failure to abide by contractual obligations. You will refrain from personal attacks, subjective insults, and abusive conduct (collectively “Professional Failings”). You shall not post a Dispute without written reasons listing Professional Failings, nor shall you post a Dispute asserting, or averring matters outside of Professional Failings. In the event you post or otherwise make public such a Dispute which does not comply with this paragraph, you agree to immediately, delete, remove, or otherwise make unavailable such a Dispute. Further, you agree to only post such a Dispute if such a Dispute complies with this paragraph.         
          </p>


          <br></br>
          <h1 className="terms-heading"> Miscellaneous </h1>

          <p className="terms-text">
          Each Party acknowledges and agrees that money damages might not be a sufficient remedy for any breach or threatened breach of this Agreement by such Party. Therefore, in addition to all other remedies available at law (which neither Party waives by the exercise of any rights hereunder), the non-breaching Party shall be entitled to seek specific performance and injunctive and other equitable relief as a remedy for any such breach or threatened breach, and the Parties hereby waive any requirement for the securing or posting of any bond or the showing of actual monetary damages in connection with such claim.
          <br></br>
          <br></br>
          This Agreement shall be governed by and construed in accordance with the internal laws of the State of New York or California, depending on your zip code location, without giving effect to any choice or conflict of law provision or rule (whether of the State of New York, California or any other jurisdiction) that would cause the application of laws of any jurisdiction other than those of the State of New York or California. Any legal suit, action, or proceeding arising out of [or related to] this Agreement or the matters contemplated hereunder shall be instituted [exclusively] in the federal courts of the United States or the courts of the State of New York or California, in each case located in the city of New York or Los Angeles, and County of New York or Los Angeles, and each Party irrevocably submits to the [exclusive] jurisdiction of such courts in any such suit, action, or proceeding and waives any objection based on improper venue or forum non conveniens. Service of process, summons, notice, or other document by mail to such Party's address set out herein shall be effective service of process for any suit, action, or other proceeding brought in any such court.
          <br></br>
          <br></br>
          All notices, requests, consents, claims, demands, waivers, and other communications hereunder shall be in writing and shall be deemed to have been given: (a) when delivered by hand (with written confirmation of receipt); (b) when received by the addressee if sent by a nationally recognized overnight courier (receipt requested); (c) on the date sent by facsimile or email if sent during normal business hours of the recipient, and on the next business day if sent after normal business hours of the recipient; or (d) on the third day after the date mailed, by certified or registered mail, return receipt requested, postage prepaid. Such communications must be sent to the respective Parties at the Company address used for your zip code location.
          <br></br>
          <br></br>
          If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.
          </p>

          <br></br>
          <br></br>

          <Footer />


      </div>


      </div>
    );

  }
}

export default AntiDefamationPolicy;
