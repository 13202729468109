import React from "react";
import MetaTags from 'react-meta-tags';

import PublicNav from "../components/PublicNav";
import Footer from "../components/Footer";

import ReactPlayer from 'react-player'

import "../index.css";

import profileimg from '../Images/selfie.png';
import map from '../Images/mapNY.png';
class AboutUs extends React.Component {

	render() {
		return (
			<div>
				<MetaTags>
					<title>About Us - Agro Accounting CPA </title>
					<meta name="description" content="Agro Accounting CPA by Basil Agrocostea CPA is the one-stop destination for freelancers, artists and creative small businesses, for accounting service & help with tax preparation." />
				</MetaTags>
				<PublicNav />

				<div className="about-us-div">
					<div className="home-secondary-header-div">
						<p className="home-secondary-header">About Us</p>
						<div class="underline-thick"></div>
					</div>
					<img className="about-us-img" src={profileimg} alt="profileimg" />
					<p className="about-us-name">Basil Agrocostea, CPA</p>
					<p className="about-us-title">Chief Practitioner</p>

					<p className="about-us-para">
					Basil has been an Accountant to Artists, Freelancers and Small Businesses for over 10 years. His firm prepares hundreds of tax returns every year. Basil had received a post-graduate education from Columbia University in New York and, in 2008, had obtained some of the higher scores on the CPA exam. Present day, Basil is a CPA licensed in multiple states. He is a fan of the Arts and enjoys eating healthy + exercising regularly.
  				  </p>

					<br></br>
					<br></br>

					<div className="modal1 player-wrapper">
					<ReactPlayer
						width="340px"
						url='https://www.youtube.com/watch?v=ykh6h4O1dUg'
						width='80%'
						height='400px'
						 />

					</div>
				</div>

				<br></br>
				<br></br>


				<Footer />
			</div >
		);
	}
}

export default AboutUs;
