import React from "react";
//import PropTypes from "prop-types";
import "../index.css";
import { connect } from 'react-redux';

//import Footer from "../components/Footer";
//import PublicNav from "../components/PublicNav";
//import HomeSecondaryHeader from "../components/HomeSecondaryHeader";
//import SearchBar from "../components/SearchBar";
//import Disclaimer1 from "../components/Disclaimer1";
//import FreeGuidanceCategory from "../components/FreeGuidanceCategory";
//import GeneralSign from "../components/GeneralSign";
//import Button from 'react-bootstrap/Button'
//import TextField from "../components/TextField";
//import ButtonFilled from "../components/ButtonFilled";

//import Header3 from "../components/Header3";
//import SubHeader3 from "../components/SubHeader3";

//import Form from 'react-bootstrap/Form'

import img1 from '../Images/file-text.png'; // Tell Webpack this JS file uses this image
import img2 from '../Images/dollar.png'; // Tell Webpack this JS file uses this image
import img3 from '../Images/briefcase.png'; // Tell Webpack this JS file uses this image

import bitmoji1 from '../Images/bitmoji-one-finger.png'; // Tell Webpack this JS file uses this image


import timeline from '../Images/timeline-create-account.png'; // Tell Webpack this JS file uses this image


class WhatHappensNextIndividual extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			Married: false,
			Individual: true

		}
	}






	isEmpty = (data) => {
		if (typeof (data) === 'number' || typeof (data) === 'boolean') {
			return false;
		}
		if (typeof (data) === 'undefined' || data === null) {
			return true;
		}
		if (typeof (data.length) != 'undefined') {
			return data.length === 0;
		}
		var count = 0;
		for (var i in data) {
			if (data.hasOwnProperty(i)) {
				count++;
			}
		}
		return count === 0;
	}



	focus = (option) => {
		if (option === "Married Filers") {

			this.setState((state, props) => {
				return {
					Married: true,
					Individual: false

				};
			});

		} else {

			this.setState((state, props) => {
				return {
					Individual: true,
					Married: false,

				};
			});

		}
	}


	render() {

		let spouseFullName = null;
		//		let screen;

		if (this.state.Married) {
			spouseFullName = <div className="margin-bottom-30">
				<label>
					Spouse Full Name
                 <input className="input-field-new" onChange={this.props.handleChange} type="text" name="spouseFullName" />
				</label>
			</div>
		}
		/*
				if (!this.props.auth.isAuthenticated) {
					screen = <div className="col half">

						<div>
							<button className={(this.state.Individual) ? "button-filled" : "button-outlined"} onClick={() => this.focus("Individual Filers")}>Individual Filers</button>
							<button className={(this.state.Married) ? "button-filled" : "button-outlined"} onClick={() => this.focus("Married Filers")}>Married Filers</button>
						</div>



						<div>
							<label>
								<p className="input-heading">Full Name</p>
								<input className="input-field" onChange={this.props.handleChange} type="text" name="name" />
							</label>
						</div>

						{spouseFullName}

						<div>
							<label>
								<p className="input-heading">Email</p>
								<input className="input-field" onChange={this.props.handleChange} type="email" name="email" />
							</label>
						</div>

						<div>
							<label>
								<p className="input-heading">Password</p>
								<input onChange={this.props.handleChange} type="password" name="password" />
							</label>
						</div>

						<div>
							<label>
								<p className="input-heading">Referred by</p>
								<input className="input-field" onChange={this.props.handleChange} type="text" name="referredBy" />
							</label>
						</div>

					</div>
				}
		*/
		console.log(this.state)


		return (
			<div className="col">
				<img className="timeline" src={timeline} alt="img" />
				<div className="row screen-padding">


					<div className="col half">
						<p className="what-happens-next-heading">What Happens After</p>

						<div className="row">
							<img className="what-happens-next-img" src={img1} alt="img" />
							<p className="what-happens-next-text">My team and I will contact you within 24 hours <span className="underline-bold">once we receive</span> <br></br> the requested tax documents and information from you</p>
						</div>

						<div className="row">
							<img className="what-happens-next-img" src={img2} alt="img" />
							<p className="what-happens-next-text">We'll draft a formal price quote</p>
						</div>

						<div className="row">
							<img className="what-happens-next-img" src={img3} alt="img" />
							<p className="what-happens-next-text">We'll get working as soon as you accept our formal price quote</p>
						</div>

						<div className="col">
							<p className="what-happens-next-text-2">Most of our modest-income clients pay toward the lower end of our price range + We offer client referral discounts!</p>
							<img className="what-happens-next-bitmoji" src={bitmoji1} alt="img" />
						</div>
					</div>


					{/*Right Side*/}
					<div className="col half">
						<div>
							<button className={(this.state.Individual) ? "button-filled" : "button-outlined"} onClick={() => this.focus("Individual Filers")}>Individual Filers</button>
							<button className={(this.state.Married) ? "button-filled" : "button-outlined"} onClick={() => this.focus("Married Filers")}>Married Filers</button>
						</div>

						<div className="margin-bottom-30">
							<label>
								Full Name
            <input className="input-field-new" onChange={this.props.handleChange} type="text" name="name" />
							</label>
						</div>

						{spouseFullName}

						<div className="margin-bottom-30">
							<label>
								Email
            <input className="input-field-new" onChange={this.props.handleChange} type="email" name="email" />
							</label>
						</div>

						<div className="margin-bottom-30">
							<label>
								Create Password
            <input className="input-field-new" onChange={this.props.handleChange} type="password" name="password" />
							</label>
						</div>

						<div className="margin-bottom-30">
							<label>
								Referred By
            <input className="input-field-new" onChange={this.props.handleChange} type="text" name="referredBy" />
							</label>
						</div>
					</div>


				</div>

				<div className="pricetool-footer footer">

					<button className="button-outlined" onClick={() => this.props.handleStep(this.props.back)}>
						Back
        </button>

					<div>




						<button className="margin-left-20 button-filled" onClick={() => this.props.handleStep("Confirmation")}>
							Complete
        </button>

					</div>



				</div>



			</div>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(
	mapStateToProps,
	{}
)(WhatHappensNextIndividual);
